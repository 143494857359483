export class BrandLogo {
    constructor() {
      this.brandLogoClickHandler();
    }
  
    public brandLogoClickHandler(): void {
      const logoWrapper = document.querySelector('.brand-logo-wrapper') as HTMLAnchorElement;
  
    }
  }
new BrandLogo();
  