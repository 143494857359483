export class AlphabetSliderControl {
    private alphaBlock: HTMLElement;
    private alphaScroller: HTMLElement;
    private prevBtn: HTMLElement;
    private nextBtn: HTMLElement;
  
    constructor() {
      this.alphaBlock = document.querySelector('.alpha-block') as HTMLElement;
      this.alphaScroller = document.querySelector('.alpha-scroller') as HTMLElement;
      this.prevBtn = document.querySelector('.alpha-pre') as HTMLElement;
      this.nextBtn = document.querySelector('.alpha-next') as HTMLElement;
  
      this.prevBtn?.addEventListener('click', this.slideLeft.bind(this));
      this.nextBtn?.addEventListener('click', this.slideRight.bind(this));
  if( this.prevBtn)
      this.prevBtn.style.display = "none";
    if(this.nextBtn)
      this.nextBtn.style.display = "none";
  
      let alphaBlockWidth = this.alphaBlock?.scrollWidth;
  
      if (alphaBlockWidth < 1232) {
        this.nextBtn.style.display = "flex";
        this.alphaBlock.onscroll = () => {
          let blockWidth = this.alphaBlock.scrollLeft;
          let scrollWidth = this.alphaBlock.scrollWidth;
          let blockOffset = this.alphaBlock.offsetWidth;
          if (blockWidth == 0) {
            this.prevBtn.style.display = "none";
            this.nextBtn.style.display = "flex";
          }
          if (blockWidth > 10) {
            this.prevBtn.style.display = "flex";
            this.nextBtn.style.display = "flex";
          }
          if (scrollWidth - blockWidth == blockOffset) {
            this.nextBtn.style.display = "none";
            this.prevBtn.style.display = "flex";
          }
        }
      }
  
      // Add your smooth scrolling code here
      document.addEventListener('DOMContentLoaded', () => {
        // Define smoothScroll and scrollToTarget methods here
  
        var links = document.querySelectorAll('a[href^="#"]');
        if(document.querySelector('.alpha-next') as HTMLElement)
        links?.forEach((link) => {
          link.addEventListener('click', this.scrollToTarget.bind(this));
        });
      });
  
      let getSecondHead = document.querySelector(".second-head") as HTMLElement;
      let getHeaderH = getSecondHead?.offsetHeight;
      const allElements = document.querySelectorAll('.glossary-list-item');
      allElements.forEach((element) => {
        (element as HTMLElement).style.scrollMarginTop = getHeaderH + 20 + "px";
      });
    }
  
    private slideLeft() {
      this.alphaBlock?.scrollBy(-200, 0); // Adjust the scroll amount as per your requirements
    }
  
    private slideRight() {
      this.alphaBlock?.scrollBy(200, 0); // Adjust the scroll amount as per your requirements
    }
  
    private smoothScroll(target: string) {
      var element = document?.querySelector(target) as HTMLElement;
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  
    private scrollToTarget(event: Event) {
      event.preventDefault();
      var href = (event.target as HTMLElement).getAttribute('href');
      var target = href?.substring(1); // Remove the leading #
  
      if (target) {
        this.smoothScroll('#' + target);
  
        // Update the URL without the #
        history.pushState('', document.title, window.location.pathname + window.location.search);
      }
    }
  }
  
  // Instantiate the class
  const alphabetSliderControl = new AlphabetSliderControl();
  