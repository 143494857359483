export class DonorConfirmation {
  private donorPurchaseDom: HTMLElement | null;
  private donorPurchaseSuccess: HTMLElement | null;
  private donorPurchaseFail: HTMLElement | null;

  constructor() {
    this.donorPurchaseDom = document.querySelector('.donation-purchase-msg') as HTMLElement;
    this.donorPurchaseSuccess = document.querySelector('.donation-success') as HTMLElement;
    this.donorPurchaseFail = document.querySelector('.donation-fail') as HTMLElement;
  }

  public displayMessage(success: boolean) {
    if (this.donorPurchaseDom) {
      this.donorPurchaseDom.style.display = 'flex';

      if (success) {
        this.showSuccessMessage();
      } else {
        this.showFailureMessage();
      }
    }
  }

  private showSuccessMessage() {
    if (this.donorPurchaseSuccess && this.donorPurchaseFail) {
      this.donorPurchaseSuccess.style.display = 'flex';
      this.donorPurchaseFail.style.display = 'none';
    }
  }

  private showFailureMessage() {
    if (this.donorPurchaseSuccess && this.donorPurchaseFail) {
      this.donorPurchaseSuccess.style.display = 'none';
      this.donorPurchaseFail.style.display = 'block';
    }
  }

  public pushHTMLToHistoryAndCleanUrl = () => {
    const htmlContent = document.documentElement.outerHTML;
    const pageTitle = document.title;
    const cleanUrl = window.location.origin + window.location.pathname;
    window.history.pushState({ html: htmlContent, pageTitle: pageTitle }, "", cleanUrl);
  };
}
