import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import {REGEX} from "../constants/regex"
import {startLoader, stopLoader} from "../util/loader"

var QRCode = require('qrcode')

export class NeighborlyApp {
    public mobileNumberInput: HTMLInputElement | null;
    public sendButton: HTMLButtonElement | null;
    public radioBtnAndroid: HTMLInputElement | null;
    public radioBtnIos: HTMLInputElement | null;
    public mainMsg: HTMLElement | null;
    public mainView: HTMLElement | null;
    public errorMsg: HTMLElement | null;

    constructor() {
        this.mobileNumberInput = document.getElementById('send-number') as HTMLInputElement;
        this.sendButton = document.getElementById('send-number-btn') as HTMLButtonElement;
        this.radioBtnAndroid = document.getElementById('android-radio') as HTMLInputElement;
        this.radioBtnIos = document.getElementById('ios-radio') as HTMLInputElement;
        this.mainMsg = document.getElementById('nbly-app-msg') as HTMLElement;
        this.mainView = document.getElementById('nbly-app-main') as HTMLElement;
        this.errorMsg = document.querySelector('.send-number-form #app-error-message');

        this.init();
    }

    public init(): void {
        this.setupEventListeners();
        this.disableSuccessMessage();
        this.generateQRCode()
    }

    private generateQRCode() {
        const btns = document.querySelectorAll('.play-btn')
        const img:any = document.querySelectorAll('.bar-scan');
        btns?.forEach((item:any, ind:number)=>{
            QRCode.toDataURL(item.href, function (err:any, url:any) {
                
                if(img[ind])
                  img[ind].innerHTML = `<img src=${url} alt="bar code" width="296" height="370">`

              })
        })
    }


    private setupEventListeners(): void {
        this.mobileNumberInput?.addEventListener('input', this.handleMobileInput.bind(this));
        this.sendButton?.addEventListener('click', this.sendAppLinkOnNumber.bind(this));
        this.radioBtnAndroid?.addEventListener('click', this.handleClickOnRadioBtn.bind(this));
        this.radioBtnIos?.addEventListener('click', this.handleClickOnRadioBtn.bind(this));
    }

    private disableSuccessMessage(): void {
        if (this.mainMsg) {
            this.mainMsg.style.display = 'none';
        }
    }

    public handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.mobileNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        if (x) {
            this.mobileNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }
        this.checkController();
    }

    public checkController(): void {
        const reg = REGEX.mobileNumberRegex;
        const val = this.mobileNumberInput!.value;
        if (reg.test(val) && val.length >= 14){
            this.errorMsg!.style.display = 'none';
        }else{
            this.errorMsg!.style.display = 'flex';
        }
        if (reg.test(val) && val.length >= 14 && (this.radioBtnAndroid!.checked || this.radioBtnIos!.checked)) {
            this.sendButton!.classList.remove('disable-btn');
            this.sendButton!.classList.add('glb-primary-btn');
        } else {
            this.sendButton!.classList.remove('glb-primary-btn');
            this.sendButton!.classList.add('disable-btn');
        }
    }

    private handleClickOnRadioBtn(): void {
        const isAndroidSelected = this.radioBtnAndroid?.checked;
        const isIosSelected = this.radioBtnIos?.checked;
    
        if (isAndroidSelected || isIosSelected) {
            this.errorMsg!.style.display = 'none';
        } else {
            this.checkController();
        }
    }

    private sendAppLinkOnNumber(): void {
        startLoader()    
        try {
            const request = {
                method: 'POST',
                url: apiConfig.APP_LINK_SEND,
                data: {
                    UserPhone: this.mobileNumberInput!.value.replace(/[- )(]/g, ''),
                    MobileAppPromoDownloadUrl: this.radioBtnAndroid!.checked
                        ? 'https://play.google.com/store/apps/details?id=com.nbly.custapp'
                        : 'https://apps.apple.com/us/app/neighborly/id1504241093',
                },
            };
            apiRequest(request)
                .then((resp) => {
                    if (this.mainMsg && this.mainView) {
                        this.mainMsg.style.display = 'block';
                        this.mainView.style.display = 'none';
                    }
                    stopLoader()
                })
                .catch((err) => {
                    console.log('error', err);
                    stopLoader()
                });
        } catch (error) {
            console.log(error);
            stopLoader()
        }
    }
}

export const neighborlyApp = new NeighborlyApp();