// import { attachNewTabListeners } from '../header/brandLocalAccordion';
import { getDynamicMenuResponse } from '../util/getDynamicMenu';

export class ServiceList {
    public showSkuAsCategory = false;
    maxCharacterCount: number = 375;
    private currentExpanded: HTMLElement | null = null; // Track currently expanded item
    authorableItems: NodeListOf<HTMLLIElement> | undefined;
    private uniqueCounter: number = 0;
    private readonly uiCode: boolean = true;
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    private async initialize() {
        this.showReadMoreButton();
        // attachNewTabListeners();
        if(this.uiCode) {
        const tabContent = document.getElementById('accordion-service') as HTMLElement;
        const tabsUl = document.querySelector('.residential-accordion .req-call-tabs') as HTMLElement;
        const accordionIconList = document.querySelector('.accordion-icon-list') as HTMLElement; // Get the accordion list
        if (tabContent || tabsUl) {
            if (tabContent.querySelector('li') !== null) {
                this.authorableItems = document.querySelectorAll('ul#accordion-service > li');
            }
            await this.getMenu();
        }
        // Check if the tabsUl doesn't exist, and if so, show the accordion-icon-list
        if (!tabsUl && accordionIconList) {
            accordionIconList.style.display = 'flex'; // Change display to flex
        }
    }
    }

    private async getMenu() {
        const tabContent = document.getElementById('accordion-service') as HTMLElement;
        const tabsUl = document.querySelector('.residential-accordion .req-call-tabs') as HTMLElement;
        const response = await getDynamicMenuResponse();
        const data = response?.serviceMenu;

        const dbaName: string | null = localStorage.getItem('dbaName');
        let options = dbaName ? data["country"][0]?.brand[0]?.webLocation[0].option : data["country"][0]?.brand[0]?.option;

        this.showSkuAsCategory = data["country"][0]?.brand[0]?.ShowSkuAsCategory || false;

        if (tabContent) {
            this.renderServiceList(options, tabContent);
        }
        if (tabsUl && options?.length > 0) {
            this.renderTabs(options, tabsUl);
        }
    }

    private renderServiceList(brandMenu: any, tabContent: HTMLElement) {
        const list = brandMenu?.map((item: any) => this.generateServiceList(item)).join('');
        const authoredItems = this.updateAuthorableItems();
        if (list) {
            tabContent.innerHTML = authoredItems ? `${list}${authoredItems}` : `${list}`;
            this.attachAccordionListeners();
        }
    }

    private renderTabs(brandMenu: any, tabsUl: HTMLElement) {
        const tabs = brandMenu?.map((item: any, index: number) => this.generateTabs(item, index)).join('');
        if (tabs) {
            tabsUl.innerHTML = tabs;
            this.toggleTabs(brandMenu[0].name.toLowerCase());
            this.attachTabListeners();
        }
    }

    private toggleAccordion(button: HTMLElement, isOpen: boolean) {
        button.setAttribute('aria-expanded', String(isOpen));
        const contentId = button.getAttribute('data-accordion-target')!;
        const content = document.querySelector(contentId) as HTMLElement;

        if (isOpen) {
            // Close the currently expanded item if it exists
            if (this.currentExpanded && this.currentExpanded !== button) {
                this.toggleAccordion(this.currentExpanded, false);
            }
            content.classList.remove('hidden');
            const arrowOpen = button.querySelector('.accordion-open') as HTMLElement;
            const arrowClose = button.querySelector('.accordion-close') as HTMLElement;

            arrowOpen.classList.add('hidden');
            arrowClose.classList.remove('hidden');
            this.currentExpanded = button; // Update current expanded
        } else {
            content.classList.add('hidden');
            const arrowOpen = button.querySelector('.accordion-open') as HTMLElement;
            const arrowClose = button.querySelector('.accordion-close') as HTMLElement;

            arrowOpen.classList.remove('hidden');
            arrowClose.classList.add('hidden');
            this.currentExpanded = null; // Reset current expanded
        }
    }

    private attachAccordionListeners() {
        const accordionButtons = document.querySelectorAll('.list-accordion-btn');

        accordionButtons.forEach(button => {
            button.addEventListener('click', (event) => {
                const target = event.currentTarget as HTMLElement;
                const isExpanded = target.getAttribute('aria-expanded') === 'true';

                this.toggleAccordion(target, !isExpanded); // Toggle accordion state
            });
        });
    }

    private attachTabListeners() {
        const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
        const tabsBtn = residentialDom.querySelector('.req-call-tabs');
        tabsBtn?.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            if (target.classList.contains("tabs-service")) {
                this.toggleTabs(target.id?.replace(/\s+/g, "-"));
            }
        });
    }

    private toggleTabs(targetID: string) {
        const targetClass = `service-acc-tab--${targetID.replace("accor-", "")}`;
        const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
        const allItems = residentialDom?.querySelectorAll(".accordion-icon-list .card-list");
        const ulAccordion = document.querySelector('.residential-accordion .accordion-icon-list');
        if (ulAccordion) {
            (ulAccordion as HTMLElement).style.display = ''; // This removes the inline display style set in CP for ul
        }
        allItems?.forEach((elem) => {
            if (elem instanceof HTMLElement) {
                elem.style.display = "none"; // Hide all items
            }
        });

        const targetElements = residentialDom?.querySelectorAll(`.accordion-icon-list .${targetClass}`);
        targetElements?.forEach((elem) => {
            if (elem instanceof HTMLElement) {
                elem.style.display = "flex"; // Show target elements
            }
        });
    }

    private generateTabs(data: any, index: number) {
        const imageName = data.name.split(' ')[0].toLowerCase().replace(/\s/g, '');
        return `
            <li class="req-call-tablist">
                <input type="radio" class="req-call-radio hidden tabs-service" id="accor-${data.name.toLowerCase()}" name="request-call" value="${index}" ${index === 0 ? 'checked' : ''}>
                <label for="accor-${data.name.toLowerCase()}" class="req-call-btn res-req-btn">
                    <img src="/brand/_assets/images/icons/${imageName}-icon-white.svg" class="primary-icon" alt="${data.name}" width="16" height="16">
                    <img src="/brand/_assets/images/icons/${imageName}-icon-gray.svg" class="gray-icon" alt="${data.name}" width="16" height="16"> ${data.name}
                </label>
            </li>`;
    }

    private showReadMoreButton() {
        const pTag = document.querySelector('.read-more-wrap .residential-text') as HTMLElement;
        const showMoreButton = document.getElementById("readMoreButton") as HTMLElement;

        if (pTag && showMoreButton) {
            const numberOfCharacters = pTag.innerText.length;
            if (numberOfCharacters < this.maxCharacterCount) {
                showMoreButton.classList.add("hidden");
            }
        }
    }
    private updateAuthorableItems() {
        const newItems: string[] = [];

        this.authorableItems?.forEach((item, index) => {
            const categoryTitle = item.querySelector('.card-title')?.textContent;
            const itemClass = item.classList.contains('authorable-commercial') ? 'commercial' : 'residential';

            // Default style based on index
            const styleDisplay = index === 0 ? 'flex' : 'none';
            const uniqueId = `service-${itemClass}-${this.uniqueCounter}`; // class-level counter

            let innerHTML = '';

            // Scenario 1: Standard button structure for residential/commercial when subcateogeries are present
            if (item.querySelector('button')) {
                const innerList = item.querySelector('.accordion-list');
                const isEmpty = !innerList || innerList.children.length === 0; // Check if <ul> is empty

                const buttonClass = isEmpty ? 'list-accordion-btn disabled' : 'list-accordion-btn';
                const buttonDisabled = isEmpty ? 'disabled' : '';

                innerHTML += `
                    <li class="card-list service-acc-tab--${itemClass}" style="display: ${styleDisplay};">
                        <button type="button" class="${buttonClass}" data-accordion-target="#${uniqueId}" aria-expanded="false" aria-controls="${uniqueId}" ${buttonDisabled}>
                            <div class="icon-block">
                                <div class="icon-wrap">
                                    <svg class="icon">
                                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                    </svg>
                                </div>
                                <div class="icon-block-text">
                                    <h3 class="card-title">${categoryTitle}</h3>
                                </div>
                            </div>
                            ${!isEmpty ? `
                                <div class="accordion-close accordion-arrow">
                                    <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
                                </div>
                                <div class="accordion-open accordion-arrow">
                                    <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
                                </div>
                            ` : ''}
                        </button>
                        <div class="card-info hidden" id="${uniqueId}">
                            <ul class="accordion-list">
                `;

                if (innerList) {
                    innerList.querySelectorAll('li').forEach(subItem => {
                        const subCategoryTitle = subItem.textContent;
                        const anchorElement = subItem.querySelector('a');
                        const subCategoryLink = anchorElement ? anchorElement.getAttribute('href') : '#';
                        const target = anchorElement ? anchorElement.getAttribute('target') : '_self';
                        innerHTML += `
                            <li><a href="${subCategoryLink}" target="${target}">${subCategoryTitle}</a></li>
                        `;
                    });
                }

                innerHTML += `
                            </ul>
                        </div>
                    </li>
                `;
            }

            // Scenario 2: anchor tag with redirection
            else if (item.querySelector('a')) {
                const anchorElement = item.querySelector('a');
                const href = anchorElement ? anchorElement.getAttribute('href') : '#'; // Fallback if no link
                const target = anchorElement ? anchorElement.getAttribute('target') : '_self';
                innerHTML += `
                    <li class="card-list service-acc-tab--${itemClass}">
                        <a id="${uniqueId}" href="${href}" target="${target}" class="list-accordion-btn">
                            <div class="icon-block">
                                <div class="icon-wrap">
                                    <svg class="icon">
                                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                    </svg>
                                </div>
                                <div class="icon-block-text">
                                    <h3 class="card-title">${categoryTitle}</h3>
                                </div>
                            </div>
                            <svg class="accordion-arrow">
                                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#arrow-small-right-black"></use>
                            </svg>
                        </a>
                    </li>
                `;
            }

            // Scenario 3: Button disabled when subcategories are not present
            else {
                innerHTML += `
                    <li class="card-list service-acc-tab--${itemClass}" style="display: flex;">
                        <button class="list-accordion-btn disabled" disabled aria-disabled="true">
                            <div class="icon-block">
                                <div class="icon-wrap">
                                    <svg class="icon">
                                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                                    </svg>
                                </div>
                                <div class="icon-block-text">
                                    <h3 class="card-title">${categoryTitle}</h3>
                                </div>
                            </div>
                            <div class="accordion-close accordion-arrow hidden">
                                <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
                            </div>
                            <div class="accordion-open accordion-arrow hidden">
                                <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
                            </div>
                        </button>
                        <div class="card-info hidden" id="${uniqueId}">
                            <ul class="accordion-list">
                            </ul>
                        </div>
                    </li>
                `;
            }

            newItems.push(innerHTML);
            this.uniqueCounter++; // Increment counter for unique IDs
        });

        return newItems.join('');
    }
    private generateAccordionItem(service: any, data: any, index: number): string {
        const name = data?.name.trim().replace(/\s+/g, "-").toLowerCase();
        const serviceGroupInput = document.getElementById('serviceGroup') as HTMLInputElement;

        let visibleServicesFromMenu;
        let buttonAttributes = '';
        let tag = 'button';

        // Filter visible services based on the serviceGroupInput 
        if (serviceGroupInput && serviceGroupInput.value === 'true') {
            // Show all service items inside menu if serviceType is "Service Group"
            visibleServicesFromMenu = (service.menu || []).flatMap((subservice: any) => {
                if (subservice.serviceType === 'Service Group' && subservice.visibility) {
                    return subservice.service.filter((item: any) => item.visibility); // To show visible items in service group
                } else if (subservice.serviceType === 'Service Sku' && subservice.visibility) {
                    return [subservice]; // Include the subservice if it's visible
                }
                return [];
            });
        } else {
            visibleServicesFromMenu = service.menu.filter((subservice: any) => subservice.visibility);
        }

        // services are visible
        const isAnyServiceVisible = visibleServicesFromMenu.length > 0;
        // Link is available when all items are not visible and the data item has link
        const isLinkAvailable = data.link &&
            (!visibleServicesFromMenu.length ||
                visibleServicesFromMenu.every((subservice: any) => !subservice.visibility));

        // To decide button or anchor tag
        if (isAnyServiceVisible) {
            buttonAttributes = `type="button" class="list-accordion-btn" data-accordion-target="#service-${name}-${index}" aria-expanded="false" aria-controls="service-${name}-${index}"`;
        } else if (isLinkAvailable) {
            tag = 'a';
            buttonAttributes = `href="${data.link}" target="${data.openNewTab ? '_blank' : '_self'}" class="list-accordion-btn"`;
        } else {
            buttonAttributes = `class="list-accordion-btn disabled" disabled`;
        }

        const categoryName = this.showSkuAsCategory && data?.category?.length === 1 ? data?.name : service.name;

        const arrowButton = isAnyServiceVisible ? `
            <div class="accordion-close accordion-arrow hidden">
                <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
            </div>
            <div class="accordion-open accordion-arrow">
                <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
            </div>` : '';
        const arrowSvg = !isAnyServiceVisible && isLinkAvailable ? `
            <svg class="accordion-arrow">
                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#arrow-small-right-black"></use>
            </svg>` : '';
        // Render the list item
        const generatedLi = `
            <li class="card-list service-acc-tab--${name}">
                <${tag} ${buttonAttributes}>
                    <div class="icon-block">
                        <div class="icon-wrap">
                            <svg class="icon">
                                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                            </svg>
                        </div>
                        <div class="icon-block-text">
                            <h3 class="card-title">${categoryName}</h3>
                        </div>
                    </div>
                    ${tag === 'button' ? arrowButton : arrowSvg}
                </${tag}>
                <div class="card-info hidden" id="service-${name}-${index}">
                    <ul class="accordion-list">
                        ${isAnyServiceVisible
                ? visibleServicesFromMenu.map((subservice: any) => `<li><a href="${subservice.link}" target="${subservice.openNewTab ? '_blank' : '_self'}">${subservice.name}</a></li>`).join('')
                : ''
            }
                    </ul>
                </div>
            </li>
        `;

        this.uniqueCounter++;
        return generatedLi;
    }


    public generateServiceList(data: any): string {
        // If there's only one category, skip the visibility check
        if (data?.category.length === 1) {
            return this.generateAccordionItemsForSingleCategory(data);
        }
    
        // If there are multiple categories, check visibility for each service
        return data?.category.map((service: any, index: number) => {
            // Only generate the accordion item if the service is visible
            if (service.visibility) {
                return this.generateAccordionItem(service, data, index);
            } else {
                return '';  // Return empty string if visibility is false
            }
        }).join('');
    }
    
    // Function to handle generating accordion items for a single category (no visibility check)
    private generateAccordionItemsForSingleCategory(data: any): string {
        return data?.category.map((service: any, index: number) => {
            // Always generate the accordion item, ignoring the visibility check for a single category
            return this.generateAccordionItem(service, data, index);
        }).join('');
    }
}

// Instantiate the ServiceList class
const serviceList = new ServiceList();
