import { isTestEnvironment } from "../apiCaching/POST-Requests/helperModules/helperFunctions";
import { apiConfig } from "../config/apiConfig";
import { isTouchDevice } from "../util/device-detection";
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
function removeApiKey(url: string): string {
    const urlObj = new URL(url);
    // Check if 'apikey' parameter exists
    if (urlObj.searchParams.has('apikey')) {
        urlObj.searchParams.delete('apikey');
    }
    return urlObj.toString();
}
export function requestLogger(payload:any, callingURL?:string){
    if(!payload){
        console.log("No payload found");
        return;
    }
    console.log("Mock Request Payload", payload);
    sessionStorage.setItem('mockRequestPayload',JSON.stringify(payload));
    const logData = {
        httpStatusCode: '418',
        message: `${JSON.stringify(payload)} - Source URL - ${window.location.href}` ,
        timeStamp: new Date(),
        apiEndpoint: removeApiKey(callingURL ?? ''),
        deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
    };
    callErrorLogger(logData);
}
async function callErrorLogger(logData: any) {
    const testEnvironment = isTestEnvironment();
    const url = `${window.location.origin}/${apiConfig.ApiLogerrorEndpoint}`;
    if (!logData.apiEndpoint) {
        if (testEnvironment) console.log("Error in determining the API endpoint of the failed call");
        return;
    }

    if (logData.apiEndpoint !== url) {
        sendLogRequest(url, { source: "js-code", errorMessages: [logData] }, testEnvironment);
    } else if (testEnvironment) {
        console.error("Error Logger API failed.");
    }
}
async function sendLogRequest(url: string, data: any, testEnvironment: boolean) {
    const request = {
        method: 'POST',
        url,
        data
    };

    try {
        if (testEnvironment) console.log(request);
        const response = await loggerRequest(request);
        if (testEnvironment) console.log('Log sent to URL Transformer:', response);
    } catch (err) {
        if (testEnvironment) console.error('Failed to send log to URL Transformer', err);
    }
}
const loggerURLTransformer = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'apikey': process.env.URL_TRANSFORMER_ERROR_LOG_KEY,
    }
});
export async function loggerRequest<T>(config: AxiosRequestConfig): Promise<T> {
    try {
        const response: AxiosResponse<T> = await loggerURLTransformer.request(config);
        return response.data;
    } catch (error: any) {
        console.log("Failed to send the mocklog");
        return error;
    }
}
