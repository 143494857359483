import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { getBrandDetailsFromBrandJson } from "../util/share";

declare global {
    interface Window {
      g2Data?: any; // Replace `any` with the appropriate type if known
    }
  }
  
export class NextPrev {
    constructor() {
        const blogContainer = document.querySelector('.blog-main');
            if (blogContainer) {
        this.setNextPrev();
       }
    }

    
    private async setNextPrev() {
        const btns: any = document.querySelectorAll('.pagination-link');
        let pathname = window.location.pathname;

        const brandName = document.getElementById('brandName') as HTMLInputElement;
        const dbaName = document.getElementById('dbaName') as HTMLInputElement;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const countryCode = (document.getElementById('countryCode') as HTMLInputElement)?.value;
        let sortingBlog = `&sort=custom_dt_created desc`
        
        
        const content = localStorage.getItem('blog_search') ?? this.blogSearchTerm();
        const category = localStorage.getItem('blog_category');
        let url = `${apiConfig.BLOG_URL}${process.env.Search_G2_URL}/select?q=custom_s_brand:${brandName?.value}&rows=1000${sortingBlog}&echoParams=explicit&fl=*,score&defType=edismax&wt=json&fq=custom_s_opus_version:2&fq=custom_s_local_dba:${dbaName?.value ? dbaName.value : 'none'}&fq=custom_s_type:blog&fq=custom_s_status:Active`;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);

        if (brandData?.enable_fte) {
            
            const updatedURL = new URL(window.location.href);
            const brandDetails = localStorage.getItem("brandDetails");
            let localBlogUrlName;
            try {
                localBlogUrlName = (brandDetails) ? JSON.parse(brandDetails)?.brand_local_blog_url_name : "blog";
            }
            catch {
                localBlogUrlName = "blog";
            }

            if (updatedURL.pathname.replace(/\//g, '') === localBlogUrlName.replace(/\//g, '')) {
                localStorage.removeItem('blog_search')
            }

            if (countryCode) {
                url += `&fq=custom_s_country:${countryCode}`
            }

            if (window?.g2Data) {
                const blogCategory = this.initBlogArticlePage();
                localStorage.setItem('blog_category', blogCategory);
            }

            if (category && category !== 'none') {
                url += `&fq=custom_ss_category:(*${category}*)`
                localStorage.removeItem('blog_search')
            }


            if (category == 'none' && content) {
                url += `&fq=content:${content}*`;
            }
        }

        try {
            const response: any = await apiRequest({
                method: 'GET',
                url: url,
            });

            const { docs } = response?.response;
            // calling Brandjson API for '/'  trailing 
            if(conceptCode){
            const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
            if( brandData){
                if(brandData.includeTrailingSlash){
                    pathname = pathname.replace(/\/$/, '');
                }
            }
         }
                
            const index = docs.findIndex((x: any) => x.custom_s_context_url.replace(/\/$/, '') === pathname);

            if (index !== -1) {
                if (index > 0) {
                    btns[0].href = docs[index - 1].custom_s_context_url;
                }

                if (index == 0) {
                    btns[0].disabled = true
                    btns[0].style.opacity = '0.3'
                }

                if (index < docs.length - 1) {
                    btns[1].href = docs[index + 1].custom_s_context_url;
                }

                if (index == docs.length-1) {
                    btns[1].disabled = true
                    btns[1].style.opacity = '0.3'
                }
            }
        } catch (err) {
            // Handle the error appropriately
            console.error(err);
        }
    }

    public blogSearchTerm() {
        const url = window.location.href
        const urlObj = new URL(url);
        // Split the pathname and find the search term
        const pathSegments = urlObj.pathname.split('/');
        const searchIndex = pathSegments.indexOf('search');
        let searchTerm = null;

        if (searchIndex !== -1 && pathSegments[searchIndex + 1]) {
            searchTerm = pathSegments[searchIndex + 1];
            console.log('Search term:', searchTerm); // Output: dryer
        } else {
            console.log('Search term not found');
        }
        return searchTerm;
    }

    public initBlogArticlePage() {
        const url = window.location.href;
        const categoryRegex = /\/category\/[\s\-a-zA-Z0-9]+\/?/gim;
        const matchedCategory = url.match(categoryRegex) || ["/category/none"];
        const category = matchedCategory[0].split("/category/")[1].replace("/", "")//.replace(/-/g, ' '); // remove the trailing slash and replace with space
        return category;
    }

}

document.addEventListener('DOMContentLoaded', function() {
const blogList = new NextPrev();
})
