import axios from "axios";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader"
export class GiftCardFormHandler {
  private form: HTMLFormElement;
  private zipCodeInput: HTMLInputElement;
  constructor() {
    this.form = document.querySelector(".gift-cetificate-wrap .primary-btn") as HTMLFormElement;
    this.zipCodeInput = document.getElementById('check-zip-gift-cert') as HTMLInputElement
    if (this.form) this.form.addEventListener("click", this.handleSubmit.bind(this));

    const inputZIpCode = document.getElementById('check-zip-gift-cert') as HTMLInputElement;
    if (inputZIpCode) {
      this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this))
    }
  }


  zipCodeError() {
    if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
      const fieldError: any = document.getElementById(`check-zip-gift-cert-error-msg`);
      const successMsg = document.getElementById('successful-message-zip-check') as HTMLInputElement;
      const errorResponse = document.querySelector('.check-zip-error');
      fieldError.classList.remove('hidden')
      errorResponse?.classList.add('hidden');
      successMsg?.classList.add('hidden');
      !fieldError.innerHTML.trim() && (fieldError.innerHTML = 'Invalid zip code format.')
    }
    else {
      const fieldError: any = document.getElementById(`check-zip-gift-cert-error-msg`);
      fieldError.classList.add('hidden')
      if(this.zipCodeInput){
        this.zipCodeInput?.classList.remove('invalid-field')
      }
    }
  }


  handleSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    const successMsg = document.getElementById('successful-message-zip-check') as HTMLInputElement;
    successMsg?.classList.add('hidden');
    const zipCodeInput = document.getElementById("check-zip-gift-cert") as HTMLInputElement;
    const zipCode = zipCodeInput.value;
    if (this.validateForm()) {
      startLoader()
      this.getData(zipCode);
    }
  }


  getData(zipCode: string) {
    const submitButton = document.querySelector(".gift-cetificate-wrap .primary-btn") as HTMLButtonElement;
    submitButton.setAttribute("disabled", "true");

    axios.get(`${apiConfig.FranchiseLookUpByServiceAddress}&Address=${zipCode}`)
      .then((response) => {
        submitButton.disabled = false;
        const result = response.data;
        const successMsg = document.getElementById('successful-message-zip-check') as HTMLInputElement;
        const successUrl = document.getElementById('successful-url-zip-check') as HTMLInputElement; // Only one declaration

        const errorResponse = document.querySelector('.check-zip-error');

        if (result.length > 0) {
          if (successUrl) {
            window.location.href = window.location.origin + successUrl.getAttribute('value');
            errorResponse?.classList.add('hidden');
          } else if (successMsg) {
            successMsg?.classList.remove('hidden');
            errorResponse?.classList.add('hidden');
          }
        } else {
          if (errorResponse) {
            errorResponse?.classList.remove('hidden');
            successMsg?.classList.add('hidden');
          }
        }
        stopLoader();
      })
      .catch((error) => {
        stopLoader();
        submitButton.disabled = false;
        console.log("Error:", error);
      });
  }


  validateForm(): boolean {
    
    document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
    document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
    const zipCodeInput = document.getElementById("check-zip-gift-cert") as HTMLInputElement;
    const zipCode = zipCodeInput.value;
    const formFields = { zipCode };
    const errors: any = {};


    if (!REGEX.sendZip.test(formFields.zipCode.trim())) {
      const errorMessage = (document.getElementById(`check-zip-gift-cert-error-msg`) as HTMLInputElement)?.innerText;
      errors['check-zip-gift-cert'] = errorMessage ? errorMessage : 'Invalid zip code format.';
    }
    document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));

    Object.keys(errors).forEach((fieldId, index) => {
      const field: any = document.getElementById(fieldId);
      const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
      if (field?.parentNode) {
        const errorMessage = errors[fieldId];
        const errorElement = document.createElement('span');
        errorElement.className = 'error-msg';
        errorElement.textContent = errorMessage
        field.classList.add("invalid-field");
        if (fieldError && errorMessage) {
          fieldError.innerHTML = errorMessage
        }
        if (fieldError?.classList.contains('hidden'))
          fieldError?.classList.remove('hidden')
      }else if (index == 0 && field) {
        field.focus()
      }
    });

    return Object.keys(errors).length === 0;
  }
}

// Initialize the class when the DOM content is loaded
document.addEventListener("DOMContentLoaded", () => {
  new GiftCardFormHandler();
});