export function showAddressForGoogle(fullAddress: string) {
    const templatesToCheck = [
        "general-os-flow-content", 
        "opus-2-lead-flow-template", 
        "opus-2-lead-flow-confirmation-page",
        "opus-2-onverity-ims-lead-flow",
        "opus-2-hms-lead-flow-template",
        "opus-2-mly-lead-flow-template",
        "opus-2-agnostic-lead-flow-template"
    ];

    const templateName = extractTemplateName();
    if (shouldShowAddress(templateName, templatesToCheck)) {
        const displayAddress = getDisplayAddress();
        if (displayAddress === 'true') {
            appendAddressToHeader(fullAddress);
        }
    }
}

function shouldShowAddress(templateName: string | null, templatesToCheck: string[]): boolean {
    return templateName !== null && templatesToCheck.includes(templateName);
}

function getDisplayAddress(): string | null {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);
    return params.get('ShowAddressOnHeader');
}

function appendAddressToHeader(fullAddress: string) {
    const headerItem = document.querySelector(".brand-header-local .local-location.disable_dba_os_flow") as HTMLElement;
    if (headerItem) {
        const existingAddress = headerItem.querySelector(".address-full");
        if (existingAddress) {
            existingAddress.remove();
        }
        headerItem.innerHTML += `<span class="address-full">${fullAddress ?? ""}</span>`;
        const addressElement = headerItem.querySelector(".address-full")as HTMLSpanElement;
        if(addressElement){
            addressElement.style.fontSize = ".875rem";
            addressElement.style.fontWeight = "400";
        }
    }
}



function extractTemplateName(){
    let pageSettingsElement = document.getElementById('page-settings');
    if(pageSettingsElement){
        let elementAsString = pageSettingsElement.outerHTML;
        
        // Display the string
        let rawString = elementAsString.split("value=")[1];
        const str = rawString.split("pagetemplate")[1].split(",")[0];
        let cleanedString = str
        .replace(/\"/g, '')    // Remove all double quotes
        .replace(/=/g, '')     // Remove equal signs
        .replace(/^:/, '')     // Remove leading colon
        .replace(/\s+/g, ' ')  // Replace multiple spaces with a single space
        .trim()
        .replace(/\s+/g,"-");  
        return cleanedString.toLowerCase();
    }
    return null;
}