import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { getSessionAndCorrelationID } from "../util/getSessionCorrelationId";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandDetailsFromBrandJson, handleFakeSubmit, hmsProgressBarUpdate, returnToHomePage } from "../util/share";

export class InspectionSummary {
    
    constructor(){
        sessionStorage.setItem("hms_s6", "true");
        window.scrollTo(0,0);
        this.init();
        this.loadEditScreen("inspection-summary-step");
        hmsProgressBarUpdate('100%', 4);
    }

    summary_data:any = {};

    //Change this flag to false to load actual data
    loadTestData: boolean = false;

    typeOfUser: string = '';

    DOMsToDisplay:any = {};

    loadEditScreen(id:string){
        const serviceSection = document.getElementById(id) as HTMLElement;
        const previousSections = document.querySelectorAll(".section-main>.contact-us-section");
        if(serviceSection && previousSections){
            previousSections.forEach((elm)=>{
                elm.classList.remove("form-step-active");
            })
            serviceSection.classList.add("form-step-active");
        }
    }

    private init() {
        startLoader();
        this.populateSummaryData();
        stopLoader();

        const submitButton = document.querySelector("#inspection-summary-step .btn-container .btn-submit")as HTMLElement;
        if(submitButton)
            submitButton.addEventListener("click", this.manageSubmit.bind(this));
        const cancelButton = document.querySelector("#inspection-summary-step .btn-container .btn-cancel")as HTMLElement;
        if(cancelButton)
            cancelButton.addEventListener("click", this.manageCancel.bind(this));

        const mainForm = document.querySelector(".summery-all")as HTMLElement;
        if(mainForm)
            mainForm.addEventListener("click", this.handleRedirtects.bind(this));
    }

    handleRedirtects(event:any){
        event.preventDefault();
        const clickedTarget = event.target;
        if((clickedTarget.parentNode.className).includes("edit-btn")){
            const parentWrapper  = clickedTarget.closest(".summery-main") as HTMLElement;
            switch(parentWrapper.id){
                case "inspection-time":
                    hmsProgressBarUpdate('0%', 0);
                    this.loadEditScreen("schedule-step");
                    break;
                case "inspection-info":
                    hmsProgressBarUpdate('25%', 1);
                    this.loadEditScreen("inspection-step");
                    break;
                case "seller-agent-info":
                    hmsProgressBarUpdate('25%', 1);
                    this.loadEditScreen("inspection-step");
                    break;
                case "buyer-agent-info":
                    hmsProgressBarUpdate('25%', 1);
                    this.loadEditScreen("inspection-step");
                    break;
                case "property-info":
                    hmsProgressBarUpdate('50%', 2);
                    this.loadEditScreen("property-step");
                    break;
                case "inspection-summary-total":
                    hmsProgressBarUpdate('75%', 3);
                    this.loadEditScreen("service-step");
                    break;
                default:
                    break;
            }
            window.scrollTo(0,0);
        }
    }

    progressBarUpdater(){
        const progressBarLine = document.querySelector(".progressbar #progress") as HTMLElement;
        const progresBarItems = document.querySelectorAll(".progressbar .progress-step");
        if(progresBarItems && progressBarLine){
            progressBarLine.style.width = "100%";
            progresBarItems.forEach((el, index)=>{
                if(index<4)
                    el.classList.add("prograssbar-length", "progress-step-confirmed");
                else if(index===4)
                    el.classList.add("prograssbar-length", "progress-step-active");
            })
            
        }
    }

    populateSummaryData(){
        //Populating with Dummy data to check flow
        if(this.loadTestData){
            this.loadDummyData();
        }
        else{
            const session_data = sessionStorage.getItem("hms_booking")
            if(session_data){
                this.summary_data = JSON.parse(session_data);
            }
        }
        
        //requested time section
        this.populateTimeBlock();

        //property section
        this.populatePropertyInfo();

        //requested service section
        this.populateSummaryBlock();

        //total Price Tracker
        this.populateTotalPrice();

        //check usertype and populate
        this.checkUserTypeAndPopulate();
        
    }

    populateTimeBlock(){
        const inspectionTimeBlock = document.getElementById("inspection-time");
        const inspectionTimeBlockConfirmation = document.querySelector("#confirmation-inspection-time-date .summery");
        if(inspectionTimeBlock){
            this.updatePropertyInfo("#inspection-time-date .edit-content", this.setTimeAndDate(this.summary_data.calendarPaylod.userSelectDate));
        }
        if(inspectionTimeBlockConfirmation){
            this.updatePropertyInfo("#confirmation-inspection-time-date .summery", this.setTimeAndDate(this.summary_data.calendarPaylod.userSelectDate));
        }
    }

    populatePropertyInfo() {
        const propertyInfoBlock = document.getElementById("property-info");
        if(propertyInfoBlock) {
            this.updatePropertyInfo("#property-info-address .edit-content", this.summary_data.Address);
            this.updatePropertyInfo("#confirmation-property-info-address .summery", this.summary_data.Address);


            this.updatePropertyInfo("#property-info-year-built .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.BuiltYear);
            this.updatePropertyInfo("#confirmation-property-info-year-built .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.BuiltYear);
            
            this.updatePropertyInfo("#property-info-sqr-feet .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.SquareFeet);
            this.updatePropertyInfo("#confirmation-property-info-sqr-feet .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.SquareFeet);

            this.updatePropertyInfo("#property-info-baths .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfBathrooms);
            this.updatePropertyInfo("#confirmation-property-info-baths .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfBathrooms);

            this.updatePropertyInfo("#property-info-beds .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfBedrooms);
            this.updatePropertyInfo("#confirmation-property-info-beds .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfBedrooms);

            this.updatePropertyInfo("#property-info-kitchens .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfKitchens);
            this.updatePropertyInfo("#confirmation-property-info-kitchens .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.NumberOfKitchens);

            this.updatePropertyInfo("#property-info-pool .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.IsPoolAvailable ? "Yes" : "No");
            this.updatePropertyInfo("#confirmation-property-info-pool .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.IsPoolAvailable ? "Yes" : "No");

            this.updatePropertyInfo("#property-info-utility .edit-content", this.summary_data.HmsRoleInfo.HmsPropertyDetail.UtilitiesOn ? "Yes" : "No");
            this.updatePropertyInfo("#confirmation-property-info-utility .summery", this.summary_data.HmsRoleInfo.HmsPropertyDetail.UtilitiesOn ? "Yes" : "No");
        }
    }

    populateSummaryBlock(){
        const summaryTotalBlock = document.getElementById("inspection-summary-total");
        const summaryTotalBlockConfirmation = document.getElementById("confirmation-inspection-summary-total");
        if(summaryTotalBlock && summaryTotalBlockConfirmation){
            const summaryDataBlock = summaryTotalBlock.querySelector(".summery-data");
            const summaryDataBlockConfirmation = summaryTotalBlockConfirmation.querySelector(".summery-data");
            if(summaryDataBlock && summaryDataBlockConfirmation){
                summaryDataBlock.innerHTML = "";
                summaryDataBlockConfirmation.innerHTML = "";

                const selectedPackages = this.summary_data.HmsRoleInfo.HmsSelectedServiceDetail.HmsServiceBundles;
                const selectedServices = this.summary_data.HmsRoleInfo.HmsSelectedServiceDetail.HmsAvailableServices;

                let textWrapper = "";
                if(selectedServices.length>0){
                    selectedServices.forEach((service:any) => {
                        textWrapper += `<div class="summery-wrap">
                        <p class="summery">${service.HmsServiceName}</p>
                        <p class="summery-price">$${service.HmsPrice}</p>
                        </div>`
                    });
                    
                }
                if(selectedPackages.length>0){
                    selectedPackages.forEach((service:any) => {
                        textWrapper += `<div class="summery-wrap">
                        <p class="summery">${service.hmsBundleServiceName}</p>
                        <p class="summery-price">$${service.hmsBundleServicePrice}</p>
                        </div>`
                    });
                   
                }
                summaryDataBlock.innerHTML = textWrapper;
                summaryDataBlockConfirmation.innerHTML = textWrapper;
            }
        }
    }

    populateTotalPrice(){
        const totalPriceBlock = document.querySelector("#inspection-summary-step .total-wrap .table-total");
        const totalPriceBlockConfirmation = document.querySelector(".scheduler-thankyou .total-wrap .table-total");
        if(totalPriceBlock && totalPriceBlockConfirmation){
            const HTMLContent = `<p>Subtotal</p><strong>$${this.summary_data.HmsRoleInfo.HmsSelectedServiceDetail.HmsServiceSubTotal}</strong>`
            totalPriceBlock.innerHTML = HTMLContent
            totalPriceBlockConfirmation.innerHTML = HTMLContent
        }
    }

    //returns time in the specified format
    setTimeAndDate(time:string){
        const options:any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return new Date(time).toLocaleDateString("en-US", options);
    }

    addAgencyForFirstBlock(agencyName: string){
        const existingDomCheckPage = document.getElementById("inspection-info-agency");
        const existingDomCheckConfirmation = document.getElementById("confirmation-inspection-info-agency");
        if(existingDomCheckPage && existingDomCheckConfirmation){
            this.showAndHideDiv("inspection-info-agency", "flex", false);
            this.showAndHideDiv("confirmation-inspection-info-agency", "flex", false);
            const agencyNameInsertionP = existingDomCheckPage.querySelector(".edit-content")as HTMLElement;
            const agencyNameInsertionC = existingDomCheckConfirmation.querySelector(".edit-content")as HTMLElement;
            if(agencyNameInsertionP && agencyNameInsertionC){
                agencyNameInsertionP.innerHTML = agencyName;
                agencyNameInsertionC.innerHTML = agencyName;
            }
        }
        else{

            const summaryPage = `<div class="summery-wrap" id="inspection-info-agency">
            <p class="summery-label">Name of Agency</p>
            <p class="summery edit-content">${agencyName}</p>
            </div>`;
            const confirmationPage = `<div class="summery-wrap" id="confirmation-inspection-info-agency">
            <p class="summery-label">Name of Agency</p>
            <p class="summery edit-content">${agencyName}</p>
            </div>`;
            
            const summaryDom = document.querySelector("#inspection-info .summery-data") as HTMLElement;
            if(summaryDom)
                summaryDom.innerHTML+=summaryPage;
            const confirmationDom = document.querySelector("#confirmation-inspection-info .summery-data") as HTMLElement;
            if(confirmationDom)
                confirmationDom.innerHTML+=confirmationPage;
        }
    }

    resetAngentAgency(){
        delete this.summary_data.HmsRoleInfo.HmsRoleDetail.AgentNameOfAgency;
        sessionStorage.setItem("hms_booking", JSON.stringify(this.summary_data));
        this.showAndHideDiv("inspection-info-agency", "flex", true);
        this.showAndHideDiv("confirmation-inspection-info-agency", "flex", true);
    }

    secondBlockAgencyCheck(){
        const roleInfo = this.summary_data.HmsRoleInfo.HmsRoleDetail;
        if(roleInfo.AgentNameOfAgency === null || roleInfo.AgentNameOfAgency === undefined || roleInfo.AgentNameOfAgency === ""){
            this.showAndHideDiv("seller-agent-info-agency", "flex", true);
            this.showAndHideDiv("confirmation-seller-agent-info-agency", "flex", true);
        }
        else{
            this.showAndHideDiv("seller-agent-info-agency", "flex", false);
            this.showAndHideDiv("confirmation-seller-agent-info-agency", "flex", false);
            this.updatePropertyInfo("#seller-agent-info-agency .summery", roleInfo.AgentNameOfAgency);
            this.updatePropertyInfo("#onfirmation-seller-agent-info-agency .summery", roleInfo.AgentNameOfAgency);
        }
    }

    //check usertype
    checkUserTypeAndPopulate(){

        const inspectionInfoBlock = document.getElementById("inspection-info");
        const sellerAgentBlock = document.getElementById("seller-agent-info");
        const buyerAgentBlock = document.getElementById("buyer-agent-info");

        const inspectionInfoBlockConfirmation = document.getElementById("confirmation-inspection-info");
        const sellerAgentBlockConfirmation = document.getElementById("confirmation-seller-agent-info");
        const buyerAgentBlockConfirmation = document.getElementById("confirmation-buyer-agent-info");

        const roleInfo = this.summary_data.HmsRoleInfo.HmsRoleDetail;

        if(inspectionInfoBlock && sellerAgentBlock && buyerAgentBlock && inspectionInfoBlockConfirmation && sellerAgentBlockConfirmation && buyerAgentBlockConfirmation){
            switch (this.summary_data.HmsRoleInfo.HmsRoleKey){
                case "CONTACT_SUBTYPE_BUYER_AGENT":{

                    //First Block
                    this.updatePropertyInfo("#confirmation-inspection-info .summery-heading", "Buyer’s Agent’s Information");
                    this.updatePropertyInfo("#inspection-info .summery-heading", "Buyer’s Agent’s Information");
                    const agencyName = roleInfo.BuyerNameOfAgency
                    if(agencyName && agencyName !=="" && agencyName !==null)
                        this.addAgencyForFirstBlock(agencyName);
                    this.updateFirstBlockOfUserInformation();

                    //second block
                    this.updatePropertyInfo("#confirmation-seller-agent-info .summery-heading", "Buyer’s Information");
                    this.updatePropertyInfo("#seller-agent-info .summery-heading", "Buyer’s Information");
                    this.secondBlockAgencyCheck();
                    this.updateClientInformation();

                    //Third Block
                    if(roleInfo.SellerAgentFirstName === null || roleInfo.SellerAgentPhone === null || roleInfo.SellerAgentEmail === null || roleInfo.SellerAgentFirstName === undefined || roleInfo.SellerAgentPhone === undefined || roleInfo.SellerAgentEmail === undefined){
                        buyerAgentBlock.classList.add("hidden");
                        buyerAgentBlockConfirmation.classList.add("hidden");
                    }
                    else{
                        this.updatePropertyInfo("#buyer-agent-info .summery-heading", "Seller’s Agent's Information");
                        this.updatePropertyInfo("#buyer-agent-info-first-name .summery", roleInfo.SellerAgentFirstName);
                        if(roleInfo.SellerAgentLastName !== "") 
                            this.updatePropertyInfo("#buyer-agent-info-last-name .summery", roleInfo.SellerAgentLastName);
                        else
                            document.getElementById("buyer-agent-info-last-name")?.classList.add("hidden");
                        this.updatePropertyInfo("#buyer-agent-info-email .summery", roleInfo.SellerAgentEmail);
                        this.updatePropertyInfo("#buyer-agent-info-phone .summery", roleInfo.SellerAgentPhone);

                        if(roleInfo.SellerNameOfAgency === null || roleInfo.SellerNameOfAgency === undefined || roleInfo.SellerNameOfAgency === ""){
                            this.showAndHideDiv("buyer-agent-info-agency", "flex", true);
                            this.showAndHideDiv("confirmation-buyer-agent-info-agency", "flex", true);
                        }
                        else{
                            this.updatePropertyInfo("#buyer-agent-info-agency .summery", roleInfo.SellerNameOfAgency);
                            this.updatePropertyInfo("#confirmation-buyer-agent-info-agency .summery", roleInfo.SellerNameOfAgency);
                        }

                        //confirmation Screen
                        this.updatePropertyInfo("#confirmation-buyer-agent-info .summery-heading", "Seller’s Agent's Information");
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-first-name .summery", roleInfo.SellerAgentFirstName);
                        if(roleInfo.SellerAgentLastName !== "") 
                            this.updatePropertyInfo("#confirmation-buyer-agent-info-last-name .summery", roleInfo.SellerAgentLastName);
                        else
                            document.getElementById("confirmation-buyer-agent-info-last-name")?.classList.add("hidden");
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-email .summery", roleInfo.SellerAgentEmail);
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-phone .summery", roleInfo.SellerAgentPhone);
                    }
                    break;
                }
                case "CONTACT_SUBTYPE_SELLER_AGENT":{
                    //First Block
                    this.updatePropertyInfo("#inspection-info .summery-heading", "Seller’s Agent’s Information");
                    this.updatePropertyInfo("#confirmation-inspection-info .summery-heading", "Seller’s Agent’s Information");
                    const agencyName = roleInfo.SellerNameOfAgency
                    if(agencyName && agencyName !=="" && agencyName !==null)
                        this.addAgencyForFirstBlock(agencyName);
                    this.updateFirstBlockOfUserInformation();

                    //second block
                    this.updatePropertyInfo("#seller-agent-info .summery-heading", "Seller’s Information");
                    this.updatePropertyInfo("#confirmation-seller-agent-info .summery-heading", "Seller’s Information");
                    this.secondBlockAgencyCheck();
                    this.updateClientInformation();

                    //Third Block
                    if(roleInfo.BuyerAgentFirstName === null || roleInfo.BuyerAgentPhone === null || roleInfo.BuyerAgentEmail === null || roleInfo.BuyerAgentFirstName === undefined || roleInfo.BuyerAgentPhone === undefined || roleInfo.BuyerAgentEmail === undefined){
                        buyerAgentBlock.classList.add("hidden");
                        buyerAgentBlockConfirmation.classList.add("hidden");
                    }
                    else{
                        this.updatePropertyInfo("#buyer-agent-info .summery-heading", "Buyer’s Agent's Information");
                        this.updatePropertyInfo("#buyer-agent-info-first-name .summery", roleInfo.BuyerAgentFirstName);
                        if(roleInfo.BuyerAgentLastName !== "") 
                            this.updatePropertyInfo("#buyer-agent-info-last-name .summery", roleInfo.BuyerAgentLastName);
                        else
                            document.getElementById("buyer-agent-info-last-name")?.classList.add("hidden");
                        this.updatePropertyInfo("#buyer-agent-info-email .summery", roleInfo.BuyerAgentEmail);
                        this.updatePropertyInfo("#buyer-agent-info-phone .summery", roleInfo.BuyerAgentPhone);

                        //confirmation
                        this.updatePropertyInfo("#confirmation-buyer-agent-info .summery-heading", "Buyer’s Agent's Information");
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-first-name .summery", roleInfo.BuyerAgentFirstName);
                        if(roleInfo.BuyerAgentLastName !== "") 
                            this.updatePropertyInfo("#confirmation-buyer-agent-info-last-name .summery", roleInfo.BuyerAgentLastName);
                        else
                            document.getElementById("confirmation-buyer-agent-info-last-name")?.classList.add("hidden");
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-email .summery", roleInfo.BuyerAgentEmail);
                        this.updatePropertyInfo("#confirmation-buyer-agent-info-phone .summery", roleInfo.BuyerAgentPhone);

                        if(roleInfo.BuyerNameOfAgency === null || roleInfo.BuyerNameOfAgency === undefined ||roleInfo.BuyerNameOfAgency === ""){
                            this.showAndHideDiv("buyer-agent-info-agency", "flex", true);
                            this.showAndHideDiv("confirmation-buyer-agent-info-agency", "flex", true);
                        }
                        else{
                            this.updatePropertyInfo("#buyer-agent-info-agency .summery", roleInfo.BuyerNameOfAgency);
                            this.updatePropertyInfo("#confirmation-buyer-agent-info-agency .summery", roleInfo.BuyerNameOfAgency);
                        }
                    }
                    break;
                }
                case "CONTACT_SUBTYPE_BUYER":{
                    //First Block
                    this.updatePropertyInfo("#inspection-info .summery-heading", "Buyer’s Information");
                    this.updatePropertyInfo("#confirmation-inspection-info .summery-heading", "Buyer’s Information");
                    this.updateFirstBlockOfUserInformation();

                    // Second and Thrid Block
                    this.showBuyerAndSellerAgentDetails();

                    //Delete agentAgency if it is present in the DOM
                    this.resetAngentAgency()

                    break;
                }
                case "CONTACT_SUBTYPE_SELLER":{
                    //First Block
                    this.updatePropertyInfo("#inspection-info .summery-heading", "Seller’s Information");
                    this.updatePropertyInfo("#confirmation-inspection-info .summery-heading", "Seller’s Information");
                    this.updateFirstBlockOfUserInformation();

                    // Second and Thrid Block
                    this.showBuyerAndSellerAgentDetails();
                   
                    //Delete agentAgency if it is present in the DOM
                    this.resetAngentAgency()

                    break;
                }
                case "CONTACT_SUBTYPE_HOME_OWNER":{
                    //First Block
                    this.updatePropertyInfo("#inspection-info .summery-heading", "Home Owner’s Information");
                    this.updatePropertyInfo("#confirmation-inspection-info .summery-heading", "Home Owner’s Information");
                    this.updateFirstBlockOfUserInformation();
                    
                    // Second and Thrid Block
                    this.showBuyerAndSellerAgentDetails();

                    //Delete agentAgency if it is present in the DOM
                    this.resetAngentAgency()

                    break;
                }
                default :{
                    document.getElementById("inspection-info")?.classList.add("hidden");
                    document.getElementById("seller-agent-info")?.classList.add("hidden");
                    document.getElementById("buyer-agent-info")?.classList.add("hidden");
                    document.getElementById("confirmation-inspection-info")?.classList.add("hidden");
                    document.getElementById("confirmation-seller-agent-info")?.classList.add("hidden");
                    document.getElementById("confirmation-buyer-agent-info")?.classList.add("hidden");
                    break;
                }
            }
        }
        
    }

    //First Block of information is common for all user types
    updateFirstBlockOfUserInformation(){
        this.updatePropertyInfo("#inspection-info-first-name .summery", this.summary_data.FirstName);
        if(this.summary_data.LastName !== "")
            this.updatePropertyInfo("#inspection-info-last-name .summery", this.summary_data.LastName);
        else
            document.getElementById("inspection-info-last-name")?.classList.add("hidden");
        this.updatePropertyInfo("#inspection-info-email .summery", this.summary_data.Email);
        this.updatePropertyInfo("#inspection-info-phone .summery", this.summary_data.Phone);

        //confirmation Screen
        this.updatePropertyInfo("#confirmation-inspection-info-first-name .summery", this.summary_data.FirstName);
        if(this.summary_data.LastName !== "")
            this.updatePropertyInfo("#confirmation-inspection-info-last-name .summery", this.summary_data.LastName);
        else
            document.getElementById("confirmation-inspection-info-last-name")?.classList.add("hidden");
        this.updatePropertyInfo("#confirmation-inspection-info-email .summery", this.summary_data.Email);
        this.updatePropertyInfo("#confirmation-inspection-info-phone .summery", this.summary_data.Phone);
    }

    //Second block when first block is an agent
    updateClientInformation(){
        const roleInfo = this.summary_data.HmsRoleInfo.HmsRoleDetail;
        const sellerAgentBlock = document.getElementById("seller-agent-info");

        if(roleInfo.ClientFirstName === null || roleInfo.ClientPhone === null || roleInfo.ClientEmail === null){
            sellerAgentBlock?.classList.add("hidden");
        }
        else{
            
            this.updatePropertyInfo("#seller-agent-info-first-name .summery", roleInfo.ClientFirstName);
            if(roleInfo.ClientLastName !== "") 
                this.updatePropertyInfo("#seller-agent-info-last-name .summery", roleInfo.ClientLastName);
            else
                document.getElementById("seller-agent-info-last-name")?.classList.add("hidden");
            this.updatePropertyInfo("#seller-agent-info-email .summery", roleInfo.ClientEmail);
            this.updatePropertyInfo("#seller-agent-info-phone .summery", roleInfo.ClientPhone);
        }

        //consirmation Screen
        const sellerAgentBlockConfirmation = document.getElementById("confirmation-seller-agent-info");

        if(roleInfo.ClientFirstName === null || roleInfo.ClientPhone === null || roleInfo.ClientEmail === null){
            sellerAgentBlockConfirmation?.classList.add("hidden");
        }
        else{
            
            this.updatePropertyInfo("#confirmation-seller-agent-info-first-name .summery", roleInfo.ClientFirstName);
            if(roleInfo.ClientLastName !== "") 
                this.updatePropertyInfo("#confirmation-seller-agent-info-last-name .summery", roleInfo.ClientLastName);
            else
                document.getElementById("confirmation-seller-agent-info-last-name")?.classList.add("hidden");
            this.updatePropertyInfo("#confirmation-seller-agent-info-email .summery", roleInfo.ClientEmail);
            this.updatePropertyInfo("#confirmation-seller-agent-info-phone .summery", roleInfo.ClientPhone);
        }
    }

    showAndHideDiv(divName:string, styleType: string, hide: boolean){
        const divBlock = document.getElementById(divName);
        if(divBlock){
            if(hide)
                divBlock.style.display = "none";
            else{
                switch(styleType){
                    case "flex":
                        divBlock.style.display = "flex";
                    break;
                    case "block":
                        divBlock.style.display = "block";
                        break;
                    case "grid":
                        divBlock.style.display = "grid";
                        break;
                    default :
                        divBlock.style.display = "block";
                        break
                }
            }
        }
    }
    
    //Second and third block
    showBuyerAndSellerAgentDetails(){
        const sellerAgentBlock = document.getElementById("seller-agent-info");
        const buyerAgentBlock = document.getElementById("buyer-agent-info");

        const sellerAgentBlockConfirmation = document.getElementById("confirmation-seller-agent-info");
        const buyerAgentBlockConfirmation = document.getElementById("confirmation-buyer-agent-info");

        const roleInfo = this.summary_data.HmsRoleInfo.HmsRoleDetail;

        //Second block
        if(roleInfo.BuyerAgentFirstName === null || roleInfo.BuyerAgentFirstName === undefined){
            buyerAgentBlock?.classList.add("hidden")
            buyerAgentBlockConfirmation?.classList.add("hidden")
        }
        else{
            this.updatePropertyInfo("#buyer-agent-info .summery-heading", "Buyer’s Agent's Information");
            this.updatePropertyInfo("#buyer-agent-info-first-name .summery", roleInfo.BuyerAgentFirstName);
            if(roleInfo.BuyerAgentLastName !== ""){
                this.showAndHideDiv("buyer-agent-info-last-name", "flex", false);
                this.updatePropertyInfo("#buyer-agent-info-last-name .summery", roleInfo.BuyerAgentLastName);
            } 
            else{
                this.showAndHideDiv("buyer-agent-info-last-name", "flex", true);
            }
            if(roleInfo.BuyerNameOfAgency === "" || roleInfo.BuyerNameOfAgency===null || roleInfo.BuyerNameOfAgency === undefined){
                this.showAndHideDiv("buyer-agent-info-agency", "flex", true);
            }
            else{
                this.updatePropertyInfo("#buyer-agent-info-agency .summery", roleInfo.BuyerNameOfAgency);
                this.showAndHideDiv("buyer-agent-info-agency", "flex", false);
            }
                
            this.updatePropertyInfo("#buyer-agent-info-email .summery", roleInfo.BuyerAgentEmail);
            this.updatePropertyInfo("#buyer-agent-info-phone .summery", roleInfo.BuyerAgentPhone);

            //confirmation
            this.updatePropertyInfo("#confirmation-buyer-agent-info .summery-heading", "Buyer’s Agent's Information");
            this.updatePropertyInfo("#confirmation-buyer-agent-info-first-name .summery", roleInfo.BuyerAgentFirstName);
            if(roleInfo.BuyerAgentLastName !== "") {
                this.updatePropertyInfo("#confirmation-buyer-agent-info-last-name .summery", roleInfo.BuyerAgentLastName);
                this.showAndHideDiv("confirmation-buyer-agent-info-last-name", "flex", false);
            }
            else{
                this.showAndHideDiv("confirmation-buyer-agent-info-last-name", "flex", true);
            }

            if(roleInfo.BuyerNameOfAgency === "" || roleInfo.BuyerNameOfAgency===null) {
                this.showAndHideDiv("confirmation-buyer-agent-info-agency", "flex", true);
            }
                
            else{
                this.updatePropertyInfo("#confirmation-buyer-agent-info-agency .summery", roleInfo.BuyerNameOfAgency);
                this.showAndHideDiv("confirmation-buyer-agent-info-agency", "flex", false);
            }
            this.updatePropertyInfo("#confirmation-buyer-agent-info-email .summery", roleInfo.BuyerAgentEmail);
            this.updatePropertyInfo("#confirmation-buyer-agent-info-phone .summery", roleInfo.BuyerAgentPhone);
        }

        //Third Block
        if(roleInfo.SellerAgentFirstName === null || roleInfo.SellerAgentFirstName === undefined){
            sellerAgentBlock?.classList.add("hidden")
            sellerAgentBlockConfirmation?.classList.add("hidden")
        }
        else{
            this.updatePropertyInfo("#seller-agent-info .summery-heading", "Seller’s Agent's Information");
            this.updatePropertyInfo("#seller-agent-info-first-name .summery", roleInfo.SellerAgentFirstName);
            if(roleInfo.SellerAgentLastName !== "") {
                this.showAndHideDiv("seller-agent-info-last-name", "flex", false);
                this.updatePropertyInfo("#seller-agent-info-last-name .summery", roleInfo.SellerAgentLastName);
            }
            else{
                this.showAndHideDiv("seller-agent-info-last-name", "flex", true);
            }

            if(roleInfo.SellerNameOfAgency === "" || roleInfo.SellerNameOfAgency===null) {
                this.showAndHideDiv("seller-agent-info-agency", "flex", true);
            }
            else{
                this.updatePropertyInfo("#seller-agent-info-agency .summery", roleInfo.SellerNameOfAgency);
                this.showAndHideDiv("seller-agent-info-agency", "flex", false);
            }

            this.updatePropertyInfo("#seller-agent-info-email .summery", roleInfo.SellerAgentEmail);
            this.updatePropertyInfo("#seller-agent-info-phone .summery", roleInfo.SellerAgentPhone);

            //Confirmation Screen
            this.updatePropertyInfo("#confirmation-seller-agent-info .summery-heading", "Seller’s Agent's Information");
            this.updatePropertyInfo("#confirmation-seller-agent-info-first-name .summery", roleInfo.SellerAgentFirstName);
            if(roleInfo.SellerAgentLastName !== "") {
                this.updatePropertyInfo("#confirmation-seller-agent-info-last-name .summery", roleInfo.SellerAgentLastName);
                this.showAndHideDiv("confirmation-seller-agent-info-last-name", "flex", false);
            }
            else{
                this.showAndHideDiv("confirmation-seller-agent-info-last-name", "flex", false)
            }
            if(roleInfo.SellerNameOfAgency === "" || roleInfo.SellerNameOfAgency===null) {
                this.showAndHideDiv("confirmation-seller-agent-info-agency", "flex", true)
            }
            else{
                this.showAndHideDiv("confirmation-seller-agent-info-agency", "flex", false)
                this.updatePropertyInfo("#confirmation-seller-agent-info-agency .summery", roleInfo.SellerNameOfAgency);
            }
            this.updatePropertyInfo("#confirmation-seller-agent-info-email .summery", roleInfo.SellerAgentEmail);
            this.updatePropertyInfo("#confirmation-seller-agent-info-phone .summery", roleInfo.SellerAgentPhone);
        }

    }

    //This is the function that actually finds and updates the DOM element
    updatePropertyInfo(selector:any, value:any) {
        const element = document.querySelector(selector);
        if(element) {
            element.innerHTML = value;
        }
    }

    manageCancel(event:any){
        event.preventDefault();
        /*Code to be uncommented once all POS moves to onverity */

        const submitButton = document.querySelector("#inspection-summary-step .btn-container .btn-submit")as HTMLElement;
        submitButton.removeEventListener("click", this.manageSubmit);
        const cancelButton = document.querySelector("#inspection-summary-step .btn-container .btn-cancel")as HTMLElement;
        cancelButton.removeEventListener("click", this.manageCancel);
        sessionStorage.removeItem("hms_booking");
        this.clearFormValues();
        hmsProgressBarUpdate('0%', 0);
        const zipModal = document.getElementById("zip-modal") as HTMLElement;
        const formSection = document.getElementById("form-section") as HTMLElement;
        if(zipModal && formSection){
            zipModal.style.display="flex";
            formSection.style.display="none";
        }
        const previousSections = document.querySelectorAll(".section-main>.contact-us-section");
        if(previousSections){
            previousSections.forEach((elm)=>{
                elm.classList.remove("form-step-active");
            })
        }
        window.scrollTo(0,0);

    }

   async manageSubmit(event:any){

        event.preventDefault();
       
        let requestBody = JSON.parse(JSON.stringify(this.summary_data)); 
        const conceptId = (document.getElementById("conceptId") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptId) as any;
        const emailOptOut = brandData?.enable_emailOptOut ?? false;
        const defaultLeadSourceId = brandData?.default_leadSourceID || 0;

        const host = window.location.hostname.toLowerCase();
        let IsTest;
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost')) 
        {
            IsTest = true;
        }else {
            IsTest = false;
        }

        //capture the Campaign type from the URL
        const campaignName = host.split("?")[1];
        let sessionID: string | undefined;
        let correlationID: string | undefined;
        const sessionAndCorelation = getSessionAndCorrelationID();
        sessionID = sessionAndCorelation.sessionID;
        correlationID = sessionAndCorelation.correlationID;
       
        const postalData = {
            "C4ChannelTypeId": 0,
            "ConceptCalledId": conceptId,
            "IsTest": IsTest,
            "WebLocationId": requestBody?.franchiseWebLocationId,
            "C4CallCenterId": null,
            "Note": `Particular Inspector: ${requestBody?.calendarPaylod?.bookInspector}, Pool:${requestBody?.HmsRoleInfo?.HmsPropertyDetail?.IsPoolAvailable}, Utilities:${requestBody?.HmsRoleInfo?.HmsPropertyDetail?.UtilitiesOn}`,
            "FirstName": requestBody?.FirstName,
            "LastName": requestBody?.LastName,
            "Email": requestBody?.Email,
            "Phone": requestBody?.Phone,
            "PostalCode": requestBody?.zipcode.trim(),
            "City": requestBody?.City,
            "State": requestBody?.State,
            "Country": requestBody?.Country,
            "Address": requestBody?.Address,
            "Address2":requestBody?.Address2 ?? null,
            "LeadSource": "BrandWebsite",//As per request from Onverity team
            "LeadOrigin": "BrandWebsite",//As per request from Onverity team
            "CampaignName": campaignName ?? "N/A",
            "ReferringURL": null,
            "CallType": "In Bound",
            "PreferredCommunicationType": "Email, Phone",
            "EmailOptOut": emailOptOut,
            "LeadSourceId": defaultLeadSourceId,
            "ServiceSkuId": 0,
            "IsLeadOnly": false,//HMS will always have a calander
            "ScheduleID":  requestBody?.calendarPaylod?.ScheduleID,
            "TimeBlock": null,
            "CustomerId": null,
            "IsFsmUpdatedCustomer": null,
            "ReferralTypeId": requestBody?.ReferralTypeId,
            "ReferralType": requestBody?.ReferralType,
            "CustomerType": "Residential", //We dont have an option to toggle btwn residential and commercial
            "NeighborlyAccountId": null,
            "VendorRouting": 0,
            "AgencyName": null,
            "hmsAppointmentType": 2,
            "HmsParticularInspector": requestBody?.calendarPaylod?.bookInspector,
            "HmsRoleInfo":requestBody?.HmsRoleInfo,
            "correlationId":""      
        }
        if (correlationID) {
            postalData.correlationId = correlationID;
        }
        let url = '';
        if(correlationID && sessionID){
            url = `${apiConfig.HMS_BOOKING}&correlationId=${correlationID}&sessionId=${sessionID}`
        }
        else{
            url = apiConfig.HMS_BOOKING;
        }

        const request = {
            method: 'POST',
            url: url,
            data: postalData
        };
        //Code to handle Fake Submit
        if (request?.data?.FirstName?.toLowerCase() === "isfakesubmit") {
            handleFakeSubmit(request,true);
            this.confirmationScreenDisplay();
            return;
        }
        const hasThisBeenLoaded = sessionStorage.getItem("hms_s6");
        if(hasThisBeenLoaded)
            if(JSON.parse(hasThisBeenLoaded)){
                startLoader();  
                sessionStorage.setItem("rl-post-data", JSON.stringify(postalData));
                sessionStorage.removeItem("hms_s6")
                apiRequest(request)
                .then(() => {
                    stopLoader();
                    sessionStorage.removeItem("hms_booking");
                    sessionStorage.removeItem("hms_booking_selection");
                    this.confirmationScreenDisplay();
                })
                .catch((err) => {
                    stopLoader();
                    console.error("Error while scheduling booking :", err );
                    this.errorModalTrigger();
                });
            }

        
        
        
    }

    loadDummyData(){
        this.summary_data = {
            "calendarPaylod":{
                "bookInspector" : "Ajay Varma",
                "userSelectDate": "2024-05-08T12:00:00",
                "userSelectTime": "Afternoon",
            },
            "doingBusinessAs": "HouseMaster Serving Shiloh/Metro East Illinois",
            "franchiseId" : 48476,
            "C4ChannelTypeId": 0,
            "ConceptCalledId": 25,
            "IsTest": false,
            "WebLocationId": 27438,
            "C4CallCenterId": null,
            "Note": "Particular Inspector: Ajay, Pool:false, Utilities:true",
            "FirstName": "Ajay",
            "LastName": "V",
            "Email": "sachidanand.singh@nbly.com",
            "Phone": "(884) 848-4848",
            "PostalCode": "35124",
            "City": "Birmingham",
            "State": "AL",
            "Country": "US",
            "Address": "1st Street 2nd cross Birmingham",
            "Address2": null,
            "LeadSource": "BrandWebsite",
            "LeadOrigin": "BrandWebsite",
            "CampaignName": "Web",
            "ReferringURL": null,
            "CallType": "In Bound",
            "PreferredCommunicationType": "Text, Email, Phone",
            "EmailOptOut": false,
            "LeadSourceId": 1,
            "ServiceSkuId": 0,
            "IsLeadOnly": false,
            "ScheduleID": "eyJDb25jZXB0SWQiOjI1LCJKb2JQcm9taXNlZERhdGUiOiIyMDI0LTA0LTE5VDA3OjAwOjAwIiwiSXNBdmFpbGFibGUiOnRydWUsIklzRnJhbmNoaXNlSW5QT1MiOnRydWUsIkRpc3BsYXlUZXh0IjoiTW9ybmluZyIsIlRlY2huaWNpYW5FeHRlcm5hbElkIjpudWxsLCJPcGFsU2NoZWR1bGVJZCI6bnVsbCwiRnNtU2xvdENvZGUiOiIwOTNkOTNmYi01NjcwLTQxNDgtYjVmNi02Y2JkYzcwN2QzZDAiLCJGcmFuY2hpc2VJZCI6NDg0NzYsIkpvYlByb21pc2VkRW5kRGF0ZSI6bnVsbCwiU3RhcnRNaW51dGVzIjpudWxsLCJFbmRNaW51dGVzIjpudWxsLCJGcmFuY2hpc2VXZWJMb2NhdGlvbkNhbGVuZGFySWQiOm51bGwsIlRlY2huaWNpYW5OYW1lIjpudWxsfQ==",
            "TimeBlock": null,
            "CustomerId": null,
            "IsFsmUpdatedCustomer": null,
            "ReferralTypeId": "33829658-6598-47af-ba82-8941214de3b9",
            "ReferralType": "Neighborly.com",
            "ReferralSourceId": null,
            "ReferralSource": null,
            "CustomerType": "Residential",
            "NeighborlyAccountId": null,
            "NeighborlyLeadSourceId": 4,
            "VendorRouting": 0,
            "AgencyName": null,
            "hmsAppointmentType": 2,
            "HmsParticularInspector": "Ajay",
            "HmsRoleInfo": {
                "HmsRoleType": "REP",
                "HmsRoleKey": "CONTACT_SUBTYPE_BUYER_AGENT",
                "HmsRoleDetail": {
                    "willOwnerOrAdultAttend": false,
                    "PresentForInspection": [
                        "Buyer"
                    ],
                    "BuyerAgentFirstName": "Roshan",
                    "BuyerAgentLastName": "Jose",
                    "BuyerAgentPhone": "(012) 343-5345",
                    "BuyerAgentEmail": "roshan@nbly.com",
                    "BuyerNameOfAgency": null,
                    "BuyerAgentReceiveTextMessages": false,
                    "SellerAgentFirstName": "ajay",
                    "SellerAgentLastName": "Kumar",
                    "SellerAgentPhone": "(012) 343-5345",
                    "SellerAgentEmail": "roshan@nbly.com",
                    "SellerAgentReceiveTextMessages": false,
                    "SellerNameOfAgency": null,
                    "BuyerOrSellerAgent": "buyer",
                    "ClientFirstName": "Roshan",
                    "ClientLastName": "Jose",
                    "ClientPhone": "(012) 343-5345",
                    "ClientEmail": "roshan@nbly.com",
                    "ClientReceiveTextMessages": false
                },
                "HmsPropertyDetail": {
                    "BuiltYear": 2009,
                    "SquareFeet": 1000,
                    "TypeOfFoundation": "Basement",
                    "NumberOfBedrooms": 4,
                    "NumberOfBathrooms": 1,
                    "NumberOfKitchens": 4,
                    "IsPoolAvailable": false,
                    "UtilitiesOn": true,
                    "HearAboutHouseMaster": "Neighborly.com",
                    "explainOther": "",
                    "isPropertyOccupied": true
                },
                "HmsSelectedServiceDetail": {
                    "HmsServiceBundles": [],
                    "HmsAvailableServices": [
                        {
                            "HmsServiceId": "2258d319-f827-47cc-aa85-4416517d1a18",
                            "HmsServiceName": "Construction/Damage Evaluation",
                            "HmsServiceDescription": "Construction/Damage Evaluation",
                            "HmsServiceProductNumber": "10083",
                            "HmsServiceCreatedBy": "Eventbridge user",
                            "HmsPrice": 100,
                            "HmsItemTax": 0,
                            "isTaxable": false
                        },
                        {
                            "HmsServiceId": "ee24ea10-8c12-4835-a730-a5c2d3646070",
                            "HmsServiceName": "Plumbing Inspection",
                            "HmsServiceDescription": "Plumbing Inspection",
                            "HmsServiceProductNumber": "10008",
                            "HmsServiceCreatedBy": "Eventbridge user",
                            "HmsPrice": 275,
                            "HmsItemTax": 0,
                            "isTaxable": false
                        },
                        {
                            "HmsServiceId": "6cc258a1-9983-4583-a82d-ded4d53accbe",
                            "HmsServiceName": "Ancillary Systems (vacuum, intercom, etc)",
                            "HmsServiceDescription": "Ancillary Systems (vacuum, intercom, etc)",
                            "HmsServiceProductNumber": "10070",
                            "HmsServiceCreatedBy": "Eventbridge user",
                            "HmsPrice": 100,
                            "HmsItemTax": 0,
                            "isTaxable": false
                        },
                        {
                            "HmsServiceId": "18cc29a5-c977-4617-a54c-b05262fb2371",
                            "HmsServiceName": "Foundation Inspection",
                            "HmsServiceDescription": "Foundation Inspection",
                            "HmsServiceProductNumber": "10002",
                            "HmsServiceCreatedBy": "Eventbridge user",
                            "HmsPrice": 275,
                            "HmsItemTax": 3,
                            "isTaxable": true
                        }
                    ],
                    "HmsServiceSubTotal": "750.00",
                    "HmsServiceTotal": 0
                },
                "HmsTaxDetail": {
                    "RegionalTaxes": [
                        {
                            "taxAmount": 0
                        }
                    ]
                }
            }
        };
    }

    errorModalTrigger(){
        const target = document.getElementById('error-modal-HMS-trigger') as HTMLElement;
        target?.click();

        const errorModalHms = document.getElementById("modal-hms-system-error");
        if (errorModalHms) {
            errorModalHms.style.display = "block";
        }
        
        const backToHomeButton = document.querySelector("#error-modal-HMS .error-btn-close")as HTMLButtonElement;
        if(backToHomeButton)
            backToHomeButton.addEventListener("click", (()=>{
                returnToHomePage();
        }))
    }

    confirmationScreenDisplay(){
        const submitButton = document.querySelector("#inspection-summary-step .btn-container .btn-submit")as HTMLElement;
        submitButton.removeEventListener("click", this.manageSubmit);
        window.scrollTo(0,0);
        const confirmationScreen = document.querySelector(".lead-flow-thank-you")as HTMLElement;
        const schedulerScreen = document.querySelector(".scheduler-main") as HTMLElement;
        if(confirmationScreen)
            confirmationScreen.style.display = "block";
        if(schedulerScreen)
            schedulerScreen.classList.add("hidden")
    }

    clearFormValues(): void {
        // Get the parent div
        const parentDiv = document.getElementById('form-section');
    
        if (parentDiv) {
            // Get all input, textarea and select elements
            const formElements = parentDiv.querySelectorAll('input, textarea, select');
    
            // Iterate over each form element
            formElements.forEach((element: Element) => {
                if (element instanceof HTMLInputElement) {
                    switch (element.type) {
                        case 'text':
                        case 'password':
                        case 'email':
                        case 'number':
                        case 'search':
                        case 'url':
                        case 'tel':
                            element.value = '';
                            break;
                        case 'checkbox':
                        case 'radio':
                            element.checked = false;
                            break;
                        default:
                            break;
                    }
                } else if (element instanceof HTMLTextAreaElement) {
                    element.value = '';
                } else if (element instanceof HTMLSelectElement) {
                    element.selectedIndex = -1;
                }
            });
        }
    }
    
}
