export interface VNode {
  type: string;
  props: { [key: string]: any };
  children: Array<VNode | string>;
}
type NodeValue = VNode | string | undefined;
function createElement(node: NodeValue): Node | null {
  if (node === undefined || node === null) {
    return null; // Handle undefined or null nodes
  }

  if (typeof node === 'string') {
    return document.createTextNode(node);
  }

  const $el = document.createElement(node.type);

  // Apply props (attributes, event listeners, etc.)
  if (node.props) {
    Object.keys(node.props).forEach(key => {
      if (key.startsWith('on')) {
        const eventType = key.slice(2).toLowerCase();
        if (typeof node.props[key] === 'function') {
          $el.addEventListener(eventType, node.props[key]);
          ($el as any)._listeners = ($el as any)._listeners || {};
          ($el as any)._listeners[eventType] = node.props[key];
        }
      } else if (node.props[key] !== null && node.props[key] !== undefined) {
        $el.setAttribute(key, node.props[key]);
      }
    });
  }

  // Recursively create and append child elements
  node.children
    .map(createElement)
    .forEach(child => {
      if (child) $el.appendChild(child);
    });

  return $el;
}

function removeEventListeners($el: HTMLElement, oldProps: { [key: string]: any }) {
  if (!oldProps) return;
  Object.keys(oldProps).forEach(key => {
    if (key.startsWith('on')) {
      const eventType = key.slice(2).toLowerCase();
      const oldListener = ($el as any)._listeners?.[eventType];
      if (oldListener) {
        $el.removeEventListener(eventType, oldListener);
      }
    }
  });
}

function propsChanged(oldProps: { [key: string]: any }, newProps: { [key: string]: any }): boolean {
  const allKeys = new Set([...Object.keys(oldProps), ...Object.keys(newProps)]);
  for (const key of allKeys) {
    if (oldProps[key] !== newProps[key]) {
      return true;
    }
  }
  return false;
}

function updateProps($el: HTMLElement, oldProps: { [key: string]: any }, newProps: { [key: string]: any }) {
  // Remove old event listeners and attributes not in newProps
  Object.keys(oldProps).forEach(key => {
    if (!newProps[key]) {
      if (key.startsWith('on')) {
        const eventType = key.slice(2).toLowerCase();
        const oldListener = ($el as any)._listeners?.[eventType];
        if (oldListener) {
          $el.removeEventListener(eventType, oldListener);
        }
      } else {
        $el.removeAttribute(key);
      }
    }
  });

  // Add or update new props
  Object.keys(newProps).forEach(key => {
    if (newProps[key] !== oldProps[key]) {
      if (key.startsWith('on')) {
        const eventType = key.slice(2).toLowerCase();
        if (typeof newProps[key] === 'function') {
          $el.addEventListener(eventType, newProps[key]);
          ($el as any)._listeners = ($el as any)._listeners || {};
          ($el as any)._listeners[eventType] = newProps[key];
        }
      } else if (newProps[key] !== null && newProps[key] !== undefined) {
        $el.setAttribute(key, newProps[key]);
      }
    }
  });
}

function changed(node1: VNode | string, node2: VNode | string): boolean {
  return typeof node1 !== typeof node2 ||
    (typeof node1 === 'string' && node1 !== node2) ||
    (isVNode(node1) && isVNode(node2) && node1.type !== node2.type) ||
    (isVNode(node1) && isVNode(node2) && node1.props?.key !== node2.props?.key);
}

function isVNode(node: VNode | string): node is VNode {
  return typeof node !== 'string';
}

function updateVDomElement(
  $parent: HTMLElement,
  newNode: VNode | string | undefined,
  oldNode?: VNode | string,
  index: number = 0
): void {
  const $child = $parent.childNodes[index] as HTMLElement | undefined;

  if (!oldNode) {
    addNewElement($parent, newNode);
  } else if (!newNode) {
    removeOldElement($parent, $child, oldNode);
  } else if (changed(newNode, oldNode)) {
    replaceElement($parent, $child, newNode);
  } else if (isVNode(newNode) && isVNode(oldNode) && $child) {
    updateElement($parent, $child, oldNode, newNode);
  }
}

function addNewElement($parent: HTMLElement, newNode: VNode | string | undefined): void {
  const newElement = createElement(newNode);
  if (newElement) {
    // If $parent is a <ul> and newElement is also a <ul>
    if ($parent.tagName.toLowerCase() === 'ul' && newElement instanceof HTMLElement && newElement.tagName.toLowerCase() === 'ul') {
      // Extract all <li> elements from the new <ul>
      const liElements = Array.from(newElement.children).filter(child => child.tagName.toLowerCase() === 'li');

      // Append each <li> to the parent <ul>
      liElements.forEach(li => {
        $parent.appendChild(li);
      });

      return; // Exit after appending the <li> elements
    }
    else {
      $parent.appendChild(newElement);
    }
  }
}

function removeOldElement($parent: HTMLElement, $child: HTMLElement | undefined, oldNode: VNode | string): void {
  if ($child) {
    removeEventListeners($child, (oldNode as VNode).props);
    $parent.removeChild($child);
  }
}

function replaceElement($parent: HTMLElement, $child: HTMLElement | undefined, newNode: VNode | string): void {
  const newElement = createElement(newNode);
  if (newElement && $child) {
    $parent.replaceChild(newElement, $child);
  }
}

function updateElement($parent: HTMLElement, $child: HTMLElement, oldNode: VNode, newNode: VNode): void {
  if (propsChanged(oldNode.props, newNode.props)) {
    updateProps($child, oldNode.props, newNode.props);
  }

  updateChildren($child, oldNode.children, newNode.children);
}

function updateChildren($child: HTMLElement, oldChildren: (VNode | string)[], newChildren: (VNode | string)[]): void {
  const maxLength = Math.max(oldChildren.length, newChildren.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < newChildren.length) {
      updateVDomElement($child, newChildren[i], oldChildren[i], i);
    } else if (i >= newChildren.length && i < oldChildren.length) {
      const extraChild = $child.childNodes[i];
      if (extraChild) {
        removeEventListeners(extraChild as HTMLElement, (oldChildren[i] as VNode).props);
        $child.removeChild(extraChild);
      }
    }
  }
}



function parseDOMString(domString: string): VNode {
  const parser = new DOMParser();
  const doc = parser.parseFromString(domString, 'text/html');
  const rootElement = doc.body.firstChild as HTMLElement;

  function createVNode(element: HTMLElement): VNode {
    const type = element.tagName.toLowerCase();
    const props: { [key: string]: any } = {};
    Array.from(element.attributes).forEach(attr => {
      props[attr.name] = attr.value;
    });

    const children: Array<VNode | string> = [];
    element.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        children.push(child.textContent ?? '');
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        children.push(createVNode(child as HTMLElement));
      }
    });

    return { type, props, children };
  }

  return createVNode(rootElement);
}

// Example usage:
// const initialDOMString = `
//   <ul>
//     <li key="1">item 1</li>
//     <li key="2">item 2</li>
//   </ul>
// `;

// const newDOMString = `
//   <ul>
//     <li key="1">item 1</li>
//     <li key="2">item 2</li>
//     <li key="3">item 3</li>
//   </ul>
// `;

// const initialVirtualDOM = parseDOMString(initialDOMString);
// const newVirtualDOM = parseDOMString(newDOMString);

// Initial rendering
// const $root = document.getElementById('root') as HTMLElement;
// updateVDomElement($root, initialVirtualDOM);

// Update the DOM
// updateVDomElement($root, newVirtualDOM, initialVirtualDOM);

export { updateVDomElement, parseDOMString };
