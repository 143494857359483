import { getBrandDetailsFromBrandJson, getWeblocationPathGroupWithDynamicConfig } from "./share";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";

export async function getDynamicMenuResponse() {
    let dynamicMenuURLResponse: any = {};
    const dynamic_url =getWeblocationPathGroupWithDynamicConfig();
    try{
        const cachedResponse = await getCachedAPIResponse(dynamic_url, apiCaching.DynamicMenu, false);
        if (cachedResponse) {
            if(typeof cachedResponse === 'object'){
                dynamicMenuURLResponse = cachedResponse;
                localStorage.setItem('dynamicMenuURLResponse', JSON.stringify(dynamicMenuURLResponse));
                setFilterService(cachedResponse);
            }
            return dynamicMenuURLResponse;
        }
    }
    catch(error){
        console.error('Error in fetching state API data:', error);
        throw error;
    }
}

function setFilterService(response:any){

    const data = response.serviceMenu;
    if(data["country"]){
        let options = data["country"][0]?.brand[0]?.option;
        if(localStorage.getItem("dbaName")){
            options = data["country"][0]?.brand[0]?.webLocation?.[0]?.option;
        }
        const brandMenu = options;
        sessionStorage.setItem('filterService',JSON.stringify(brandMenu));
    }
}
export async function handleSocialIcon() {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    if (!conceptCode) return;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    if (!brandData) return;

    const socialIconList = document.querySelector('.fb-social-block');
    const hambergerSocial = document.querySelector('.menu-social');
    const deskTopSocial = document.querySelector('.hero-social');

    if (!socialIconList) return;

    const isLocal:boolean = !!(document.getElementById('dbaName') as HTMLInputElement)?.value;
    const isHeroBanner:boolean = !!(document.getElementById('hero-banner-social') as HTMLElement);

    // Hamburger social icons (National or Local based on conditions)
    if (
        brandData?.display_social_icons_humburger_national ||
        (brandData?.display_social_icons_humburger_local && isLocal)
    ) {
        cloneAndAppendSocialIcons(socialIconList, hambergerSocial);
    }

    // Hero banner social icons (based on Local or National)
    const displaySocialIcons = isLocal 
        ? brandData?.display_social_icons_local_hero_banner 
        : brandData?.display_social_icons_national_hero_banner;

    if (shouldAppendSocialIcons(displaySocialIcons, isHeroBanner)) {
        cloneAndAppendSocialIcons(socialIconList, deskTopSocial);
    }

    function shouldAppendSocialIcons(displayCondition: boolean, bannerElement: boolean) {
        return displayCondition && bannerElement;
    }
    const deskTopSocialHtml = deskTopSocial as HTMLElement;
    if (deskTopSocialHtml && !deskTopSocialHtml.querySelector('.card-social-link')) {
        deskTopSocialHtml.style.display = 'none';
    } else {
        deskTopSocialHtml?.style.removeProperty('display');
    }
}

// Function to clone the social icon list and append it to a target element
function cloneAndAppendSocialIcons(socialIconList:any, targetElement:any) {
    if (socialIconList && targetElement) {
        // Clone the social icon list to avoid modifying the original DOM
        const clonedSocialIconList = socialIconList.cloneNode(true) as HTMLElement;
        // Process the cloned social icons
        socialIconFromFooter(clonedSocialIconList);
        // Append the cloned list to the target element
        // Check if targetElement does not already contain clonedSocialIconList
        targetElement.innerHTML = "";
        targetElement.appendChild(clonedSocialIconList);
    }
}

// Function to modify the cloned social icons
function socialIconFromFooter(socialIconList: HTMLElement) {
    const fbSocialItems = socialIconList.querySelectorAll('.fb-social-li');
    if (fbSocialItems) {
        fbSocialItems.forEach(item => {
            item.classList.replace('fb-social-li', 'card-social-list');
            const useElement = item.querySelector<SVGUseElement>('use');
            if (useElement) {
                const svgLink = changeSvgIcon(useElement.getAttribute('xlink:href'));
                useElement.setAttribute('xlink:href', svgLink);
            }

            // Update the 'fb-social-link' class
            const linkElement = item.querySelector<HTMLElement>('.fb-social-link');
            if (linkElement) {
                linkElement?.classList.replace('fb-social-link', 'card-social-link');
            }
        });

        // Replace 'fb-social-block' with 'card-social' for the socialIconList element itself
        socialIconList.classList.replace('fb-social-block', 'card-social');
    }
}

// Function to change the SVG icon's href to use the white version
function changeSvgIcon(svg: string | null): string {
    if (!svg) return '';
    const parts = svg.split('#');
    parts[1] = parts[1] !== "insta" ? parts[1] : "instagram";
    let iconName = parts[1].endsWith("-white") ? parts[1] : parts[1].concat("-white");
    return `${parts[0]}#${iconName}`;
}


