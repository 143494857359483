import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { appendCommaForText } from "./share";

let position = {
    lat: 0,
    lng: 0
};

export let allAddressList: any[] = [];
export let address1Details = '';
export let suggestedAddress:any = [];
export function isValidZip(zipcode:string) : boolean{
    const zipRegex = REGEX.sendZip;
    if(zipcode && zipcode != '00000' && zipRegex.test(zipcode)){
        return true;
    }
    else{
        return false;
    }
}
export async function discoverSuggestions(zipCodeElem:any, AddrElem:any) : Promise<any>{
    let isFlag = false;
    if (isValidZip(zipCodeElem?.value.trim()) && zipCodeElem.id.includes('zip-code')) {
        isFlag = true;
    }
    if(isFlag){
        const latLngRequest = `${apiConfig.geoCodeSearchApiUrl}?qq=postalCode=${zipCodeElem.value}&apiKey=${process.env.JS_Heremap_API_KEY}&in=countryCode%3AUSA%2CCAN`;
        getCachedAPIResponse(latLngRequest)
        .then((response: any) => {
            position = response?.items[0].position;
            if(AddrElem.value){
                let endpoint = `${apiConfig.DISCOVER_API}?q=${AddrElem.value}&in=countryCode%3AUSA%2CCAN&in=circle:${position.lat},${position.lng};r=50000&apiKey=${process.env.JS_Heremap_API_KEY}`;
                getCachedAPIResponse(endpoint)
                .then((result:any)=>{
                    let resultData: any = [];
                    result?.items.forEach((item:any)=>{
                        if(item.address.postalCode?.includes('-')){
                            item.address.postalCode = item.address.postalCode.split('-')[0];
                            let label = item.address.label.split(item.address.postalCode);
                            if(label.length>1){
                                label = label[0] + item.address.postalCode + "," + label[1].split(",")[1];
                            }
                            item.address.label = label;
                        }
                        if(item.address.postalCode === zipCodeElem.value){
                            resultData.push(item);
                        }
                    }); 
                    if(resultData.length > 0) {
                        suggestedAddress = resultData[0];
                        allAddressList = resultData;
                        AddrElem.setAttribute("data-suggestion", JSON.stringify(allAddressList[0]));
                        setDatat(allAddressList, AddrElem);
                    }
                    else{
                        AddrElem.setAttribute("data-suggestion", JSON.stringify(result.items[0]));
                    }
                })
                .catch((err:any)=>{
                    return [];
                });
            }
            
        })
        .catch((err: any)=>{
            return [];
        });
    }
    else{
        discoveryAPI(AddrElem.value)
        .then((result:any) => {
            suggestedAddress = result?.items[0];
            result?.items.forEach((item:any)=>{
                if(item.address.postalCode?.includes('-')){
                    item.address.postalCode = item.address.postalCode.split('-')[0];
                    let label = item.address.label.split(item.address.postalCode);
                    if(label.length>1){
                        label = label[0] + item.address.postalCode + "," + label[1].split(",")[1];
                    }
                    item.address.label = label;
                }
            }); 
            allAddressList = result?.items;
            AddrElem.setAttribute("data-suggestion", JSON.stringify(result.items[0]));
            setDatat(allAddressList, AddrElem);
        }).catch((err:any) => {
            return err;
        });
    }
    
}

export async function discoveryAPI(arg:string):Promise<any>{
    try{
        if(arg){
            const endpoint = `${apiConfig.DISCOVER_API}?q=${arg}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`;
            const result = await getCachedAPIResponse(endpoint);
            return result;
        }
    }
    catch{
        throw Error('Discover API does not return');
    }
    
}

function setDatat(suggestions: any,AddrElem:any) {
    try {
        const inputElement = AddrElem;
        const suggestionContainer: any = document.createElement("ul") as HTMLElement;
        suggestionContainer.className = "suggestion-container";

        // Clear previous suggestions
        while (inputElement.nextSibling && inputElement.nextSibling.className === "suggestion-container") {
            inputElement.parentNode.removeChild(inputElement.nextSibling);
        }
        
        suggestions.forEach((matchingSuggestion: any) => {
            const suggestionItem = document.createElement("li");
            suggestionItem.className = "suggestion-item";
            suggestionItem.textContent = matchingSuggestion?.address.label;
            suggestionItem.setAttribute('data-heremap', JSON.stringify(matchingSuggestion.address) ?? '');

            suggestionItem.addEventListener("click", () => {
                sessionStorage.setItem("suggestion", "true");
                inputElement.value = matchingSuggestion.address.label;
                suggestionContainer.parentNode.removeChild(suggestionContainer);
                const heremapData = JSON.parse(suggestionItem.getAttribute('data-heremap') as string);
                const suggestedAddress = getAddressDetails(heremapData);
                sessionStorage.setItem('suggestedAddress',JSON.stringify(suggestedAddress));
                address1Details = suggestedAddress;
            });

            suggestionContainer.appendChild(suggestionItem);
        });

        if (suggestions.length > 0) {
            inputElement.parentNode.insertBefore(suggestionContainer, inputElement.nextSibling);
        }

    } catch (error) {
        console.log("error", error)
    }
}

export function getAddressDetails(heremapData:any):any{
    const requiredKeyList = ['houseNumber','street','city','stateCode','postalCode','countryName'];
    let flattenObj = '';
    requiredKeyList.forEach((key)=>{
        if (heremapData.hasOwnProperty(key)) {
            flattenObj = flattenObj + heremapData[key] + " ";
        }
    });
    let label = heremapData.label.replace(/,/g, "").replace(flattenObj.trim(),"").trim();
    label = label.replace(heremapData.countryName,"").replace(heremapData.city,"").replace(heremapData.stateCode,"").trim();
    let output = "";
    if(label){
        output = label;
    }
    if(heremapData.hasOwnProperty('houseNumber')){
        if(output){
            output = output + ", " + heremapData.houseNumber + " ";
        }
        else{
            output = heremapData.houseNumber + " ";
        }
        
    }
    if(heremapData.hasOwnProperty('street')){
        if(output==label){
            output = output + ", " + heremapData.street;
        }
        else{
            output = output + heremapData.street;
        }
        
    }
    if(output.split(" ")[0] === heremapData.postalCode){
        output = "No Address Collected";
    }
    if(!output){
        output = "No Address Collected";
    }
    const suggestedAddress = {
        "addr1": `${output}`,
        "city": `${isValid(heremapData.city)}`,
        "stateCode": `${isValid(heremapData.stateCode)}`,
        "postalCode": `${isValid(heremapData.postalCode)}`,
        "country": `${isValid(heremapData.countryCode)}`
    };
    return suggestedAddress;
}

export function isValid(arg:string){
    if(arg && arg!="null" && arg!="undefined"){
        return arg;
    }
    else{
        return '';
    }
}

function filterZip(obj:any){
    if(obj.postalCode?.includes('-')){
        obj.postalCode = obj.postalCode.split('-')[0];
        let label = obj.label.split(obj.postalCode);
        if(label.length>1){
            label = label[0] + obj.postalCode + "," + label[1].split(",")[1];
        }
        obj.label = label;
    }
}

export async function handleMissingData(city:any,state:any,zipCode:any): Promise<any>{
    const addrFlag = sessionStorage.getItem("suggestion");
    if (addrFlag === "false" || !city || !state) {
        try {
            const result = await discoveryAPI(zipCode);
            const outputData = result?.items?.[0];
            city = outputData?.address?.city ?? "No city found";
            state = outputData?.address?.stateCode ?? "No state found";
            sessionStorage.setItem("manualCity", city);
            sessionStorage.setItem("manualState", state);
            return Promise.resolve({ "city": city, "state": state });
        } catch (error) {
            console.warn("handleMissingData: Error occurred while fetching data from HERE Maps API", error);
            city = city ?? "No city found";
            state = state ?? "No state found";
            sessionStorage.setItem("manualCity", city);
            sessionStorage.setItem("manualState", state);
            return Promise.resolve({ "city": city, "state": state });
        }
    } else {
        return Promise.resolve({ "city": city, "state": state });
    }
}


export function getAddressConfirmation(bookingData:any){
    const suggestFlag = sessionStorage.getItem("suggestion");
    const suggestAddr = sessionStorage.getItem("suggestedAddress") as string;
    let text1,text2;
    if(suggestFlag && suggestFlag === "true" && suggestAddr){                
        const suggestJSON = JSON.parse(suggestAddr);
        if(suggestJSON.addr1 === "No Address Collected"){
            text1 = bookingData?.Address2 ?? bookingData?.address2 ?? '';
        }
        else{
            if(bookingData?.Address2){
                text1 = `${suggestJSON?.addr1}, ${bookingData?.Address2}`;
            }
            else if(bookingData?.address2){
                text1 = `${suggestJSON?.addr1}, ${bookingData?.address2}`;
            }
            else{
                text1 = suggestJSON?.addr1;
            }   
        }
        
        const addressParts2 = [
            validInput(suggestJSON?.city,bookingData?.City ?? bookingData?.city),
            validInput(suggestJSON?.stateCode,bookingData?.State ?? bookingData?.state),
            bookingData?.PostalCode ?? bookingData?.postalCode
        ];
        text2 = addressParts2.join(", "); 
    }
    else{
        if(bookingData?.Address2){
            text1 = `${bookingData?.Address}, ${bookingData?.Address2}`;
        }
        else if(bookingData?.address2){
            text1 = `${appendCommaForText(bookingData?.address)}${appendCommaForText(bookingData?.address2)}`;
        }
        else{
            text1 = bookingData?.Address ?? bookingData?.address;
        }
        text2 = `${appendCommaForText(bookingData?.City) || appendCommaForText(bookingData?.city)}${appendCommaForText(bookingData?.State) || appendCommaForText(bookingData?.state)}${bookingData?.PostalCode ?? bookingData?.postalCode ?? ''}`
    }
    sessionStorage.setItem("suggestion","false"); 
    return {"line1": text1, "line2": text2};
}

function validInput(input1:string,input2:string):string {
    if(input1 && input1!="" && input1!=null){
        return input1;
    }
    else{
        return input2;
    }
}