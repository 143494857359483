export const errorMessage = {
    fname: "Invalid name format",
    lname: "Invalid last name format",
    email: "Invalid email format",
    phone: "Invalid phone format",
    zip: "Invalid zip code",
    city: "Invalid city",
    state: "Invalid state",
    address: "Invalid address",
    address2: "Invalid address2"
}