import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig"
import { getDynamicMenuResponse } from '../util/getDynamicMenu';

interface MenuItem {
  name: string;
  link: string;
  category: any;
  menu: any;
  nestedItems?: MenuItem[];
}

export class MenuGenerator {
  constructor() {
    this.getMenue()
}

  getMenue() {

      getDynamicMenuResponse();


    let request2 = {
      url: apiConfig.GETHeaderMenu,
      menthod: 'GET'
    }

    apiRequest(request2)
      .then((response: any) => {
        const data = JSON.parse(response.serviceMenu);
        const options = data["country"][0].brand[0].option;
        const brandMenu = options;

        // const generatedMenu = this.generateMenu(brandMenu);               
        // document.querySelector('.main-nav')?.insertAdjacentHTML('afterbegin', generatedMenu);
        // console.log("generatedMenu", generatedMenu);   

      })
      .catch((err) => { });

  }




  generateSubMenu = (menu3Items: any) => {
    return menu3Items.map((menu3: any) => menu3Items.link ? `<li class="subMenu-li">
              <a href=${menu3Items.link} class="subMenu-link">${menu3.name}</a>
          </li>         
      `: `<li class="subMenu-li">
          <a  class="subMenu-link">${menu3.name}</a>
      </li>         
  `).join('');
  };

  generateMenu2 = (menu2: any) => {
    return (`
    <div class="menu-block">
        <div class="mb-wrap">
        ${menu2.link ? ` <a href=${menu2.link} data-nested-value="nav-electric" class="expand-menu">
          <svg class="nav-square-img">
              <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#squares-2x2" />
          </svg>
          <span class="menu-nested">${menu2.name} <svg class="nav-arrow-img">
              <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg.svg#chevron-right" />
          </svg></span>
      </a>`  : ` <a  data-nested-value="nav-electric" class="expand-menu">
      <svg class="nav-square-img">
          <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg.svg#squares-2x2" />
      </svg>
      <span class="menu-nested">${menu2.name} <svg class="nav-arrow-img">
          <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right" />
      </svg></span>
  </a>`

      }
           
        </div>
        <div id="nav-electric" data-nbly="nav-electric" class="hide-Submenu sub-menu">
            <button type="button" data-back="nav-electric" class="back-btn">
                <svg class="nav-arrow-img">
                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevronleft" />
                </svg> back
            </button>
            <div class="nav-link-active">${menu2.name}</div>
            <ul>${this.generateSubMenu.call(this, menu2.menu)}</ul>
            <a href="#" class="find-local" data-modal-target="glb-find-local" data-modal-toggle="glb-find-local">
                <svg class="icon">
                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary" />
                </svg>
                <div> Find My Local Mr. Electric </div>
            </a>
        </div>
    </div>
`)
  };

  generateMenuItem = (menuItem: any) => {
    return (`
    <li class="nav-link-item hoverable">
    ${menuItem.link ? ` <a href=${menuItem.link} class="nav-link" aria-current="page" data-nested-value="nav-residential">
      <span class="nav-link-span">${menuItem.name} <svg class="nav-arrow-img">
          <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right" />
      </svg></span>
  </a>` : ` <a  class="nav-link" aria-current="page" data-nested-value="nav-residential">
  <span class="nav-link-span">${menuItem.name} <svg class="nav-arrow-img">
      <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right" />
  </svg></span>
</a>`
      }
       
        <div id="nav-residential" data-nbly="nav-residential" class="mega-menu hide-menu">
            <div class="mega-inner-warp">
                <button type="button" data-back="nav-residential" class="back-btn">
                    <svg class="nav-arrow-img">
                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevronleft" />
                    </svg> back
                </button>
                <div class="nav-link-active">${menuItem.name}</div>
                ${menuItem.category.map(this.generateMenu2.bind(this)).join('')}
            </div>
        </div>
    </li>
`)
  };

  generateMenu = (menu: any) => {
    return menu.map((menuItem: any) => this.generateMenuItem.call(this, menuItem)).join('');
  };

  insertMenuIntoDOM = (menuHTML: any) => {
    const mainNav = document.querySelector('.main-nav');
    if (mainNav) {
      mainNav.insertAdjacentHTML('afterbegin', menuHTML);
    }
  };

  generateAndInsertMenu = (menu: any) => {
    const menuHTML = this.generateMenu(menu);
    this.insertMenuIntoDOM(menuHTML);
  };
}


const menuGenerator = new MenuGenerator();