export function startLoader() {
    if(!(document.querySelector('.loading-div .loader') as HTMLElement)){
        let loaderContainer = document.createElement('div')
        let loader = document.createElement('div')
        loaderContainer.className = 'loading-div active'
        loader.className = 'loader'
        loader.style.backgroundImage = "url(/brand/_assets/images/global-img/loader.gif)";
        loaderContainer.appendChild(loader)
        document.body.appendChild(loaderContainer)
    }
    
}

export function stopLoader() {
    const loader = document.querySelector('.loading-div') as HTMLElement;
    if(loader){
        document.body?.removeChild(loader);
    }
}