
function generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);

    for (const value of array) {
        result += characters.charAt(value % charactersLength);
    }
    return result;
}
export function getSessionAndCorrelationID(){
    let obj = {
        correlationID: '',
        sessionID: ''
    }

    const sessionID = localStorage.getItem("sessionIDFr");
    if(sessionID){
        obj.sessionID = sessionID;
    }
    else{
        obj.sessionID = generateRandomString(5);
        localStorage.setItem("sessionIDFr", obj.sessionID);
    }
    obj.correlationID = `${generateRandomString(5)}-${Date.now()}`;
    return obj;
}