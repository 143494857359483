import { apiConfig } from '../config/apiConfig';
import axios, { AxiosResponse } from 'axios';

export class StateHandler {
  // constructor() {}

  public getStateFun(): void {
    this.initStateCall();
    // const estimateInputState = document.querySelector('select[name="estimateInputState"]') as HTMLSelectElement | null;
    // if (estimateInputState !== null) {
    //   const referenceDetailsVal = estimateInputState.value;
    //   if (referenceDetailsVal === '' || referenceDetailsVal === '0') {
    //     this.initStateCall();
    //   }
    // }

    // const setState = document.querySelector('.setState') as HTMLSelectElement | null;
    // if (setState !== null) {
    //   const setStateVal = setState.value;
    //   if (setStateVal === '' || setStateVal === '0') {
    //     this.initStateCall();
    //   }
    // }
  }

  private initStateCall(): void {
    const baseUrl = apiConfig.stateApi;
    const conceptCode = (document.querySelector('#conceptCode') as HTMLInputElement)?.value || "MRE";
    const country = (document.querySelector('#countryCode') as HTMLInputElement)?.value || "us";
    const countryCode = country?.toUpperCase();

    if (conceptCode === 'FSP') {
      const zipcode = localStorage.getItem('zipcode') || '';
      const caTest = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(zipcode);
      if (caTest) {
        this.profileStateApiCa(baseUrl, countryCode);
      } else {
        this.profileStateApiUs(baseUrl, countryCode);
      }
    } else {
      if (country === 'ca') {
        this.profileStateApiCa(baseUrl, 'US');
      } else {
        this.profileStateApiUs(baseUrl, countryCode);
      }
    }
  }

  private profileStateApiCa(baseUrl: string, countryCode: string | undefined): void {
    // Implementation for profile state API call in Canada
  }

  private profileStateApiUs(baseUrl: string, countryCode: string | undefined): void {
    // Implementation for profile state API call in the US
    axios
      .get(baseUrl + countryCode + `?apikey=${process.env.JS_API_KEY}`)
      .then((response: AxiosResponse<any>) => {
        const data = response.data;
        const states: string[] = [];
  
        data.forEach((element: any) => {
          states.push(`<option id="${element.stateCode}" value="${element.stateCode}">${element.stateName}</option>`);
        });
  
        const setState = document.querySelector('#modal-states.select-basic') as HTMLSelectElement | null;
        const conceptCode = document.querySelector('#conceptCode') as HTMLSelectElement || "MRE";
        if (setState !== null) {
          setState.innerHTML = '';
          if (conceptCode?.value == 'PLG') {
            setState.innerHTML += '<option value="">Select State</option>';
          } else {
            setState.innerHTML += '<option value="">Select State/Province</option>';
          }
          setState.innerHTML += states.join('');
        }
  
        // const estimateInputState = document.querySelector('select[name="estimateInputState"]') as HTMLSelectElement | null;
        // if (estimateInputState !== null) {
        //   estimateInputState.innerHTML = '<option value="">Select a state</option>' + states.join('');
        // }
  
        // const blogInputState = document.querySelector('select[name="blogInputState"]') as HTMLSelectElement | null;
        // if (blogInputState !== null) {
        //   blogInputState.innerHTML = '<option value="">Select</option>' + states.join('');
        // }
      })
      .catch((error: any) => {
        // Handle error
      });
  }
}