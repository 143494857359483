import { cachedAPIWrapper } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { shareOnFacebook, shareOnLinkedIn, shareOnPinterest, shareOnTwitter } from "../util/share";
interface Photo {
  imageURL: string;
  shortCaption?: string;
  categoryName: string;
  altText:any
}
export class PhotoGallery2 {
  private readonly page: number = 1;
  private readonly pageSize: number = 3;
  private perPageItem: Record<string, number> = {};
  private categoryImages: { name: string }[] = [];
  private imageListData: Photo[] = [];
  private categoryImagesTemp: { name: string }[] = [];
  private imageListDataTemp: Photo[] = [];

  public modal: HTMLElement | null;
  public slideIndex: number = 1;
  public photoSection: HTMLElement | null;

  public itemsPerPage: number;
  public currentPage: number;
  private data: any[];

  constructor() {
    this.itemsPerPage = 12;
    this.currentPage = 1;
    this.data = [];

    this.loadInitialData();
    this.modal = document.getElementById("galleryModal");
    this.photoSection = document.querySelector(".photo-gallery-section.content_ces");
    const selectService: any = document.querySelector('.category-selection .select-basic') as HTMLSelectElement;
    selectService?.addEventListener('change', () => {
      const category = selectService?.value
      if (category === "all" || category === "All")
        this.loadInitialData('all');
      else {
        this.categoryImages = [{ name: category }];
        this.imageListData = this.imageListDataTemp.filter((item: any) => item.categoryName == category);
        this.initShowLength(this.categoryImages, this.imageListData, 'btnClick');
      }
    })

  }

  removeDuplicateCategories(objects: any[]) {
    const seenCategories = new Set();
    return objects.filter(obj => {
      if (seenCategories.has(obj.categoryName)) return false;
      seenCategories.add(obj.categoryName);
      return true;
    }).map(obj => ({
      ...obj,
      name: obj.categoryName
    }));
  }

  private async loadInitialData(category_type ?:string) {
    try {
      const localWebID = localStorage.getItem("franchiseWebLocationId");
      const webID = document.getElementById('weblocationId') as HTMLInputElement;
      const weblocationId = webID?.value ? webID?.value : (localWebID || '0');
      const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value || '9';
      const categoryID = '0';

      const imagesApi = `${apiConfig.PhotosAPI}/${weblocationId}/${conceptId}/${categoryID}?skip=100`;
      const imagesResp = await cachedAPIWrapper(imagesApi);
      const categoriesData = this.removeDuplicateCategories(imagesResp?.photos || [])

      const imageData = imagesResp?.photos || [];
      this.categoryImages = categoriesData;
      this.imageListData = imageData;
      this.categoryImagesTemp = categoriesData;
      this.imageListDataTemp = imageData;

      if(categoriesData.length == 1){
        this.data = imagesResp?.photos || [];
        this.renderPageNumbers('', '');
        const data:any  =  this.changeDataToPage(imageData, 1, this.itemsPerPage)
        this.populateGallery2(data);
        const selectService = document.querySelector('.category-selection .select-basic') as HTMLSelectElement;
        if (selectService) {
            selectService.innerHTML = ''; // Clear if any options
            selectService.classList.add('hidden'); // Hide the dropdown
        }
      }
      else{
      this.initShowLength(categoriesData, imageData, category_type);
      setTimeout(() => {
        const container = document.querySelector(".category-list"); // Replace with your actual container selector
        const ins_gallery_falg = (document.querySelector('.gallery-page-section') as HTMLElement)?.classList?.contains('inspiration-gallery')
        if (container && categoriesData.length >= 1 && !ins_gallery_falg) {
          const categoryList = this.createCategoryList(categoriesData);
          container.innerHTML = ''
          container.appendChild(categoryList);
          const selectService = document.querySelector('.category-selection .select-basic') as HTMLSelectElement;
          selectService.innerHTML = '';
          // Create and append new <option> elements
          const option = document.createElement('option');
          option.value = 'All'; // Set the value attribute as needed
          option.textContent = 'All'; // Set the option text as needed
          selectService.appendChild(option);

          categoriesData?.forEach((optionData: any) => {
            const option = document.createElement('option');
            option.value = optionData.name; // Set the value attribute as needed
            option.textContent = optionData.name; // Set the option text as needed
            selectService.appendChild(option);
          });
              
        }
      }, 1000);
    }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  private changeDataToPage(array: any, page: any, itemsPerPage: any) {
    let filterArray = array;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = filterArray.slice(startIndex, endIndex);
    return currentPageItems;
}

  private renderPageNumbers(getSearch: string | null, getCategories: string | null): void {

    const paginationWrapper: any = document.querySelector(".photogallerydiv .pagination-wrapper");
    if(paginationWrapper){
      paginationWrapper.innerHTML = "";
    }

    const totalPages = Math.ceil(this.data.length / this.itemsPerPage);
    const prevLink = `${window.location.pathname}?page=${this.currentPage > 1 ? this.currentPage - 1 : this.currentPage}&search=${getSearch || ''}&categories=${getCategories || ''}`;
    const nextLink = `${window.location.pathname}?page=${this.currentPage < totalPages ? this.currentPage + 1 : this.currentPage}&search=${getSearch || ''}&categories=${getCategories || ''}`;
    const lastLink = `${window.location.pathname}?page=${totalPages}&search=${getSearch || ''}&categories=${getCategories || ''}`;

    const previousHTML = `<li class="page-list">
                            <a href="#" class="prev-btn">
                                <span class="sr-only">Previous</span>
                                <svg>
                                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-g500 "></use>
                                </svg>
                            </a>
                        </li>`;

    const nextHTML = `<li class="page-list">
                        <a href="#" class="next-btn">
                            <span class="sr-only">Next</span>
                            <svg>
                                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-g500 "></use>
                            </svg>
                        </a>
                    </li>`;

    if (totalPages > 1) {
        paginationWrapper?.insertAdjacentHTML('afterbegin', previousHTML);
    }

    if (totalPages <= 1) {
      paginationWrapper?.classList.add("hidden");
   }
    if (totalPages > 1) {
      for (let i = 1; i <= (totalPages > 3 ? 3 : totalPages); i++) {
        const pageItem = document.createElement("li");
        pageItem.className = "page-list";
        const pageLink = document.createElement("a");
        pageLink.href = `#`;
        pageLink.className = "page-number";
        pageLink.textContent = i.toString();
        pageLink.addEventListener('click', ()=>{
          const data:any = this.changeDataToPage(this.data, i, this.itemsPerPage);
          this.populateGallery2(data);
        })

        if (i === this.currentPage) {
            pageLink.classList.add("current-page");
            pageLink.setAttribute("aria-current", "page");
        }
        pageItem.appendChild(pageLink);
        paginationWrapper?.appendChild(pageItem);
      }
    }
    if (totalPages > 3) {
        paginationWrapper?.insertAdjacentHTML('beforeend', `<li class="page-list"><a href="${lastLink}" class="page-number">${totalPages}</a></li>`);
    }

    if (totalPages > 1) {
        paginationWrapper?.insertAdjacentHTML('beforeend', nextHTML);
    }
    
    const previousLi = paginationWrapper?.querySelector('.prev-btn');
    const nextLi = paginationWrapper?.querySelector('.next-btn');

    if (previousLi) {
        previousLi.addEventListener('click', () => {
         if( this.currentPage > 1){
          this.currentPage -= 1;
          const data:any = this.changeDataToPage(this.data, this.currentPage, this.itemsPerPage);
          this.populateGallery2(data);
         }

        });
    }

    if (nextLi) {
        nextLi.addEventListener('click', () => {
          if(totalPages > this.currentPage){
          this.currentPage += 1
          const data:any   =  this.changeDataToPage(this.data, this.currentPage, this.itemsPerPage)
          this.populateGallery2(data)
          }
          else{           
            nextLi.disabled = true
          }
         
        });
    }

}

  private createCategoryList(categories: { name: string }[]): HTMLElement {
    const ul = document.createElement("ul");
    ul.className = "category-list";

    const ALlData = [{ name: 'All' }, ...categories]
    let ulHTML = '';
    ALlData.forEach((category) => {
      const li = document.createElement("li");
      li.className = "category-item-tab";

      const input = document.createElement("input");
      input.type = "radio";
      input.className = "category-radio hidden";
      input.id = category.name;
      input.dataset.nbly = category.name;
      input.name = "category-tabs";
      input.value = category.name;
      if (category.name === "all" || category.name === "All") {
        input.checked = true;
      }

      input.addEventListener('click', () => {
        if (category.name === "all" || category.name === "All")
          this.loadInitialData('all');
        else {
          this.categoryImages = [{ name: category.name }];
          this.imageListData = this.imageListDataTemp.filter((item: any) => item.categoryName == category.name);

          this.initShowLength(this.categoryImages, this.imageListData, 'btnClick');
        }
      })

      const label = document.createElement("label");
      label.htmlFor = category.name;
      label.className = "category-btn";
      label.textContent = category.name;

      li.appendChild(input);
      li.appendChild(label);
      ulHTML = ulHTML + li.outerHTML;
    });
    ul.innerHTML = ulHTML;
    return ul;
  }

  private initShowLength(categoriesData: { name: string }[], imageData: Photo[], id?: string) {
    if (id && id != 'btnClick' && id != 'all') {
      this.perPageItem[id] = (this.perPageItem[id] || 0) + 3;
    } else if(id == 'all'){
      categoriesData.forEach((category) => {
        this.perPageItem[category.name] = 3;
      });
    }else {
      categoriesData.forEach((category) => {
        this.perPageItem[category.name] = (id == 'btnClick' ? 0 : this.perPageItem[category.name] || 0) + 3;
      });
    }

    this.populateGallery(imageData, categoriesData);
    this.onCLickLoadMore();

  }

  private onCLickLoadMore() {
    const loadMore = document.querySelectorAll('.loadMore');
    loadMore.forEach((item: any) => {
      item.addEventListener('click', () => {
        this.initShowLength(this.categoryImages, this.imageListData, item.id);
      });
    });
  }

  private onClickToTop () {
    const backToTop = document.querySelectorAll('.backToTop');
    backToTop.forEach((item) => {
      item.addEventListener('click',() => {
        item.scrollTop = 0;
      })
    })
  }
  private populateGallery(data: Photo[], categoriesData: { name: string }[] | undefined) {
    const photoDomain = `${window.location.origin}/`;
    const gallerymain = document.querySelector('.gallery-page-main');
    const listData = categoriesData || [];
    const fragment = document.createDocumentFragment();

    listData.forEach((catData) => {
      const itemsData = data.filter((x) => x.categoryName === catData.name);
      const categoryDiv = document.createElement('div');
      categoryDiv.classList.add('photo-gallery-category');

      const headingDiv = document.createElement('div');
      headingDiv.classList.add('category-heading');
      headingDiv.textContent = catData.name;

      const ul = document.createElement('ul');
      ul.classList.add('photo-card-list');
      let ulHTML = '';
      itemsData
        .filter((fData, index) => index < this.perPageItem[catData.name])
        .forEach((item, index) => {
          const li = document.createElement('li');
          li.classList.add('photo-card-item');

          const photoCardDiv = document.createElement('div');
          photoCardDiv.classList.add('photo-card', 'card-for-modal');

          photoCardDiv.style.cursor = 'pointer';

          const cardImageDiv = document.createElement('div');
          cardImageDiv.classList.add('card-image');

          const img = document.createElement('img');
          img.src = `${photoDomain}photos/${item.imageURL}`;
          img.alt = item?.altText ? item?.altText : 'photo gallery image';
          img.loading = "lazy";

          img.addEventListener('click', () => {
            this.openModal(itemsData, photoDomain);
            this.currentSlide(index + 1,itemsData);
          });

          const cardBodyDiv = document.createElement('div');
          cardBodyDiv.classList.add('card-body');

          const cardTextP: any = document.createElement('p');
          cardTextP.classList.add('card-text');
          cardTextP.textContent = item?.shortCaption;

          cardImageDiv.appendChild(img);
          cardBodyDiv.appendChild(cardTextP);
          photoCardDiv.appendChild(cardImageDiv);
          photoCardDiv.appendChild(cardBodyDiv);
          li.appendChild(photoCardDiv);
          ulHTML = ulHTML + li.outerHTML;
          
        });
      ul.innerHTML = ulHTML;

      let ctaWrapperDiv = document.createElement('div');
      ctaWrapperDiv.classList.add('cta-wrapper');

      const loadMoreBtn = document.createElement('a');
      loadMoreBtn.id = catData.name;
      loadMoreBtn.classList.add('primary-btn', 'btn', 'loadMore');
      loadMoreBtn.textContent = 'Load More';
      
      if(itemsData.length > this.perPageItem[catData.name]){
        ctaWrapperDiv.appendChild(loadMoreBtn);
      }else { 
        let backToTopBtn = document.createElement('a');
        backToTopBtn.id = 'backToTopBtn-id';
        backToTopBtn.href = "#";
        backToTopBtn.classList.add('primary-btn', 'btn', 'backToTop');
        backToTopBtn.textContent = 'Back To Top';
        ctaWrapperDiv.appendChild(backToTopBtn);
        this.onClickToTop();
      }

      categoryDiv.appendChild(headingDiv);
      categoryDiv.appendChild(ul);
      categoryDiv.appendChild(ctaWrapperDiv);
      fragment.appendChild(categoryDiv);
    });
    
    if (gallerymain) {
      gallerymain.innerHTML = '';
      gallerymain.appendChild(fragment);
    }
  }

  private populateGallery2(data: any[]) {
    const photoDomain = `${window.location.origin}/`;
    const gallerymain = document.querySelector('.gallery-page-main');
    const categoryDiv = document.createElement('div');
    categoryDiv.classList.add('photo-gallery-category');
    const fragment = document.createDocumentFragment();
    const ul = document.createElement('ul');
    ul.classList.add('photo-card-list');
    let htmlStr = '';
    data.forEach((item, index) => {
        const li = document.createElement('li');
        li.classList.add('photo-card-item');

        const photoCardDiv = document.createElement('div');
        photoCardDiv.classList.add('photo-card', 'card-for-modal');

        photoCardDiv.style.cursor = 'pointer';

        const cardImageDiv = document.createElement('div');
        cardImageDiv.classList.add('card-image');

        const img = document.createElement('img');
        img.src = `${photoDomain}photos/${item.imageURL}`;
        img.alt = item?.altText ? item?.altText : 'photo gallery image';
        img.loading = "lazy";

        img.addEventListener('click', () => {
          this.openModal(data, photoDomain);
          this.currentSlide(index + 1,data);
        });

        const cardBodyDiv = document.createElement('div');
        cardBodyDiv.classList.add('card-body');

        const cardTextP: any = document.createElement('p');
        cardTextP.classList.add('card-text');
        cardTextP.textContent = item?.shortCaption;

        cardImageDiv.appendChild(img);
        cardBodyDiv.appendChild(cardTextP);
        photoCardDiv.appendChild(cardImageDiv);
        photoCardDiv.appendChild(cardBodyDiv);
        li.appendChild(photoCardDiv);
        htmlStr = htmlStr + photoCardDiv.outerHTML;
    });
    ul.innerHTML = htmlStr;

    const ctaWrapperDiv = document.createElement('div');
    ctaWrapperDiv.classList.add('cta-wrapper');
    categoryDiv.appendChild(ul);
    categoryDiv.appendChild(ctaWrapperDiv);
    fragment.appendChild(categoryDiv);

    if (gallerymain) {
      gallerymain.innerHTML = '';
      gallerymain.appendChild(fragment);
    }
  }

  openModal(photoData: any, photoDomain: any) {   
    if (this.modal) {
      this.modal.style.display = "flex";

      const ulModalElement = document.querySelector('#galleryModal .modal-content') as HTMLUListElement;
      if (ulModalElement) {
        ulModalElement.innerHTML = '';
        let htmlStr = '';
        photoData.forEach((imageInfo: any, index: any) => {
          const liElement = document.createElement('li');
          liElement.classList.add('pop-up-slider');

          const galleryCard = document.createElement('div');
          galleryCard.classList.add('gallery-card');

          const cardImg = document.createElement('div');
          cardImg.classList.add('card-img');

          const img = document.createElement('img');
          img.src = `${photoDomain}photos/${imageInfo.imageURL}` || '';
          img.alt = imageInfo.altText;;
          img.width = 321;
          img.height = 256;

          cardImg.appendChild(img);

          const closeModalButton = document.querySelector(".gallery-modal .close");
          closeModalButton?.addEventListener('click', () => {
            this.closeModal();
          });

          const cardBody = document.createElement('div');
          cardBody.classList.add('card-body');

          const cardTitle = document.createElement('span');
          cardTitle.classList.add('card-title');
          cardTitle.textContent = imageInfo.shortCaption ? `${imageInfo.shortCaption} -` : '';

          const cardDesc = document.createElement('span');
          cardDesc.classList.add('card-desc');
          cardDesc.textContent = imageInfo.longCaption ? imageInfo.longCaption : '';

          // Create the necessary elements and set attributes
          const cardFooter = document.createElement("div");
          cardFooter.classList.add("card-footer");

          const cardSocialList = document.createElement("ul");
          cardSocialList.classList.add("card-social");

          const socialIcons = [
            "facebook-white",
            "twitter-white",
            "pinterest-white",
            "linkedin-white",
            "link-white",
          ];

          socialIcons.forEach((iconName) => {
            const listItem = document.createElement("li");
            listItem.classList.add("card-social-list");

            const link = document.createElement("a");
            link.href = "#";
            link.target = "_blank";
            link.rel = "noopener";
            link.classList.add("card-social-link");
            link.setAttribute("aria-label", iconName);

            link.addEventListener('click',(e)=>{
              e.preventDefault()
              e.stopPropagation()

              if(iconName == 'facebook-white'){
                shareOnFacebook(`${photoDomain}photos/${imageInfo.imageURL}` || '', '')
              }
             else if(iconName == 'twitter-white'){
                shareOnTwitter('', `${photoDomain}photos/${imageInfo.imageURL}` || '');
              }

             else if(iconName == 'pinterest-white'){
                shareOnPinterest(`${photoDomain}photos/${imageInfo.imageURL}` || '','')
              }

             else if(iconName == 'linkedin-white'){
                shareOnLinkedIn(`${photoDomain}photos/${imageInfo.imageURL}` || '')
              }

              
             else if(iconName == 'link-white'){
                let textToCopy = `${photoDomain}photos/${imageInfo.imageURL}` || ''
                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        alert("Text copied to clipboard");
                        console.log("Text copied to clipboard: " + textToCopy)
                    })
                    .catch((error) => {
                        console.error("Unable to copy text: ", error);
                    });
              }          

            })

            const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.classList.add("social-icon");

            const use = document.createElementNS("http://www.w3.org/2000/svg", "use");
            use.setAttribute("href", `/brand/_assets/images/icons/social-icon-sprite.svg#${iconName}`);

            svg.appendChild(use);
            link.appendChild(svg);
            listItem.appendChild(link);
            cardSocialList.appendChild(listItem);
          });

          // Append the elements to the document
          cardFooter.appendChild(cardSocialList);
          // You can replace `document.body` with the target parent element
          cardBody.appendChild(cardTitle);
          cardBody.appendChild(cardDesc);
          galleryCard.appendChild(cardImg);
          galleryCard.appendChild(cardBody);
          galleryCard.appendChild(cardFooter);
          liElement.appendChild(galleryCard);
          htmlStr = htmlStr + liElement.outerHTML;
        });
        ulModalElement.innerHTML = htmlStr;
      }
    }
  };

  closeModal() {
    if (this.modal) {
      this.modal.style.display = "none";
    }
  };

  currentSlide(n: number,itemsData:any) {
    this.showSlides(this.slideIndex = n);
    const prevBtn:any=document.querySelector('#galleryModal .prev');
    const nextBtn:any=document.querySelector('#galleryModal .next');

      if(n===itemsData?.length && itemsData.length > 1){
        this.disableGalleryButton(nextBtn, true);
        this.disableGalleryButton(prevBtn, false);
    }
    else if(n===1 && itemsData.length > 1){
        this.disableGalleryButton(prevBtn, true);
        this.disableGalleryButton(nextBtn, false);
    }
    else{
        this.disableGalleryButton(nextBtn, true);
        this.disableGalleryButton(prevBtn, true);
    }
  };
  showSlides(n: number) {
    let i;
    const slides = document.getElementsByClassName("pop-up-slider");

    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      (slides[i] as HTMLElement).style.display = "none";
    }
    (slides[this.slideIndex - 1] as HTMLElement).style.display = "flex";
  };

  disableGalleryButton(btn: HTMLButtonElement | null, isDisabled: boolean) {
    if (btn) {
        btn.disabled = isDisabled;
    }
}
}

// Initialize the PhotoGallery class
const photoGallery = new PhotoGallery2();
