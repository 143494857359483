import { REGEX } from "../constants/regex";
import { DomainIdentifier } from "./domainService";
import { LocalizationIdentifier } from "./localizationService";

export function modalVisibilityCheck(ZipCode:any) {
    // Get the domain and localization identifiers
    const domain = DomainIdentifier();
    const localization = LocalizationIdentifier();
    const countryZip = !REGEX.sendCaPostalCode.test(ZipCode?.trim()) ? 'us' : 'ca';

    // Check if the domain is "us" and localization is "ca"
    if ((domain === "us" && localization === "ca") || (domain === "us" && countryZip === "ca")) {
        return true;
    } else {
        return false;
    }
}