import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { OnLoaderServices } from '../services';
import { apiConfig } from '../config/apiConfig';
import { isTouchDevice } from '../util/device-detection';
import { isTestEnvironment } from '../apiCaching/POST-Requests/helperModules/helperFunctions';
import { generateRandomString } from '../util/sessionCorelationID';

const loggerURLTransformer = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'apikey': process.env.URL_TRANSFORMER_ERROR_LOG_KEY,
    }
});

// Define a type for API error response data
interface ApiError {
    error: string;
    code: number;
    Message?: string;
}

// Function to strip API Key
function removeApiKey(url: string): string {
    const urlObj = new URL(url);
    // Check if 'apikey' parameter exists
    if (urlObj.searchParams.has('apikey')) {
        urlObj.searchParams.delete('apikey');
    }
    return urlObj.toString();
}

// Centralized function to send log requests (for both error and performance)
async function sendLogRequest(url: string, data: any, testEnvironment: boolean) {
    const request = {
        method: 'POST',
        url,
        data
    };

    try {
        if (testEnvironment) console.log(request);
        const response = await loggerRequest(request);
        if (testEnvironment) console.log('Log sent to URL Transformer:', response);
    } catch (err) {
        if (testEnvironment) console.error('Failed to send log to URL Transformer', err);
    }
}

// Performance benchmarking function
export function performanceBenchmark(url: string, uniqueID:string) {
    const testEnvironment: boolean = isTestEnvironment();
    performance.mark(`${uniqueID}-end`);
    performance.measure(`${uniqueID}-duration`, `${uniqueID}-start`, `${uniqueID}-end`);

    const entries = performance.getEntriesByName(`${uniqueID}-duration`);
    if (entries.length > 0) {
        const duration: number = entries[0].duration || 0;
        if (duration > parseInt(process.env.API_LOGGER_THRESHOLD ?? '5000', 10)) {
            callPerformanceLogger(url, duration);
        }
        if (testEnvironment) console.log(`${url}: ${duration} ms`);
    } else if (testEnvironment) {
        console.warn('No performance entries found for "api-call-duration"');
    }

    // Clear marks to prevent memory leaks
    performance.clearMarks(`${uniqueID}-start`);
    performance.clearMarks(`${uniqueID}-end`);
    performance.clearMeasures(`${uniqueID}-duration`);
}

// Handle API errors and extract meaningful information
export function handleApiError(error: AxiosError<ApiError>, callingURL?: string): Error {
    if (error.response) {
        const { status, data } = error.response;
        const errorMessage = data.Message ?? 'API Error';
        let fallbackErrorResponse = (data ?? 'No message received from API response')as any;

        if(fallbackErrorResponse === "") 
            fallbackErrorResponse = 'No message received from API response';
        
        if(status === 404)
            fallbackErrorResponse = 'Resource does not exist';
        
        const logData = {
            httpStatusCode: status.toString(),
            message: `${data.Message ?? fallbackErrorResponse} - Source URL - ${window.location.href}` ,
            timeStamp: new Date(),
            apiEndpoint: removeApiKey(callingURL ?? ''),
            deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
        };
        callErrorLogger(logData);
        return new Error(`${errorMessage} (Status: ${status})`);
    } else if (error.request) {
        return new Error('No response received from the server');
    } else {
        return new Error('Error occurred while setting up the request');
    }
}

// Call the performance logger
async function callPerformanceLogger(callingURL: string, latency: number) {
    const logData = {
        source: "js-api-latency",
        apiEndpoint: `${removeApiKey(callingURL)} - Source URL - ${window.location.href}`,
        latency: `${latency} ms`,
        timeStamp: new Date(),
        deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
    };

    const testEnvironment = isTestEnvironment();
    sendLogRequest(`${window.location.origin}/${apiConfig.ApiLogperformanceEndpoint}`, logData, testEnvironment);
}

// Call error logger
async function callErrorLogger(logData: any) {
    const testEnvironment = isTestEnvironment();

    //Uncomment the following line to send the error stack and change errorMessages: [logData] -> errorMessages: errors
    // const errorService = OnLoaderServices.getInstance();
    // const errors = errorService.getLoggedErrors();
    // errors.push(logData);

    const url = `${window.location.origin}/${apiConfig.ApiLogerrorEndpoint}`;
    if (!logData.apiEndpoint) {
        if (testEnvironment) console.log("Error in determining the API endpoint of the failed call");
        return;
    }

    if (logData.apiEndpoint !== url) {
        sendLogRequest(url, { source: "js-code", errorMessages: [logData] }, testEnvironment);
    } else if (testEnvironment) {
        console.error("Error Logger API failed.");
    }
}

// Logger API request
export async function loggerRequest<T>(config: AxiosRequestConfig): Promise<T> {
    try {
        const response: AxiosResponse<T> = await loggerURLTransformer.request(config);
        return response.data;
    } catch (error: any) {
        throw handleApiError(error, config.url);
    }
}

//performace mark fingerprint generator
export function uniqueIDGenerator(url:string){
    return `api-call-${url}-${generateRandomString(5)}`
}

