import axios from "axios";
import { apiConfig } from "../config/apiConfig";
import { StateHandler } from "../util/getStateFun";
import {startLoader, stopLoader} from "../util/loader"
import { getCountryCode } from "../location-search-map/getCountryCode";
import { NearbyLocations } from "../util/nearbyLocations";
import { getBrandDetailsFromBrandJson } from "../util/share";

export class GetLocateLocation {

  private handleNearbyLocationsFlow(inputArg:any){
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let franchiseLimit: number,units: string,nearbyLocations;
    getBrandDetailsFromBrandJson(conceptCode)
      .then((brandDetail)=>{

        const findChange50mileflag = brandDetail?.enable_50mile_areameasure_findChangeMyLocation as boolean;
        const flag = brandDetail?.enable_50mile_areameasure as boolean;

        if (findChange50mileflag) {
          this.handleEnable50MilesFindMyLocation(inputArg,brandDetail);
        } else if (flag) {
          units = brandDetail?.area_measure_values ? this.findUnits(brandDetail.area_measure_values) : "miles";
          franchiseLimit = brandDetail?.nearest_franchiselimit ? Number(brandDetail.nearest_franchiselimit) : 50;
          this.callNearbyLocations(units, franchiseLimit, inputArg);
        }
        else {
          this.handleErrorScenario();
        } 
      })
      .catch((err:any)=>{
          console.log("Unable to read brands.json. Using default range 50 miles radius...");
          units = "miles";
          franchiseLimit = 50;
          this.callNearbyLocations(units, franchiseLimit,inputArg);
      });       
}
  private callNearbyLocations(units:string, franchiseLimit:number,inputArg:any){
    const nearbyLocations = new NearbyLocations(units, franchiseLimit);
    nearbyLocations.searchNearbyLocations(inputArg)
    .then((data: any)=>{
      let containsFlag;
      if(data){
        containsFlag = true;
      }
      else{
        containsFlag = false;
      }
      const brandData = {
        "containingData": containsFlag,
        "franchiseLimit": franchiseLimit,
        "units": units,
        "resultData": data,
        "searchQuery": inputArg
      }
      sessionStorage.setItem("nearbyLocations",JSON.stringify(brandData));
      this.openLocationsPage();
    })
    .catch(()=>{
      console.log("Error searching nearby locations");
      this.handleErrorScenario();
    });
  }
  private openLocationsPage(){
    window.location.replace(window.location.origin + "/locations/");
  }
  private handleErrorScenario(){
    const modalElement = document.querySelector('#glb-find-local') as HTMLElement;
    modalElement.classList.add('modal');
    const text1 = document.getElementById("step1-text1") as HTMLElement;
    const text2 = document.getElementById("step1-text2") as HTMLElement;      
    text1.style.display = "none";
    text2.style.display = "block";
  }
  private findUnits(area_measure_values:string) : string {
    if(area_measure_values.includes('measure_km')){
        return "km";
    }
    return "miles";
  }
  public locateLocation(sDeviceType: string): void {

    let sAddressParam = '';

    if (sDeviceType === 'desk') {
      const element = document.getElementById('local-search-modal') as HTMLInputElement;
      const zipcode = element?.value.trim();
      if(zipcode){
        sessionStorage.setItem('temp_zipcode', zipcode);
      }
      const domainName = location.hostname.toLowerCase();
      const isWebSite =
        domainName.indexOf('-ca-') === -1 && domainName.indexOf('.ca') === -1 && domainName.indexOf('-ca.') == -1
          ? 'us'
          : 'ca';

      if (isWebSite === 'ca') {
        const formattedZipcode = zipcode.indexOf(' ') === -1 ? zipcode.replace(/^(.{3})(.*)$/, '$1 $2') : zipcode;
        sAddressParam = formattedZipcode;
      } else if (zipcode && zipcode.length > 0) {
        sAddressParam = zipcode;
      }
    } else if (sDeviceType === 'mob') {
      const inputLocalZip = this.getValue('inputLocal-zip');
      if (inputLocalZip.length > 0) {
        sAddressParam = inputLocalZip;
      }
    }

    startLoader();
    const apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(sAddressParam));
    axios.get(apiUrl, {
      headers: {
        'Cache-Control': 'max-age=0',
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.length > 0) {

            stopLoader();
            const sRedirectURL = result[0].locationWebsiteUrl ?? '';
            sessionStorage.setItem('alternateConcept', result[0].alternateConcept);
            localStorage.setItem("franchiseWebLocationId", result[0].franchiseWebLocationId);
            let countryCode = getCountryCode();
            sessionStorage.setItem('countryCode', countryCode);
            localStorage.setItem('doingBusinessAs', result[0].doingBusinessAs);
            sessionStorage.setItem('franchiseeName', result[0].franchiseeName);
            sessionStorage.setItem('sAddressParam', sAddressParam) 
            const hostURL = new URL(sRedirectURL);
            const pathname = hostURL.pathname;
            const redirectURL = new URL(window.location.href)
            const redirectHostName = redirectURL.host
            const conceptId:any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
            if(conceptId==25){
              localStorage.setItem('hms_redirect_local', 'true');
            }

            const domainName = location.hostname.toLowerCase();
            const isWebSite =
              domainName.indexOf('-ca-') === -1 && domainName.indexOf('.ca') === -1 && domainName.indexOf('-ca.') == -1
                ? 'us'
                : 'ca';
            if(isWebSite=='ca' && sRedirectURL){
              window.location.href = sRedirectURL;
            }else{
              window.location.replace(`https://${redirectHostName}${pathname}`);
            }
            localStorage.setItem('alternateConcept', result[0].alternateConcept);
            localStorage.setItem('franchiseWebLocationId', result[0].franchiseWebLocationId);
            // brandLocalHeader();
        
        } else {
          this.handleNearbyLocationsFlow(sAddressParam);
          stopLoader();
        }
      })
      .catch((error) => {
        stopLoader();
        const duplicate = error.response.data;

        if (duplicate.Message === 'Full address is needed to narrow down results') {
          const stateHandler = new StateHandler();
          stateHandler.getStateFun(); 

          const step1 = document.querySelector('.step-1') as HTMLElement;
          const step2 = document.querySelector('.step-2') as HTMLElement;

          if (step1) {
            step1.classList.add('hidden');
          }

          if (step2) {
            step2.classList.remove('hidden');
            step2.classList.add('block');

            const closeLocalModal = document.querySelector('.close-modal-btn');
            closeLocalModal?.addEventListener('click', function handleClick(event) {

            step2.classList.add("hidden");
            step1.classList.remove("hidden");
            })
          }
          const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
          modalZipCode.value = sAddressParam;
        }
      });
  }

  private getValue(id: string): string {
    const element = document.getElementById(id) as HTMLInputElement;
    return element ? element.value : '';
  }

  private handleEnable50MilesFindMyLocation(sAddressParam:any,brandDetail:any){
    const units = 'miles'; //brandDetail?.area_measure_values_findChangeMyLocation;
    const franchiseLimit = brandDetail?.nearest_franchiselimit_findChangeMyLocation || 50;
    const nearbyLocations = new NearbyLocations(units, franchiseLimit);
    nearbyLocations.searchNearbyLocations(sAddressParam)
    .then((data: any)=>{
      console.log("NearByLocation:",data);
      console.log("NearByLocation length:",data?.length);
      if(data?.length > 1){
        this.openNearByLocationsModal(data,sAddressParam,brandDetail);
      } else if (data?.length === 1){ 
        this.findMyChangeLocationRedirectAndLocalise(data[0].zipCode)
        console.log("Redirecting to selected location");
      }
      else {
        this.handleErrorScenario();
      }
    }).catch((err)=>{
      console.log("Error searching nearby locations",err);
    });
  }

  openNearByLocationsModal(resultData: any,zipCode:any,brandDetail:any) {
    stopLoader();
    const modal = document.getElementById("findnearby-user-zip-code");
    if (modal) {
        const totalResults = resultData?.length;
        const resultsTextElement = document.querySelector('.results-text');
        if (resultsTextElement) {
            resultsTextElement.textContent = ` ${totalResults} Results for ${zipCode}`;
        }
        const ul = document.querySelector('.user-zip-result-list') as HTMLElement;
        ul.innerHTML = '';
        const sortedResultData = resultData?.slice().sort((a: any, b: any) => a.distance - b.distance);
        sortedResultData?.forEach((item: any) => {
            const liElement = document.createElement('li');
            liElement.className = 'user-zip-result-item';
            const distanceRounded = Math.round(item.distance) + ' mi.';
            const pNum = item?.websiteTrackingPhone?.length > 0 ? item.websiteTrackingPhone[0] : item.phoneNumber;
            liElement.innerHTML = `
            <div class="locaion-info">
            <div class="locaion-time">
              <svg class="icon">
                <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use>
              </svg>
              <span class="location-text">${distanceRounded}</span>
            </div>
            <div class="locaion-name">
              <p class="location-main">${item?.doingBusinessAs}</p>
              <a class="location-call" href="tel:${pNum}">(${pNum?.slice(0, 3)}) ${pNum?.slice(3, 6)}-${pNum?.slice(6)}</a>
              <p class="location-zip-code hidden">${item?.zipCode}</p>
            </div>
          </div>
          ${item?.websiteUrl ? 
            `<div class="locaion-btn">
          <a href=${item?.websiteUrl} class="primary-link">${brandDetail?.websitelink_findChangeMyLocation}</a>
          </div>`:''}`;
            ul?.appendChild(liElement);
        });

        const closeModalButton = document.getElementById("close-local-modal");
        if (closeModalButton) {
            closeModalButton.click();
        }
        (document.querySelector('.findnearby-modal') as HTMLElement)?.click();
    }
  }
  
  findMyChangeLocationRedirectAndLocalise(sAddressParam:any){
    startLoader();
    const apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(sAddressParam));
    axios.get(apiUrl, {
      headers: {
        'Cache-Control': 'max-age=0',
      },
    })
      .then((response) => {
        const result = response.data;
        if (result.length > 0) {
            stopLoader();
            const sRedirectURL = result[0].locationWebsiteUrl ?? '';
            sessionStorage.setItem('alternateConcept', result[0].alternateConcept);
            localStorage.setItem("franchiseWebLocationId", result[0].franchiseWebLocationId);
            let countryCode = getCountryCode();
            sessionStorage.setItem('countryCode', countryCode);
            localStorage.setItem('doingBusinessAs', result[0].doingBusinessAs);
            sessionStorage.setItem('franchiseeName', result[0].franchiseeName);
            sessionStorage.setItem('sAddressParam', sAddressParam) 
            const hostURL = new URL(sRedirectURL);
            const pathname = hostURL.pathname;
            const redirectURL = new URL(window.location.href)
            const redirectHostName = redirectURL.host;
            window.location.replace(`https://${redirectHostName}${pathname}`);
        }
      });
  }

}

