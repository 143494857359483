import { getConceptCode } from "./getConceptCode";
import { getCountryCode } from "./getCountryCode";
export const locationSearchMapConfig = {    
    initView: {
        lat: getCountryCode() === 'us' ? '43' : '50',
        lng: getCountryCode() === 'us' ? '-99' : '-100',
        zoom: '4',
        mobileZoom:'2.2',
    },
    franchiseWebLocationsApiCall: `${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/concepts/${getConceptCode()}/franchiseweblocations?apikey=${process.env.JS_API_KEY}`,
    apiKey: process.env.JS_Heremap_API_KEY,
    geoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/geocode',
    revGeoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/revgeocode',
    fullAttributeApiUrl: `${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/fullattribute/franchiseWeblocations/search`,
    nblyApiKey: process.env.JS_API_KEY,
    mobileViewWidth: 992,
    desktopMapContainerCol: 'map-wrapper-mobile',
    mobileMapContainerCol: 'map-wrapper'
}
// set level to 'debug' if you want to fetch data from ./franchiseWebLocations.json
export const debugConfig = {
    level: 'prod'
}
export const brandColorCode:any = {
    "MRE":"#006AC6",
    "RBW":"#18487F",
    "WDG":"#500878",
    "DVW":"#463C96",
    "SHG":"#005489",
    "HMS":"#009578",
	"LAP":"#C93F01",
	"JUK":"#ED1C24",
	"PDS":"#00523D",
	"MOJ":"#43B02A",	
	"MDG":"#0B408F",
    "MLY":"#071D49",
    "MRR":"#003DA6",
    "GUY":"#4A7729",
    "MRA":"#F4633A",
    "FSA":"#FF6900"

}