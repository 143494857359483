const displayCacheLog = (process.env.SHOW_CACHE_LOG && typeof JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase()) === 'boolean') ? JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase()) : false;
const enableServiceWorker = (process.env.ENABLE_SERVICE_CACHE && typeof JSON.parse(process.env.ENABLE_SERVICE_CACHE.toLowerCase()) === 'boolean') ? JSON.parse(process.env.ENABLE_SERVICE_CACHE.toLowerCase()) : false;
if(enableServiceWorker && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register('/brand/_assets/js/sw1.js', { scope: '/' })
    .then(registration => {
        if(displayCacheLog)
            console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
        if(displayCacheLog)
            console.error('Service Worker registration failed:', error);
    });
}

export * from './opus2';
export * from './global';
