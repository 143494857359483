import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import {getCachedAPIResponse} from "../apiCaching/apiWrapper";
import { getImageReplacemnt} from "./imageHelper";

let page = 1;
let isLoading = false;
export class Testimonial {
  private data: any;
  private pageNumber: number;
  private pageSize: number;
  private conceptId: any;
  private countryId: any;
  private wrapperElement: any;
  private selectedStarRating: number;
  readonly popoverElement:HTMLElement | null;
  readonly upArrow:HTMLElement | null;
  readonly downArrow:HTMLElement | null;
  readonly resetLink:HTMLElement | null;
  private overAllRatings: string;

  constructor() {
    this.resetLink = document.getElementById('resetLink');
    this.upArrow = document.getElementById('upArrow');
    this.downArrow = document.getElementById('downArrow');
    this.popoverElement = document.getElementById('popover');
    this.pageNumber = 1; // Initial page number
    this.pageSize = window.innerWidth >= 768 ? 30 : 4; // Number of items per page
    this.conceptId = (document.getElementById("conceptId") as HTMLInputElement)?.value;
    this.selectedStarRating = 0;
    this.overAllRatings = '5/5';
    if(sessionStorage.getItem('countryCode') != null){
      this.countryId = sessionStorage.getItem('countryCode')?.toLowerCase()=="us" ? "1" : "2";
    }else{
        this.countryId = (document.getElementById("countryCode") as HTMLInputElement)?.value.toLowerCase() == "us" ? "1" : "2";
    }
    
    document.addEventListener('DOMContentLoaded', () => {
    
    const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value; 

    if(pageType && pageType.toLocaleLowerCase() !=="opus 2 location map"){
      const testimonialDOMisPresent = document.querySelector(".testimonials-section")
      if(testimonialDOMisPresent)
        this.getData();
    }

    this.wrapperElement = document.querySelector('.testimonials-section .container');
        let btn = `<div class="testi-btn-wrapper">                
                 <a href="#" class="primary-btn"> See More...</a>                
                 </div>`
       this.wrapperElement?.insertAdjacentHTML('beforeend', btn);

    const seeMoreButton = document.querySelector('.testi-btn-wrapper .primary-btn');
    if (seeMoreButton) {
      seeMoreButton.addEventListener('click', this.seeMoreData.bind(this));
    }

      this.getTotalReviews();
      //Show the list of star rating dropdown on click event of dropdown arrow
      const starRatingDrpdwn = document.getElementById('popoverBtn');
      if (starRatingDrpdwn) {
        starRatingDrpdwn.addEventListener('click', () => {
          this.handleDropdownToggle();
        });
      }
      //Show the list of star rating dropdown on click event of stars displayed
      const starsDisplay = document.getElementById('starsDisplay');
      if (starsDisplay) {
        starsDisplay.addEventListener('click', () => {
          this.handleDropdownToggle();
        });
      }


    this.resetLink?.addEventListener('click', () => {
      this.onResetClick();
    });

  });
  }

  private async getData() {
    const weblocationId:any = localStorage.getItem("franchiseWebLocationId") ? localStorage.getItem("franchiseWebLocationId") : ((document.getElementById('weblocationId') as any)?.value ? (document.getElementById('weblocationId') as any)?.value : '');
    let ratingParam = `&StarRating=${this.selectedStarRating?this.selectedStarRating:5}`;
    const url = apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${this.conceptId}&CountryId=${this.countryId}&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}&FranchiseWebLocationId=${weblocationId}${ratingParam}`
    //calling the Testimonial API from the apiWrapper.ts file
    
    try{   
      let result = await getCachedAPIResponse(url, apiCaching.TestimonyAndReview);
      const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
      this.wrapperElement?.classList.remove('hidden');
      if(seeMoreButton){
        if(result.length ==0 || result.length < this.pageSize){
          seeMoreButton.style["display"] = "none";
          isLoading = false;
        }
        else
        {
          seeMoreButton.style["display"] = "flex";
          isLoading = true
        }  
      }
      const lists = result.map((item: any) => {
        return this.createTestimonialHTML(item);
      })
      const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper');
      if (testimonialsList) {
        testimonialsList.innerHTML = lists.join('')
      }

    }
    catch{
        this.wrapperElement?.classList.add('hidden');
        isLoading = false
    }
  }
  
  public generateRatingStars(rating: number): string {
    const fullStar = '<span class="star full">★</span>';
    const emptyStar = '<span class="star empty">☆</span>';
    const numberOfFullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const numberOfEmptyStars = hasHalfStar ? 5 - numberOfFullStars - 1 : 5 - numberOfFullStars;
    return fullStar.repeat(numberOfFullStars) + (hasHalfStar ? '<span class="star half">★</span>' : '') + emptyStar.repeat(numberOfEmptyStars);
  }
  public createTestimonialHTML(data: any): string {
    let photoDiv = '';
    if(data.reviewPhotoUrl === null){
      photoDiv = getImageReplacemnt(data.reviewAuthorName);
    }   
    else{
      photoDiv = `<div class="testi-profile"><img src="${data.reviewPhotoUrl}" alt="profile photo" width="72" height="73"></div>`;  
    }
    return `
        <li class="testi-main-wrap">
          <div class="testi-main">
            <div class="testi-details">
              ${photoDiv}
              <div class="testi-bio">
                <strong class="user-name">${data.reviewAuthorName}</strong>
                ${data.city ? `<address class="address">${data.city}</address>` : ''}
                <div class="rating">
                  ${this.generateRatingStars(data.reviewStarRating)}
                </div>
                ${data.reviewSourceType ?? "" ? `<span class="review-source">via ${data.reviewSourceType}</span>` : ""}
              </div>
            </div>
            <div class="testi-comment">
            ${data.reviewBody ? `<p class="body-text-sm">“${data.reviewBody}”</p>` : ''}
             ${data.reviewResponseBody ? `<div class="testi-reply">
                <p class="body-text-sm">Owner Response:</p>
                <p class="body-text-sm">“${data.reviewResponseBody}”</p>
              </div>` : ''}
            </div>
          </div>
        </li>
      `;
  }
  private seeMoreData(event: any) {
    event.preventDefault();
    this.pageNumber++; // Increment the page number for the next page of data
    let ratingParam = `&StarRating=${this.selectedStarRating?this.selectedStarRating:5}`;
    const request = {
      method: 'GET',
      url: apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${this.conceptId}&CountryId=${this.countryId}&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}&FranchiseWebLocationId=${localStorage.getItem("franchiseWebLocationId") ?? ''}${ratingParam}`
    };
    apiRequest(request)
      .then((result: any) => {
        const lists = result.map((item: any) => {
          return this.createTestimonialHTML(item);
        });

        const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper');
        if (testimonialsList) {
          testimonialsList.innerHTML += lists.join('');
        }
        const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
        if(!result || result.length ==0 || result.length < this.pageSize){
          seeMoreButton.style["display"] = "none";
        }
        else{
          seeMoreButton.style["display"] = "flex";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private handleStarRatingFilter() {
    const ratingForm = document.getElementById("ratingForm");
    if (ratingForm) {
        const radioButtons = ratingForm.querySelectorAll('input[name="rating"]') as NodeListOf<HTMLInputElement>;
        // Add a change event listener to each radio button
        radioButtons.forEach((radioButton) => {
            radioButton.onclick =  () => {
                const selectedRadio = ratingForm.querySelector('input[name="rating"]:checked') as HTMLInputElement;
                if (selectedRadio) {
                    this.selectedStarRating = parseInt(selectedRadio.value, 10);
                    this.pageNumber = 1;//reset page number to 1 on selecting star filters.
                    this.getData();
                    this.showHideRatingDrpDwn();
                    this.updateStarsDisplay(this.selectedStarRating);
                    this.handleRatingDisplayChanges();
                }
            }
        });
      } 
  }

  private handleDropdownToggle() {
    if (this.popoverElement) {
      this.showHideRatingDrpDwn()
      this.handleStarRatingFilter();
    }
  }

  private showHideRatingDrpDwn() {
    this.popoverElement?.classList?.toggle("hidden");
    this.upArrow?.classList?.toggle("hidden");
    this.downArrow?.classList?.toggle("hidden");
  }

  private updateStarsDisplay(rating: number) {
    const starsContainer = document.getElementById('starsDisplay');
    if (starsContainer) {
      starsContainer.innerHTML = "";
      for (let i = 0; i < rating; i++) {
        const starElement = document.createElement("span");
        starElement.className = "star full";
        starElement.innerHTML = '&#9733;'; 
        starsContainer.appendChild(starElement);
      }
    }
  }

  private handleRatingDisplayChanges(){
    const ratingText = document.getElementById('ratingText');
    const ratingsInfo = document.getElementById('ratingsInfo');
    if(this.resetLink?.classList.contains("hidden")){
      this.resetLink?.classList.remove("hidden");
      this.resetLink.classList.add('reset-text');
      if(ratingText){
        ratingText.textContent = 'Showing:';
        ratingText.classList.remove('ratings-text');
        ratingText.classList.add('showing-text');
        ratingsInfo?.classList.remove('ratings-info');
        ratingsInfo?.classList.add('hidden');
      }
    }
  }

  private onResetClick(){
    const ratingText = document.getElementById('ratingText');
    const ratingsInfo = document.getElementById('ratingsInfo');
    if(ratingText){
      ratingText.textContent = this.overAllRatings;
      ratingText.classList.remove('showing-text');
      ratingText.classList.add('ratings-text');
    }
    this.resetLink?.classList.add('hidden');
    this.updateStarsDisplay(5);
    const ratingForm = document.getElementById("ratingForm");
    if (ratingForm) {
        const radioButtons = ratingForm.querySelectorAll('input[name="rating"]') as NodeListOf<HTMLInputElement>;
        // Add a change event listener to each radio button
        radioButtons.forEach(radio => radio.checked = false);
    }
    ratingsInfo?.classList.remove('hidden');
    ratingsInfo?.classList.add('ratings-info');
    this.selectedStarRating = 0;
    this.pageNumber = 1;
    this.getData();
  }

  async getTotalReviews() {
    let weblocationId: any;
    // Check sessionStorage first
    if (localStorage.getItem("franchiseWebLocationId")) {
      weblocationId = localStorage.getItem("franchiseWebLocationId");
    } else {
      // Check if the value exists in the DOM element
      const weblocationElement = document.getElementById('weblocationId') as any;
      weblocationId = weblocationElement?.value ? weblocationElement.value : '';
    }
    const url = apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${this.conceptId}&CountryId=${this.countryId}&MinimumRating=1&PageNumber=1&PageSize=1&FranchiseWebLocationId=${weblocationId}`
    try{
      let result = await getCachedAPIResponse(url, apiCaching.TestimonyAndReview);
      if(result.length){
        const totalReviews = document.querySelector("#ratingsInfo p");
        if (totalReviews) {
          totalReviews.textContent = `${result[0]?.grandTotal} Ratings`;

        }
        const overAllRatingsdiv = document.getElementById("ratingText");
        if (overAllRatingsdiv) {
          this.overAllRatings = `${result[0]?.overallRating}/5`;
          overAllRatingsdiv.textContent = this.overAllRatings;
        }
      }
    }
    catch{
      console.log("Unable to fetch total ratings");
    }
    
  }

}
const testimonial = new Testimonial();
