type WorkerFunction<TInput, TOutput> = (...args: (TInput | null)[]) => TOutput | null;

const CACHE_NAME = 'memoization-cache';
const CACHE_EXPIRY_MS = 24 * 60 * 60 * 1000; // 24 hours
const MAX_CACHE_ENTRIES = 100; // Maximum number of items in the cache

/**
 * Generate a unique cache key for the function and arguments.
 */
function generateCacheKey(functionRef: Function, args: (any)[]): string {
    return `${functionRef.name}-${JSON.stringify(args)}`;
}

/**
 * Cleans the cache by removing expired or least recently used entries.
 */
async function cleanCache() {
  const cache = await caches.open(CACHE_NAME);
  const keys = await cache.keys();

  const entries = await getCacheEntries(cache, keys);
  const validEntries = getValidEntries(entries);
  const expiredEntries = getExpiredEntries(entries);

  await deleteEntries(cache, expiredEntries);
  await enforceMaxSize(cache, validEntries);
}

async function getCacheEntries(cache: Cache, keys: readonly RequestInfo[]) {
  return Promise.all(
    keys.map(async (key) => {
      const response = await cache.match(key);
      if (response) {
        const { timestamp } = await response.json();
        return { key, timestamp: timestamp ?? 0 };
      }
      return null;
    })
  );
}

function getValidEntries(entries: ({ key: RequestInfo; timestamp: number } | null)[]) {
  const now = Date.now();
  return entries.filter((entry) => entry && now - (entry?.timestamp || 0) <= CACHE_EXPIRY_MS);
}

function getExpiredEntries(entries: ({ key: RequestInfo; timestamp: number } | null)[]) {
  const now = Date.now();
  return entries.filter((entry) => !entry || now - (entry.timestamp || 0) > CACHE_EXPIRY_MS);
}

async function deleteEntries(cache: Cache, entries: ({ key: RequestInfo; timestamp: number } | null)[]) {
  for (const entry of entries) {
    try {
      await cache.delete(entry?.key || '');
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Error deleting cache entry: ${error.message}`);
        } else {
            console.error(`Unknown error deleting cache entry: ${error}`);
        }
    }
  }
}

async function enforceMaxSize(cache: Cache, validEntries: ({ key: RequestInfo; timestamp: number } | null)[]) {
  validEntries.sort((a, b) => (a?.timestamp ?? 0) - (b?.timestamp ?? 0));

  while (validEntries.length > MAX_CACHE_ENTRIES) {
    const oldest = validEntries.shift();
    if (oldest) {
      try {
        await cache.delete(oldest.key || '');
      } catch (error) {
        if (error instanceof Error) {
            console.error(`Error deleting cache entry: ${error.message}`);
        } else {
            console.error(`Unknown error deleting cache entry: ${error}`);
        }
    }
    }
  }
}

/**
 * Memoization function using Cache API, handling possible null inputs.
 * 
 * @param functionRef - The function to memoize.
 * @param args - The arguments for the function.
 * @returns A promise resolving to the cached or newly computed result.
 */
async function useMemoCacheAPI<T>(
    functionRef: (...args: (any)[]) => T | null,
    args: (any)[] = []
): Promise<T | null> {
    const key = generateCacheKey(functionRef, args);
    const cache = await caches.open(CACHE_NAME);

    // Clean the cache (remove expired and LRU entries)
    try {
        await cleanCache();
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Error cleaning cache: ${error.message}`);
        } else {
            console.error(`Unknown error cleaning cache: ${error}`);
        }
    }

    // Check if result is in the cache
    const cachedResponse = await cache.match(key);
    if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        return cachedData?.result ?? null;
    }

    // Compute the result and store it in the cache
    const result = functionRef(...args);
    const timestamp = Date.now();

    if (result !== null) {
        try {
            await cache.put(
                key,
                new Response(JSON.stringify({ result, timestamp }), {
                    headers: { 'Content-Type': 'application/json' },
                })
            );
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error caching result: ${error.message}`);
            } else {
                console.error(`Unknown error caching result: ${error}`);
            }
        }
    }

    return result;
}

/**
 * Spawns a web worker, executes the provided function with multiple arguments, and returns the result.
 * Handles null inputs gracefully.
 * 
 * @param fn - The function to execute in the worker.
 * @param args - The arguments for the function.
 * @returns A promise that resolves to the memoized or computed output.
 */
export async function executeWithMemoization<TInput, TOutput>(
    fn: WorkerFunction<TInput, TOutput>,
    ...args: (TInput | null)[]
): Promise<TOutput | null> {
    return useMemoCacheAPI(async () => {
        try {
            return await executeInWorker(fn, ...args);
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Error executing worker: ${error.message}`);
            } else {
                console.error(`Unknown error executing worker: ${error}`);
            }
            return null;
        }
    }, [fn, ...args]);
}

/**
 * Executes the function in a Web Worker, handling null values gracefully.
 * 
 * @param fn - The function to execute in the worker.
 * @param args - The arguments for the function.
 * @returns A promise that resolves to the output of the function.
 */
function executeInWorker<TInput, TOutput>(
    fn: WorkerFunction<TInput, TOutput>,
    ...args: (TInput | null)[]
): Promise<TOutput | null> {
    return new Promise<TOutput | null>((resolve, reject) => {
        const workerId = Date.now() + Math.random().toString(36).substring(2); // Unique ID for each worker
        const fnString = fn.toString();
        const workerScript = `
            self.onmessage = function(event) {
                const { fn, args, workerId } = event.data;
                const func = new Function('return ' + fn)();
                try {
                    const result = func(...args);
                    self.postMessage({ success: true, result, workerId });
                } catch (error) {
                    self.postMessage({ success: false, error: error.message, workerId });
                }
            };
        `;

        const blob = new Blob([workerScript], { type: 'application/javascript' });
        const workerUrl = URL.createObjectURL(blob);
        const worker = new Worker(workerUrl);

        worker.onmessage = (event) => {
            const { success, result, error, workerId: receivedWorkerId } = event.data;
            if (receivedWorkerId === workerId) { // Ensure we handle the correct worker
                if (success) {
                    resolve(result ?? null);
                } else {
                    reject(new Error(error));
                }
                worker.terminate();
                URL.revokeObjectURL(workerUrl);
            }
        };

        worker.onerror = (error) => {
            reject(new Error(`Worker error: ${error.message}`));
            worker.terminate();
            URL.revokeObjectURL(workerUrl);
        };

        worker.postMessage({ fn: fnString, args, workerId });
    });
}


/*
(async () => {
    const result = await executeWithMemoization(factorial, 5);
    console.log('Factorial of 5:', result); // Output: Factorial of 5: 120
})();
*/



/*Old code*/
export function useMemo<T>(functionRef: (...args: any[]) => T, args: any[] = []): T {
    // Create a unique key based on the function and arguments
    const keyName = functionRef.name + JSON.stringify(args);

    // Check if result is already memoized
    const memoItem = localStorage.getItem(keyName);
    if (memoItem) {
        try {
            return JSON.parse(memoItem);
        } catch (e) {
            // Handle parsing errors and recalculate result
            console.warn('Failed to parse memoized result, recalculating...');
        }
    }

    // Execute the function and store the result
    const result = functionRef(...args);
    localStorage.setItem(keyName, JSON.stringify(result));
    return result;
}
