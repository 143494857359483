export function setTimeAndDate(time:string){
    const options:any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return new Date(time).toLocaleDateString("en-US", options);
}

export function reformatDate(str:string) {
    let parts = str.replace(/,/g, "").split(" ");
    let timeSection = parts.slice(4, str.length).join(" ").replace(/  /g, " ");
    const newStr = `${parts[0]}, ${parts[2]} ${parts[1]}, ${parts[3]}, ${timeSection}`;
    return newStr;
}