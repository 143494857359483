import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

/*Special offers modal to be shown when user is idle for 1 seconds*/
document.addEventListener('DOMContentLoaded', async function () {
    // If isEnabled is false, exit the function early
    const timeoutDuration = 10;
    let idleTime = 0;
    let idleInterval: any;
    try {
        const offerDetails = await getCachedAPIResponse(getUrlPath(), apiCaching.OffersPopup); // Fetch data only if URL is valid
        const result: any = offerDetails;
        if (result?.SpecialOfferExists) {
            if (!sessionStorage.getItem('splOfferModalShown')) {
                initIdleDetection();
            }
        }
    }
    catch (err: any) {
        if (err.response?.status !== 404) {
            console.error('Error fetching data:', err);
        }
    }
    

    function initIdleDetection() {
        idleTime = 0;
        idleInterval = setInterval(timerIncrement, 1000);

        // Event listeners for user activity
        document.addEventListener('mousemove', resetIdleTime);
        document.addEventListener('keypress', resetIdleTime);
        document.addEventListener('click', resetIdleTime);
        document.addEventListener('scroll', resetIdleTime);
    }

    function timerIncrement() {
        idleTime++;
        if (idleTime > timeoutDuration) {
            showModal();
            clearInterval(idleInterval);
        }
    }

    function resetIdleTime() {
        idleTime = 0;
    }

    function showModal() {
        const modal = document.getElementById('sp-offer-modal');
        const closeModalBtn = document.querySelector('.sp-offer-modal .close-modal-btn');
        if(!modal || !closeModalBtn) return;
        modal.classList.remove('hidden');
        modal.classList.add('flex');
        modal.style.backgroundColor = "#00000066";
        sessionStorage.setItem('splOfferModalShown', 'true');
        closeModalBtn.addEventListener('click', () => {
            if (modal) {
                modal.classList.add('hidden');
                modal.classList.remove('flex');
                modal.style.backgroundColor = "";
            }
        });
    }
    function getUrlPath(){
        const dbaName = localStorage.getItem('dbaName')?? 'none';
        return `${window.location.origin}/specialoffermodalpopup/${dbaName}`;
    }
});
