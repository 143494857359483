import { getBrandDetailsFromBrandJson } from "./share";

export async function replaceLocalLogoLink(element: HTMLElement) {

    let trailingSlash = '';
    let hostMapWebLocation ='';

    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;

    if(conceptCode){
      const brandData = await getBrandDetailsFromBrandJson(conceptCode);
      if(brandData?.includeTrailingSlash){
        trailingSlash = brandData.includeTrailingSlash ? "/" : "";
      }
       if(brandData?.weblocation_path_group){
          hostMapWebLocation = brandData.weblocation_path_group;
      }
    }
    let dbaName = hostMapWebLocation ? `${hostMapWebLocation}/${localStorage.getItem('dbaName')}`: localStorage.getItem('dbaName');
    element?.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
}