import { errorMessage } from "../common/errorMessages";
import { REGEX } from "../constants/regex";

export function hideError(id: string): void {
    const field = document.getElementById(id);
    const fieldError = document.getElementById(`${id}-error-msg`);

    if (fieldError) {
        fieldError.classList.add('hidden');
    }

    if (field) {
        field.classList.remove('invalid-field');
    }
}

export function showError(id: string, message: string): void {
    const field = document.getElementById(id);
    const fieldError = document.getElementById(`${id}-error-msg`);

    if (fieldError) {
        fieldError.textContent = message;
        fieldError.classList.remove('hidden');
    }
}

export function handleMobileInput(e: any): void {
    const phoneInput = e.target;
    e.preventDefault();
    const reg = REGEX.mobileNumberRegex;
    const val = phoneInput?.value;
    const x = val?.replace(/\D/g, '').match(reg);
    const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';

    if (phoneInput?.value != '' && phoneInput?.value.length < 14 && formattedVal.length < 14) {
        const getErrMsg = (document.getElementById(`${phoneInput?.id}-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
        showError(phoneInput?.id, getErrMsg ?? errorMessage.phone);
    } else {
        hideError(phoneInput?.id);
    }

    if (x) {
        phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
    }
}