import { TinySlider } from "../../global/global-custom";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

export class OffersTeaser {
    private static instance: OffersTeaser;

    constructor() {
        this.init();
    }
    static getInstance(): OffersTeaser {
        if (!OffersTeaser.instance) {
            OffersTeaser.instance = new OffersTeaser();
        }
        return OffersTeaser.instance;
    }

    init() {
        const dbaName: string | null = localStorage.getItem('dbaName');
        const webId: string | null = localStorage.getItem('weblocationId');
        if (webId && dbaName && !window.location.href.includes(dbaName)) {
            this.fetchTeaserInfo();
        }
    }

    async fetchTeaserInfo() {
        const pageSettingsElement = document.getElementById('page-settings');
        // Check if the element exists and has a value
        if (pageSettingsElement && this.hasTeaserComponent(pageSettingsElement.outerHTML)) {
            const urlSiteConfig = this.getOfferTeaserPath();
            try {
                if (urlSiteConfig != null) {
                    let result = await getCachedAPIResponse(urlSiteConfig, apiCaching.OfferTeaser, false);
                    if (!result || result.status === 404 || Object.keys(result).length === 0) {
                        // Remove the entire section if result is empty or 404 status
                        const section = document.querySelector('.offers-block-section');
                        if (section) {
                            section.remove();  // Removes the section from the DOM
                        }
                    } else {
                        // Replace the content of the section with class 'offers-block-section' with the fetched data
                        this.replaceSectionContent(result, '.offers-block-wrapper');
                        const offersliderElement = new TinySlider();
                    }
                }
                else {
                    const section = document.querySelector('.offers-block-section');
                        if (section) {
                            section.remove();  // Removes the section from the DOM
                        } 
                }
            }
            catch {
                const section = document.querySelector('.offers-block-section');
                        if (section) {
                            section.remove();  // Removes the section from the DOM
                        } 
            }
        }


    }
    private hasTeaserComponent(elementHtml: string): boolean {
        const rawString = elementHtml.split("value=")[1];
        const str = rawString?.split("specialoffersteasercomponent")[1]?.split(":")[1]?.split(",")[0]?.trim();
        const pageScope = rawString?.split('"pagescope":"')[1]?.split('"')[0]?.trim();
        return str === 'true' && pageScope === 'national';
    }

    private replaceSectionContent(responseData: string, targetSelector: string): void {
        const targetElement = document.querySelector(targetSelector);

    if (targetElement) {
        targetElement.innerHTML = responseData;
    }
    }

    getOfferTeaserPath(): string|null {
        const url = window.location.origin;
        const dbaName: string | null = localStorage.getItem('dbaName');
        let pathSegments = [];
        const couponsBlock = document.querySelector('.offers-block-section') as HTMLElement;

        if (dbaName && couponsBlock) {
            pathSegments.push('specialofferteaserdata', dbaName);
            return `${url}/${pathSegments.join('/')}`;
        }

        return null;
    }

}

document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector(".offers-block-section")) {
        const DomCheck = document.querySelector(".offers-block-wrapper .offers-block-list") as HTMLElement;
        if (DomCheck)
            OffersTeaser.getInstance()
    }
});