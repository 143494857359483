export function payloadManipulator(payload: any, domain: string, localization: string | null, optInCheck: boolean, countryCode: string) {
    // copy of the payload to avoid changing the original
    let resultPayload = { ...payload };

    const zipcode = countryCode;
    // Default values for optIn and emailOptOut
    resultPayload.optIn = optInCheck;
    resultPayload.emailOptOut = "false";
    // Helper function to add notes and comments
    const addOptInNotes = (optIn: string, emailOptIn: string) => {
        if(resultPayload['note']){
            resultPayload['Note'] = resultPayload['note'];
        }
        if(resultPayload['comments']){
            resultPayload['Comments'] = resultPayload['comments'];
        }
        if (resultPayload['Note']) {
            resultPayload['Note'] += `, Text OptIn: ${optIn}, Email OptIn: ${emailOptIn}`;
        } else {
            resultPayload['Note'] = `Text OptIn: ${optIn}, Email OptIn: ${emailOptIn}`;
        }

        if (resultPayload['Comments']) {
            resultPayload['Comments'] += `, Text OptIn: ${optIn}, Email OptIn: ${emailOptIn}`;
        } else {
            resultPayload['Comments'] = `Text OptIn: ${optIn}, Email OptIn: ${emailOptIn}`;
        }
    };

    // Main function for handling zipcode and opt-in logic
    const handleOptInLogic = (zipcode: string, optInCheck: boolean, isCADomain: boolean = false) => {
        if (zipcode === "us") {
            resultPayload.emailOptOut = false;
            resultPayload.optIn = optInCheck;
            addOptInNotes(optInCheck ? "yes" : "no", isCADomain ? "no" : "yes");
        } else if (zipcode === "ca") {
            resultPayload.emailOptOut = !optInCheck;
            resultPayload.optIn = false;
            addOptInNotes("no", optInCheck ? "yes" : "no");
        }
    };
    // Handle conditions for "us" domain and "national" or "us" localization
    const handleUSDomain = () => {
        if (localization === null || localization === "us" || localization === "ca") {
            handleOptInLogic(zipcode, optInCheck);
        } else {
            return resultPayload;
        }
    };

    // Handle conditions for "ca" domain
    const handleCADomain = () => {
        // If localization is "null", "national", or "ca", handle CA domain logic
        if (localization === null || localization === "ca") {
            handleOptInLogic(zipcode, optInCheck, true);
        }
        // If localization is "us" / any other unexpected localization value, return early without doing anything (invalid case for CA domain)
        else {
            return resultPayload;
        }

    };

    // Main logic
    if (domain === "us") {
        handleUSDomain();
    } else if (domain === "ca") {
        handleCADomain();
    }

    // return payload 
    return resultPayload;
}
