export class BannerManager {
  private banner: HTMLDivElement | null;

  constructor() {
    this.banner = null;
  }

  public initialize(): void {
    this.banner = document.getElementById("header-alert") as HTMLDivElement;

    const element = document.querySelector('.alert-close');
    if (element) {
      element.addEventListener('click', this.onClickHandler.bind(this));
    }

    const infoBanner = sessionStorage.getItem("informationBanner") || "true"; // Default to "true"
    if (infoBanner === "true") {
      this.showBanner();
    } else if (infoBanner === "false") {
      this.hideBanner();
    }
  }

  private onClickHandler(): void {
    if (this.banner != null) {
      this.banner.style.display = "none";
      sessionStorage.setItem('informationBanner', 'false');
    }
  }

  private showBanner(): void {
    if (this.banner != null) {
      this.banner.style.display = "block";
    }
  }

  private hideBanner(): void {
    if (this.banner != null) {
      this.banner.style.display = "none";
    }
  }
}

const bannerManager = new BannerManager();
bannerManager.initialize();
