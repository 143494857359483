export class EventLanding {
    
    globalEventMonth:string = 'Select Month';
    globalIndex:number = 0;
    selectedItemLength:number = 0;

    maxItemsInOneGo:number = 5; 

    maxItemsOnLoadMore:number = 3;

    itemsCurrentlyThatCanBeViewed: number = 5;

    isClickTriggered:boolean = false;

    dropDown:any;
    constructor() {
        this.init()
    }

    displayOnlySelectedMonth(){
        this.isClickTriggered = false;
        const month = (document.getElementById("filter-event") as HTMLSelectElement).value;
        this.globalIndex = 0;
        this.selectedItemLength = 0;
        this.globalEventMonth = month;
        const allEvents = document.querySelectorAll(".events-list>.event-wrapper");
        allEvents.forEach((el)=>{
            const monthCard = el as HTMLElement;
            monthCard.style.display = "none";
        });
        if(month!=="Select Month" && document.getElementById(month)){
            const monthCard = document.getElementById(month) as HTMLElement;
            monthCard.style.display = "flex";
        }
            
        else{
            allEvents.forEach((el)=>{
                const monthCard = el as HTMLElement;
                monthCard.style.display = "flex";
            });
        }
        
        this.setGlobalValuesToEventLength();

        this.showFiveContents();
    
    }

    showFiveContents() {
        this.itemsCurrentlyThatCanBeViewed = this.isClickTriggered 
            ? this.maxItemsInOneGo + (this.maxItemsOnLoadMore * this.globalIndex) 
            : this.maxItemsInOneGo;
    
        this.globalIndex += 1;
    
        let parentMonth = new Set();
    
        let selector = this.globalEventMonth === "Select Month" 
            ? ".event-wrapper>.events-card" 
            : `#${this.globalEventMonth}>.events-card`;
    
        let allEvents = document.querySelectorAll(selector);
    
        if (allEvents) {
            allEvents.forEach((el, index) => {
                const parentWrapper = el.parentNode as HTMLElement;
                const titleTarget = document.getElementById(parentWrapper.id) as HTMLElement;
                const targetTitleMonth = titleTarget.querySelector(".event-list-date") as HTMLElement;
                const eventCard = el as HTMLElement;
    
                if (index > this.itemsCurrentlyThatCanBeViewed - 1) {
                    eventCard.style.display = "none";
                } else {
                    eventCard.style.display = "block";
                    parentMonth.add(parentWrapper.id);
                }
    
                // Add or remove title months
                targetTitleMonth?.classList.toggle("hidden", !parentMonth.has(parentWrapper.id));
            });
        }
    
        // Check if load more buttons should be shown
        const buttonDOM = document.getElementById("load_more_events") as HTMLElement;
        const hideDiv = document.getElementById("load-more") as HTMLElement;
        const backToTopButton = document.getElementById("back_to_top");
        const backToTopWrapper = backToTopButton?.parentNode as HTMLElement;
    
        if (buttonDOM) {
            const shouldHide = this.selectedItemLength < (this.maxItemsInOneGo + 1) || (this.itemsCurrentlyThatCanBeViewed) > this.selectedItemLength - 1;
            buttonDOM.classList.toggle("hidden", shouldHide);
            hideDiv.classList.toggle("hidden", shouldHide);
    
            if (backToTopButton) {
                backToTopWrapper?.classList.toggle("hidden", !shouldHide);
                backToTopButton.removeEventListener("click", this.scrollToTop);
                if (shouldHide) {
                    backToTopButton.addEventListener("click", this.scrollToTop);
                }
            }
        }
    
        this.isClickTriggered = true;
    }
    
    

    setGlobalValuesToEventLength(){
        const childArticles = document.getElementById(this.globalEventMonth)

        if(childArticles)
            this.selectedItemLength = childArticles.querySelectorAll(".events-card").length;
        else
            this.selectedItemLength = document.querySelectorAll(".events-list>.event-wrapper>.events-card").length;
    }

    scrollToTop() {
        scrollTo(0, 0);
    }

    checkIfLoadMoreNeedsToBeShown(){
        //to check if load more buttons should be shown
        const buttonDOM =  document.getElementById("load_more_events") as HTMLElement;
        if(buttonDOM){
            if(this.selectedItemLength< (this.maxItemsInOneGo+1) || (this.globalIndex*this.maxItemsInOneGo)>this.selectedItemLength-1){
                buttonDOM.classList.add("hidden");
                const backToTopButton = document.getElementById("back_to_top");
                if(backToTopButton){
                    const backToTopWrapper = backToTopButton.parentNode as HTMLElement;
                    backToTopWrapper?.classList.remove("hidden");
                    backToTopButton.addEventListener("click", this.scrollToTop);
                }
            }
            else{
                buttonDOM.classList.remove("hidden");
                const backToTopButton = document.getElementById("back_to_top");
                if(backToTopButton){
                    const backToTopWrapper = backToTopButton.parentNode as HTMLElement;
                    backToTopWrapper?.classList.add("hidden");
                    backToTopButton.removeEventListener("click", this.scrollToTop);
                }
            }
        }
      }

    private init() {
        this.isClickTriggered = false;
        if(document.getElementById("load_more_events") && document.getElementById("filter-event")){
            document.getElementById("load_more_events")?.addEventListener("click", this.showFiveContents.bind(this));
            this.dropDown = document.getElementById("filter-event");
            document.getElementById("filter-event")?.addEventListener("change", this.displayOnlySelectedMonth.bind(this));
            this.setGlobalValuesToEventLength();
            this.showFiveContents();
        }
    }
    
    
}

new EventLanding();
