export function extractServices(data: any) {
    const services: any = [];
    const level2 = data?.menu ? data?.menu : []
    let serviceData: any = []
    level2?.forEach((item2: any) => {
        if(!item2.service && item2.serviceType == 'Service Sku' && item2.visibility){
            item2.serviceSkuId = true;
            serviceData.push(item2);
        }
    else
        item2?.service?.forEach((item3: any) => {
            if(item3.visibility){
                item3.serviceSkuId = true;
                serviceData.push(item3);
            }
          
        })
    })

    return serviceData;
}

