
import { tns } from "tiny-slider";
import MenuGeneratorDynamic from "../header/dynamicInjectMenu";
import { apiConfig } from "../config/apiConfig";
import { cachedAPIWrapper } from "../apiCaching/apiWrapper";
export class PhotoGallery {
    public modal: HTMLElement | null;
    public slideIndex: number = 1;
    public photoSection: HTMLElement | null;
    constructor() {
        this.modal = document.getElementById("galleryModal");
        this.photoSection = document.querySelector(".photo-gallery-section.content_ces");
        this.fetchImageData();
    }

    async fetchImageData() {
        const localWebID = localStorage.getItem("franchiseWebLocationId");
        const webID = document.getElementById('weblocationId') as HTMLInputElement;
        let weblocationId;
        if(webID?.value){
            weblocationId = webID?.value;
        }
        else if(localWebID){
            weblocationId = localWebID;
        }
        else{
            weblocationId = 0;
        }

        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const categoryID = 0;

        const imagesApi = `${apiConfig.PhotosAPI}/${weblocationId}/${conceptId}/${categoryID}?skip=100`;
        const categoriesApi = `${apiConfig.PhotoCategoriesAPI}/${weblocationId}/${conceptId}`;

        const setupGallerySlider = (sliderContainer: HTMLElement) => {
        const slider = tns({
            container: sliderContainer,
            items: 1,
            loop: false,
            slideBy: 'page',
            nav: true,
            navPosition: "bottom",
            // controlsPosition: "bottom",
            controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
            responsive: {
                1024: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            }
        });

        let gallerySliderlist = sliderContainer.getElementsByTagName("li").length;

        const adjustGallerySlider = () => {
            let w = window.innerWidth;
            if (gallerySliderlist <= 3 && w > 1300) {
                slider?.destroy();
                const galleryWrapper : HTMLElement | null = document.querySelector(".gallery-wrapper");;
                if (galleryWrapper) {
                    galleryWrapper.style.maxWidth = "100%";
                }
            }
        };

        window.addEventListener('load', adjustGallerySlider);
        window.addEventListener('resize', adjustGallerySlider);
    };

        const fetchImages = () => cachedAPIWrapper(imagesApi);
        const fetchCategories = () => cachedAPIWrapper(categoriesApi);
        
        
        // Used Promise.all to make parallel requests
        Promise.all([fetchImages(), fetchCategories()])
        .then(([imagesResponse, categoriesResponse]) => {
            const imagesData = imagesResponse.data;
            const categoriesData = categoriesResponse.data;
            
            const photos = imagesData.photos;
            localStorage.setItem('galleryPhotos', JSON.stringify(photos));

            const dynamicMenu = new MenuGeneratorDynamic();
            dynamicMenu.fetchPhotoGalleryData();

            const categories = categoriesData.photoGalleryCategories;
            if(this.photoSection && photos.length > 5){
                this.populateGallery(photos, categories);
                const gallerySlider : HTMLElement | null = document.querySelector(".gallery-slider");
                if(gallerySlider){
                    setupGallerySlider(gallerySlider);
                }
            } else if(this.photoSection){
                this.photoSection.style.display = 'none';
            }
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
            });

    }

    photoUrlFun() {
        let photoUrl: string = '';
        const js_redirectUri = process.env.JS_redirectUri;
        if (js_redirectUri) {
            if (js_redirectUri.indexOf('-dev') > 0) {
                photoUrl = js_redirectUri?.replace('-dev', '-stage').replace('nblysbx', 'nblytest');
            }
            else if (js_redirectUri.indexOf('-test') > 0) {
                photoUrl = js_redirectUri?.replace('-test', '-stage').replace('nblydev', 'nblytest');
            }
            else if (js_redirectUri.indexOf('-stage') > 0) {
                photoUrl = js_redirectUri?.replace('-stage', '').replace('nblytest', 'nblyprod');
            }
            else {
                photoUrl = js_redirectUri
            }
        }
        return photoUrl;
    }

    populateGallery(imageData: any, categoriesData: any) {
        // const photoDomain = window.location.host.indexOf("www-nei") != -1 ? this.photoUrlFun() : window.location.origin+'/';
        const photoDomain = `${window.location.origin}/`;

        const galleryList = document.querySelector('.photo-gallery-section .gallery-wrapper .photo-gallery-list') as HTMLUListElement;

        if (galleryList) {
            galleryList.innerHTML = '';
            let htmlStr='';
            imageData.forEach((imageInfo: any, index: any) => {

                const li = document.createElement('li');
                li.classList.add('photo-gallery-item');

                const card = document.createElement('div');
                card.classList.add('gallery-card');

                const cardImg = document.createElement('div');
                cardImg.classList.add('card-img');
                // cardImg.style.width = "321px";
                const img = document.createElement('img');
                img.src = `${photoDomain}photos/${imageInfo.imageURL}` || '';
                img.alt = imageInfo.altText;
                img.width = 321;
                img.height = 256;

                img.addEventListener('click', () => {
                    this.openModal(imageData, photoDomain);
                    this.currentSlide(index + 1);
                });

                const cardBody = document.createElement('div');
                cardBody.classList.add('card-body');

                const cardTitle = document.createElement('span');
                cardTitle.classList.add('card-title');
                cardTitle.innerHTML = imageInfo.shortCaption ? `${imageInfo.shortCaption} -` : '';

                const cardDesc = document.createElement('span');
                cardDesc.classList.add('card-desc');
                cardDesc.innerHTML = imageInfo.longCaption ? imageInfo.longCaption : '';
                cardDesc.style.whiteSpace = "normal"

                cardImg.appendChild(img);
                cardBody.appendChild(cardTitle);
                cardBody.appendChild(cardDesc);
                card.appendChild(cardImg);
                card.appendChild(cardBody);
                li.appendChild(card);
                htmlStr = htmlStr + li.outerHTML;
                //galleryList?.appendChild(li);

            });
            galleryList.innerHTML = htmlStr;
        }
    }

    openModal(photoData: any, photoDomain: any){
        if (this.modal) {
            this.modal.style.display = "flex";
            const ulModalElement = document.querySelector('#galleryModal .modal-content') as HTMLUListElement;
            if (ulModalElement) {
                let htmlStr='';
                ulModalElement.innerHTML = '';
                photoData.forEach((imageInfo: any, index: any) => {
                    const liElement = document.createElement('li');
                    liElement.classList.add('pop-up-slider');

                    const galleryCard = document.createElement('div');
                    galleryCard.classList.add('gallery-card');

                    const cardImg = document.createElement('div');
                    cardImg.classList.add('card-img');

                    const img = document.createElement('img');
                    img.src = `${photoDomain}photos/${imageInfo.imageURL}` || '';
                    img.alt = imageInfo.altText;;
                    img.width = 321;
                    img.height = 256;

                    cardImg.appendChild(img);

                    const closeModalButton = document.querySelector(".gallery-modal .close");
                    closeModalButton?.addEventListener('click', () => {
                        this.closeModal();
                    });

                    const cardBody = document.createElement('div');
                    cardBody.classList.add('card-body');

                    const cardTitle = document.createElement('span');
                    cardTitle.classList.add('card-title');
                    cardTitle.textContent = imageInfo.shortCaption ? `${imageInfo.shortCaption} -` : '';

                    const cardDesc = document.createElement('span');
                    cardDesc.classList.add('card-desc');
                    cardDesc.textContent = imageInfo.longCaption ? imageInfo.longCaption : '';

                    cardBody.appendChild(cardTitle);
                    cardBody.appendChild(cardDesc);
                    galleryCard.appendChild(cardImg);
                    galleryCard.appendChild(cardBody);
                    liElement.appendChild(galleryCard);
                    htmlStr = htmlStr + liElement.outerHTML;
                    //ulModalElement.appendChild(liElement);
                });
                ulModalElement.innerHTML = htmlStr;
            }
        }
    };

    closeModal(){
        if (this.modal) {
            this.modal.style.display = "none";
        }
    };

    currentSlide(n: number){
        this.showSlides(this.slideIndex = n);
    };

    showSlides(n: number){
        let i;
        const slides = document.getElementsByClassName("pop-up-slider");
        if(slides){
            if (n > slides.length) {
                this.slideIndex = 1;
            }

            if (n < 1) {
                this.slideIndex = slides.length;
            }

            for (i = 0; i < slides.length; i++) {
                (slides[i] as HTMLElement).style.display = "none";
            }
            (slides[this.slideIndex - 1] as HTMLElement).style.display = "flex";
        }
    };

}

// Initialize the PhotoGallery class when the DOM is ready
document.addEventListener('DOMContentLoaded', () => {
    const photoSection = document.querySelector(".photo-gallery-section.content_ces") as HTMLElement;
    if(photoSection){
        new PhotoGallery();
    }
});
