
export const bookingPayload = {
    "FranchiseId": "",
    "HasSameDayScheduling": true,
    "IsBetaTester": false,
    "Comments": "",
    "IsLocalized": true,
    "ConceptId": 1,
    "UseFranchiseProfileOptInForPOSScheduling": true,
    "IsFranchiseOptedIntoPOSScheduling": true,
    "C4ChannelTypeId": 0,
    "ConceptCalledId": 1,
    "ConceptName": "Molly Maid",
    "ConceptCode": "MLY",
    "C4LeadsEnabled": true,
    "CanceledJobInformation": "",
    "VendorId": 70,
    "NationalLeadId": "",
    "IsTest": false,
    "VendorName": "Molly Maid Website",
    "WebLocationId": 0,
    "C4CallCenterId": 0,
    "DayTimePhoneNumber": "",
    "ZipCode": "",
    "Note": "",
    "FirstName": "",
    "LastName": "",
    "Email": "",
    "Phone": "",
    "PhoneType": "",
    "PostalCode": "",
    "City": "",
    "State": "",
    "Country": "",
    "Address": "",
    "Address2": "",
    "LeadSource": "Web",
    "LicenseNumber": "",
    "WebLocationAccountCode": "",
    "LeadOrigin": "Web",
    "CampaignName": "N/A",
    "ReferringURL": "",
    "CurrentPage": window.location.origin,
    "Disposition": "",
    "CallType": "In Bound",
    "PreferredCommunicationType": "",
    "EmailOptOut": false,
    "TagName": "",
    "Latitude": 0,
    "Longitude": 0,
    "LocationType": "",
    "IsGoogleAddress": true,
    "GoogleCalendarEventId": "",
    "GoogleCalendarEmail": "",
    "ServiceText": "Lead Only",
    "ServiceID": 0,
    "Warranty": false,
    "TimeSlotTypeId": 0,
    "MakeId": 0,
    "LeadSourceId": 0,
    "SecondaryLeadSourceId": 0,
    "serviceTypeID": 0,
    "businessName": "",
    "ServiceTypeProblem": "",
    "OverrideJobPromisedDate": "",
    "ServiceSkuId": 0,
    "IsEstimate": false,
    "EstimateHouseStyle": "",
    "EstimateSquareFeet": "",
    "EstimateBedrooms": "",
    "EstimateBathrooms": "",
    "EstimatePets": "",
    "CCFirstName": "",
    "CCLastName": "",
    "CCType": "",
    "CCSuffix": "",
    "CCToken": "",
    "CCExpirationDate": "",
    "CCComment": "",
    "IsLeadOnly": false,
    "OverrideCSRName": "",
    "OverrideComment": "",
    "SyncTenantName": "",
    "IsEmergencyCall": false,
    "CallId": "",
    "IsFranchiseInPOS": true,
    "CustomerType": "",
    "NeighborlyAccountId": 0,
    "NeighborlyLeadSourceId": null,
    "FileUrls": [
        {},
        {},
        {
            "FileUrl": ""
        }
    ],
    "JobFrequency": "",
    "JobFrequencyDetail": "",
    "Year": 0,
    "Make": "",
    "Model": "",
    "BodyStyle": "",
    "VIN": "",
    "LicensePlate": "",
    "WindshieldRepair": "",
    "HeadlightRestoration": "",
    "ServiceRequested": [],
    "LeadAdditionalNotes": {
        "LeadAdditionalNoteLabel": "Website",
        "LeadAdditionalNoteText": ""
    },
    "FormOfPayment": "",
    "InsuranceCompany": "",
    "DeductibleAmount": 0,
    "PolicyNumber": "",
    "ScheduleID": "",
    "SignUpForUpdates": false,
    "ReferralType": "",
    "ReferralTypeId": "",
};

export function isTestFcn(){
    const host = window.location.hostname.toLowerCase();
    let IsTest;
    if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) {
        IsTest = true;
    } else {
        IsTest = false;
    }
    return IsTest;
}


