import { getCachedAPIResponse } from "../apiCaching/apiWrapper";

declare global {
    interface Window {
        LocalizationIdentifier: typeof LocalizationIdentifier;
        DomainIdentifier: typeof DomainIdentifier;
    }
}
import { Footer } from "../common";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { isTouchDevice } from "../util/device-detection";
import { DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { DomainIdentifier } from "../util/domainService";
import { LocalizationIdentifier } from "../util/localizationService";
import { updateHeaderContent } from "../util/share";

interface LoggedError {
    httpStatusCode?: string;
    message: string;
    stackTrace?: string;
    timeStamp: Date;
    apiEndpoint?: string;
    deviceType?: string;
}
export class OnLoaderServices {

    private static instance: OnLoaderServices;
    private loggedErrors: LoggedError[] = [];

    constructor() {
        //to get the location from the url
        this.getLocalizedFromLink();

        //to capture all error logs
        this.attachErrorListener();
    }

    static getInstance(): OnLoaderServices {
        if (!OnLoaderServices.instance) {
            OnLoaderServices.instance = new OnLoaderServices();
        }
        return OnLoaderServices.instance;
    }

    private attachErrorListener(): void {
        window.addEventListener('error', (event) => {
            const error = event.error;
            const timeStamp = new Date(Date.now());
            if (error) {
                const loggedError: LoggedError = {
                    httpStatusCode: error.httpStatusCode ?? "",
                    message: error.message ?? "No message",
                    stackTrace: error.stack ?? "No message",
                    timeStamp,
                    deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
                };
                this.loggedErrors.push(loggedError);
            }
            else {
                const loggedError: LoggedError = {
                    httpStatusCode: "No Status Code, as error is in the script",
                    message: event.message ?? "No Trace available",
                    stackTrace: "No message",
                    timeStamp,
                    deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
                };
                console.info(event.message ?? "Error in GTM script");
                this.loggedErrors.push(loggedError);
            }

        });
    }

    getLoggedErrors(): LoggedError[] {
        return this.loggedErrors;
    }

    async getLocalizedFromLink() {
        const url = window.location.href;
        const urlObj = new URL(url);
        const urlParams = urlObj.searchParams;
        const location = urlParams.get("location");
        if (location) {
            localStorage.setItem('weblocationId', location);
            const url = `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${location}`;
            try {
                const resp = await getCachedAPIResponse(url, apiCaching.FullAttributeResponse);
                const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
                const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
                if (resp.conceptId === Number(conceptId) && resp.brandCode === conceptCode) {
                    this.setLocalData(resp, resp?.zip);
                }
            }
            catch {
                console.error(`Error fetching Full Attribute`);
                throw (new Error("Error fetching Full Attribute"))
            }
        }
    }

    //setting hidden input items into the DOM
    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    //setting localization
    private async setLocalData(data: any, zipCode: any) {

        if (data) {
            const { franchiseWebLocationId, locationDoingBusinessAs, locationWebsiteUrl, mainPhoneNumber, callTrackings } = data;
            localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
            sessionStorage.setItem('franchiseId', franchiseWebLocationId);
            localStorage.setItem('doingBusinessAs', locationDoingBusinessAs);
            sessionStorage.setItem('redirectionURL', locationWebsiteUrl);
            localStorage.setItem('weblocationId', franchiseWebLocationId);
            const locationUrl = locationWebsiteUrl?.replace(/\/$/, '') ?? '';
            const splitUrlArr = locationUrl.toLowerCase().split("/");
            const pathname = splitUrlArr[splitUrlArr.length - 1];

            let callTrackNumber: any = mainPhoneNumber;

            callTrackings.forEach((type: any) => {
                if (type.callTrackingTypeDescription == "WebsiteTracking") {
                    callTrackNumber = type.callTrackingNumber;
                    localStorage.setItem('localPhoneNumber', callTrackNumber);
                }
            });
            const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

            if (phoneNO) {
                phoneNO.href = `tel:${callTrackNumber}`
                const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
            }

            const mobile: any = document.querySelector('.header-cta-mobile .primary-btn')

            if (mobile) {
                mobile.href = `tel:${callTrackNumber}`

            }

            if (pathname) {
                localStorage.setItem('dbaName', pathname);
            }
            this.updateInputValue("local_weblocationId", franchiseWebLocationId);
            this.updateInputValue("weblocationId", franchiseWebLocationId);
            this.updateInputValue("full_dbaName", pathname);
            this.updateInputValue("dbaName", pathname);

            const address = document.querySelector('.header-wrapper .address')
            const desc = document.querySelector('.header-wrapper .description')

            if (locationDoingBusinessAs) {
                updateHeaderContent(locationDoingBusinessAs);
            }

            if (address && desc) {
                address.innerHTML = locationDoingBusinessAs
                desc.innerHTML = 'Locally Owned and Operated'
            }
            const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement)?.value;
            sessionStorage.setItem("manualEnteredAddress", addressEntered + ", " + zipCode);
            sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
            sessionStorage.setItem("RL_DBAName", locationDoingBusinessAs);
            const globalBrandLogo = document.querySelector(".brand-header .brand-logo-wrapper") as HTMLElement;
            const globalfindLocal = document.querySelector(".brand-header .find-local") as HTMLElement;
            if (globalBrandLogo) {
                globalBrandLogo.style.display = "none";
            }
            if (globalfindLocal) {
                globalfindLocal.style.display = "none";
            }
            new Footer();
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    new OnLoaderServices();
    const sAddressParam = sessionStorage.getItem('sAddressParam');
    DisclaimerServiceIdentifier(sAddressParam);

    window.LocalizationIdentifier = LocalizationIdentifier;
    window.DomainIdentifier = DomainIdentifier;

});