import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { performanceBenchmark, handleApiError, uniqueIDGenerator } from './apiLogger';

const axiosInstance1 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

const axiosInstance2 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.JS_API_KEY,
  }
});

interface ApiError {
  error: string;
  code: number;
  Message?: string; 
}

/**
 * Generalized function to handle API requests with optional Axios instance.
 */
async function performApiRequest<T>(
  config: AxiosRequestConfig, 
  axiosInstance = axiosInstance1
): Promise<T> {
  const url = config.url ?? 'unknown-url';
  const uniqueID = uniqueIDGenerator(url)
  performance.mark(`${uniqueID}-start`);

  try {
    const response: AxiosResponse<T> = await axiosInstance.request(config);
    performanceBenchmark(url, uniqueID); // Benchmark API performance

    return response.data; // Return the actual response data
  } catch (error: any) { 
    throw handleApiError(error, url); // Handle and rethrow the error
  } 
}

/**
 * Wrapper function for handling API requests with performance and error logging.
 */
export async function apiRequest<T>(config: AxiosRequestConfig): Promise<T> {
  return performApiRequest(config, axiosInstance1);
}

/**
 * Alternative API request function using a different Axios instance.
 */
export async function apiRequest2<T>(config: AxiosRequestConfig): Promise<T> {
  return performApiRequest(config, axiosInstance2);
}

/**
 * API request function for wrapper with specialized error handling.
 */
export async function apiRequestForWrapper<T>(config: AxiosRequestConfig): Promise<T> {
  const url = config.url ?? 'unknown-url';
  const uniqueID = uniqueIDGenerator(url)
  performance.mark(`${uniqueID}-start`);

  try {
    const response: AxiosResponse<T> = await axiosInstance1.request(config);
    performance.mark(`${uniqueID}-end`); 
    performanceBenchmark(url, uniqueID); // Benchmark API performance

    return response.data; // Return the actual response data
  } catch (error: any) { 
    throw handleWrapperApiError(error); // Handle and rethrow the error
  } 
}

export async function wrapperAPICall<T>(config: AxiosRequestConfig): Promise<T> {
  const url = config.url ?? 'unknown-url';
  const uniqueID = uniqueIDGenerator(url)
  performance.mark(`${uniqueID}-start`);

  try {
    const response: AxiosResponse<T> = await axiosInstance2.request(config);
    performance.mark(`${uniqueID}-end`); 
    performanceBenchmark(url, uniqueID); // Benchmark API performance

    return response.data; // Return the actual response data
  } catch (error: any) { 
    throw handleWrapperApiError(error); // Handle and rethrow the error
  } 
}

/**
 * Handle wrapper-specific API errors.
 */
function handleWrapperApiError(error: AxiosError<ApiError>): Error {
  if (error.response) {
    const errorMessage = error.response.data.Message ?? 'API Error';
    const statusCode = error.response.status;
    return new Error(JSON.stringify({ message: errorMessage, status: statusCode }));
  } else if (error.request) {
    return new Error('No response received from the server');
  } else {
    return new Error('Error occurred while setting up the request');
  }
}

export default apiRequest;