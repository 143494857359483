export function getBrandJSON():string {
    const url = window.location.origin;
    //for local enviroments
    if(url.includes('localhost')){
        const assetPathElement = document.getElementById("assetPath")as HTMLInputElement;
        if(assetPathElement){
            const assetPathValue = assetPathElement.value;
            if(assetPathValue.includes("/US/"))
                return '_assets-global/configs/brands.json';
            else if(assetPathValue.includes("/CA/"))
                return '_assets-global/configs/brands-ca.json';
        }
        return "Error validating Location"
    }
    //Code for lower enviroments
    else if (url.includes('nblysbx') || url.includes('nblydev') || url.includes('nblytest')){
        try{
            if (url.includes('-ca-')){
                return '_assets-global/configs/brands-ca.json';
            }
            else{
                return '_assets-global/configs/brands.json';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
    //Code for production enviroment
    else{
        try{
            const lastDotIndex = url.lastIndexOf('.'); 
            const domainExtension = url.substring(lastDotIndex + 1);
            if(domainExtension === "ca"){
                return '_assets-global/configs/brands-ca.json';
            }
            else{
                return '_assets-global/configs/brands.json';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
}